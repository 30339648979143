import React, { useState, useEffect } from "react";
import "../css/reporte2.css";
import Menu from "./Menu";
import SeccionReporte from "./SeccionReporte";
import icono1 from "../assets/outline_person_white_24dp.png";
import icono2 from "../assets/receipt_white_24dp.svg";
import icono3 from "../assets/view_list_white_24dp.svg";
import icono4 from "../assets/confirmation_number_white_24dp.svg";
import icono5 from "../assets/priority_high_white_24dp.svg";
import icono6 from "../assets/note_add_white_24dp.svg";
import icono7 from "../assets/receipt_long_white_24dp.svg";
import Tabla from "./Tabla";
import Modal from "./Modal";

const url = "https://parkingapi.ccg.hn:4000";

function Reporte2({ ...props }) {
  const [mostrarTabla, setmostrarTabla] = useState("none");
  const [mostrarReporte, setmostrarReporte] = useState("");
  const [mostrarModal, setmostrarModal] = useState("none");
  const [filter, setFilter] = useState("");
  const [fechaR, setfechaR] = useState("");
  const [ultimaFactura, setultimaFactura] = useState("");

  const [mostrarModuloReporte, setmostrarModuloReporte] = useState("");
  const [mostrarModuloMenú, setmostrarModuloMenú] = useState("none");
  const [mostrarBotonMenu, setmostrarBotonMenu] = useState("");
  const [mostrarUltimaFactura, setmostrarUltimaFactura] = useState("");

  const [tituloReporte, settituloReporte] = useState("");

  const getFactura = async () => {
    const res = await fetch(`${url}/UltimaF`);
    const data = await res.json();
    console.log(data);
    setultimaFactura(data[0].id_Factura);
  };

  const descripcion1 =
    'Facturación diaria que hizo cada usuario con rol "Facturador".';
  const descripcion2 =
    "Registro de facturación que se llevó acabo en un día en especifico.";
  const descripcion3 =
    "Muestra la información ingresada en Facturación mensual de un mes en especifico.";
  const descripcion4 =
    "Muestra la información ingresada en Facturación de ticket extraviado en un día especifico.";
  const descripcion5 =
    "Muestra el registro de facturas NULAS en un mes en especifico.";
  const descripcion6 = "Conteo de Creacion de Ticket's/Facturas generadas.";
  const descripcion7 =
    "Registro de facturación que se llevó acabo en un mes en especifico.";
  const descripcion8 = "Reporte de facturacion hasta la fecha.";
  const descripcion9 = "Reporte de ticket con cortesia.";
  const descripcion10 =
    "Registro de pagos Mensuales que se han generado en el sistema.";

  useEffect(() => {
    if (mostrarTabla !== "none") {
      setmostrarReporte("none");
      setmostrarModal("none");
    } else {
      setmostrarReporte("");
    }
    getFactura();
  }, [mostrarTabla]);

  return (
    <React.Fragment>
      <div style={{ display: `${mostrarModuloReporte}` }}>
        <div
          className='contenedorPirncipalReportes'
          style={{ display: `${mostrarReporte}`, filter: `${filter}` }}
        >
          <SeccionReporte
            icono={icono1}
            titulo='Reporte Facturacion por Usuario'
            descripcion={descripcion1}
            setmostrarTabla={setmostrarTabla}
            settituloReporte={settituloReporte}
            setmostrarModal={setmostrarModal}
            setFilter={setFilter}
            setmostrarBotonMenu={setmostrarBotonMenu}
            setmostrarUltimaFactura={setmostrarUltimaFactura}
          />
          <SeccionReporte
            icono={icono2}
            titulo='Reporte Facturacion por Día'
            descripcion={descripcion2}
            setmostrarTabla={setmostrarTabla}
            settituloReporte={settituloReporte}
            setmostrarModal={setmostrarModal}
            setFilter={setFilter}
            setmostrarBotonMenu={setmostrarBotonMenu}
            setmostrarUltimaFactura={setmostrarUltimaFactura}
          />
          <SeccionReporte
            icono={icono3}
            titulo='Reporte Pago Mensualidad'
            descripcion={descripcion3}
            setmostrarTabla={setmostrarTabla}
            settituloReporte={settituloReporte}
            setmostrarModal={setmostrarModal}
            setFilter={setFilter}
            setmostrarBotonMenu={setmostrarBotonMenu}
            setmostrarUltimaFactura={setmostrarUltimaFactura}
          />
          <SeccionReporte
            icono={icono4}
            titulo='Reporte Pago Ticket Extraviado'
            descripcion={descripcion4}
            setmostrarTabla={setmostrarTabla}
            settituloReporte={settituloReporte}
            setmostrarModal={setmostrarModal}
            setFilter={setFilter}
            setmostrarBotonMenu={setmostrarBotonMenu}
            setmostrarUltimaFactura={setmostrarUltimaFactura}
          />
          <SeccionReporte
            icono={icono5}
            titulo='Reporte de Facturas Nulas'
            descripcion={descripcion5}
            setmostrarTabla={setmostrarTabla}
            settituloReporte={settituloReporte}
            setmostrarModal={setmostrarModal}
            setFilter={setFilter}
            setmostrarBotonMenu={setmostrarBotonMenu}
            setmostrarUltimaFactura={setmostrarUltimaFactura}
          />
          <SeccionReporte
            icono={icono6}
            titulo="Reporte Creación Ticket's/Facturas"
            descripcion={descripcion6}
            setmostrarTabla={setmostrarTabla}
            settituloReporte={settituloReporte}
            setmostrarModal={setmostrarModal}
            setFilter={setFilter}
            setmostrarBotonMenu={setmostrarBotonMenu}
            setmostrarUltimaFactura={setmostrarUltimaFactura}
          />
          <SeccionReporte
            icono={icono7}
            titulo='Reporte Facturación por Mes'
            descripcion={descripcion7}
            setmostrarTabla={setmostrarTabla}
            settituloReporte={settituloReporte}
            setmostrarModal={setmostrarModal}
            setFilter={setFilter}
            setmostrarBotonMenu={setmostrarBotonMenu}
            setmostrarUltimaFactura={setmostrarUltimaFactura}
          />
          <SeccionReporte
            icono={icono1}
            titulo='Reporte General facturación'
            descripcion={descripcion8}
            setmostrarTabla={setmostrarTabla}
            settituloReporte={settituloReporte}
            setmostrarModal={setmostrarModal}
            setFilter={setFilter}
            setmostrarBotonMenu={setmostrarBotonMenu}
            setmostrarUltimaFactura={setmostrarUltimaFactura}
          />

          <SeccionReporte
            icono={icono1}
            titulo='Reporte Ticket Cortesia'
            descripcion={descripcion9}
            setmostrarTabla={setmostrarTabla}
            settituloReporte={settituloReporte}
            setmostrarModal={setmostrarModal}
            setFilter={setFilter}
            setmostrarBotonMenu={setmostrarBotonMenu}
            setmostrarUltimaFactura={setmostrarUltimaFactura}
          />
          <SeccionReporte
            icono={icono2}
            titulo='Registro Parqueo Mensual'
            descripcion={descripcion10}
            setmostrarTabla={setmostrarTabla}
            settituloReporte={settituloReporte}
            setmostrarModal={setmostrarModal}
            setFilter={setFilter}
            setmostrarBotonMenu={setmostrarBotonMenu}
            setmostrarUltimaFactura={setmostrarUltimaFactura}
          />
        </div>

        <div
          className='contenedor-Modal'
          style={{ display: `${mostrarModal}` }}
        >
          <Modal
            tituloReporte={tituloReporte}
            setmostrarModal={setmostrarModal}
            setFilter={setFilter}
            setmostrarBotonMenu={setmostrarBotonMenu}
            setmostrarUltimaFactura={setmostrarUltimaFactura}
          />
        </div>
        <div style={{ display: `${mostrarTabla}` }}>
          <Tabla
            tituloReporte={tituloReporte}
            setmostrarTabla={setmostrarTabla}
            settituloReporte={settituloReporte}
            setmostrarUltimaFactura={setmostrarUltimaFactura}
            setmostrarBotonMenu={setmostrarBotonMenu}
          ></Tabla>
        </div>

        <div
          className='Contenedor-Reportes-Menu'
          style={{ display: `${mostrarBotonMenu}` }}
        >
          <div className='contenedor-boton-menu'>
            <button
              type='button'
              className='btn btn-warning cambiarFactura'
              onClick={() => {
                setmostrarModuloReporte("none");
                setmostrarModuloMenú("");
                setmostrarUltimaFactura("none");
              }}
            >
              Menú
            </button>
          </div>
        </div>
      </div>

      <div
        className='contenedor-Reporte-NumeroFactura'
        style={{ display: `${mostrarUltimaFactura}` }}
      >
        <label>Última Factura Generada:{ultimaFactura}</label>
      </div>

      <div style={{ display: `${mostrarModuloMenú}` }}>
        <Menu nombreI={props.nombreI} rol={props.rol}></Menu>
      </div>
    </React.Fragment>
  );
}

export default Reporte2;
