import React from "react";
import "../css/FormularioCajon.css";

function ModalImportar({ ...props }) {
  const cancelar = () => {
    props.setMostarImportar("none");
  };

  const uploadFile = async () => {
    const fileInput = document.getElementById("fileInput");
    const file = fileInput.files[0];

    const formData = new FormData();
    formData.append("file", file);

    const res = await fetch(
      "https://parkingapi.ccg.hn:4000/asignacionesEstacionamiento/importar",
      {
        method: "POST",
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data) {
          data.map(async (result) => {
            if (result.tipo_Acceso === "Tarjeta Inteligente") {
              await fetch(
                `https://parkingapi.ccg.hn:4000/prueba/asignacion/${result.numero_Tarjeta}/${result.id_Asignacion_cajon}`
              );
            }
          });

          alert("Se han Importado Correctamente los Registros");
          cancelar();
          props.setrecargar(!props.recargar);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div>
      <div id='myModal' class='modal'>
        <div className='modal-content'>
          <div classname='modal-header'>
            <span className='close' onClick={() => cancelar()}>
              &times;
            </span>
            <h2>Importar Asignacion de Estacionamientos</h2>
          </div>
          <div class='modal-body'>
            <div className='contenedor'>
              <div className='contenedorGrupo'>
                <label className='elementLabel'>Archivo .XLSX</label>
                <input type='file' name='file' id='fileInput'></input>
              </div>
            </div>
          </div>

          <div class='modal-footer'>
            <button
              className='btn btn-primary form-control'
              style={{ fontSize: "14px" }}
              onClick={() => uploadFile()}
            >
              Guardar
            </button>
            <button
              className=' btn danger form-control'
              style={{ fontSize: "14px", marginLeft: "20px" }}
              onClick={() => cancelar()}
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalImportar;
