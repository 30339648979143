import React, { useState, useEffect } from "react";
const url = "https://parkingapi.ccg.hn:4000";

function TipoCliente({ ...props }) {
  const consultarRTN = async (valor) => {
    const res = await fetch(`${url}/ConsultarRTN`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        valor,
      }),
    });

    const data = await res.json();
    if (data.length > 0) {
      props.setN(data[0].nombre);
    } else {
      props.setN("");
    }
  };

  return (
    <React.Fragment>
      <div
        className='form-check'
        style={{ width: "40rem", marginLeft: "2rem" }}
      >
        <input
          style={{ fontSize: "2rem" }}
          className='form-check-input'
          type='checkbox'
          selected={props.check}
          id='consultarRTN'
          onClick={() => {
            props.setCheck(!props.check);
          }}
        />
        <label className='form-check-label' htmlFor='consultarRTN'>
          Consultar RTN
        </label>
      </div>
      <section style={{ width: "35rem", marginTop: "1rem" }}>
        <div className='input-group mb-3'>
          <span
            style={{ fontSize: "1.8rem", backgroundColor: "#e5e5e5" }}
            className='text-span'
          >
            RTN
          </span>
          <input
            style={{ fontSize: "1.8rem" }}
            type='text'
            className='form-control'
            value={props.rtn}
            onChange={async (e) => {
              props.setRtn(e.target.value);
              if (props.check === true) {
                console.log(e.target.value);
                consultarRTN(e.target.value);
              }
            }}
          />
        </div>
      </section>

      <section style={{ width: "35rem", marginTop: "1rem" }}>
        <div className='input-group mb-3'>
          <span
            style={{ fontSize: "1.8rem", backgroundColor: "#e5e5e5" }}
            className='text-span'
          >
            Nombre
          </span>
          <input
            style={{ fontSize: "1.8rem" }}
            type='text'
            className='form-control'
            onChange={(e) => {
              props.setN(e.target.value);
              if (props.check === false) {
                props.setN(e.target.value);
              }
            }}
            value={props.n}
          />
        </div>
      </section>
    </React.Fragment>
  );
}

export default TipoCliente;
