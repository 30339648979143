import React, { useEffect, useState } from "react";
import GuardarPago from "./GuardarPago";
import TipoCliente from "./TipoCliente";
import Factura from "./Factura";
import Menu from "./Menu";
import TipoPago from "./TipoPago";

const url = "https://parkingapi.ccg.hn:4000";

function Facturacion({ ...props }) {
  const fecha = new Date();
  //Variables para mostrar contenedores
  const [primerContenedor, setPrimerContenedor] = useState("");
  const [segundoContenedor, setSegundoContenedor] = useState("none");
  const [contenedorRTN, setcontenedorRTN] = useState("none");
  const [tercerContenedor, setTercerContenedor] = useState("none");
  const [cuartoContenedor, setcuartoContenedor] = useState("none");
  const [contenedorCasco, setContenedorCasco] = useState("none");
  const [check, setCheck] = useState(false);

  const [numero, setNumero] = useState("");
  const [valorB, setValorB] = useState("PHV");
  const [QR, setQR] = useState("");

  //variables para infromacion
  const [tipoc, setTipoc] = useState("Consumidor Final");
  const [rtn, setRtn] = useState("");
  const [nombre, setNombre] = useState("");
  const [n, setN] = useState("");
  const [HoraFactura, SetHoraFactura] = useState("");

  //variables que traen informacion del ticket

  const [ticket, setTicket] = useState("");
  const [identificador, setIdentificador] = useState("");
  const [hora, setHora] = useState("");
  const [NombreS, setNombreS] = useState("");

  //variables para enviar a Factura
  const [total, setTotal] = useState("");
  const [subtotal, setSubtotal] = useState("");
  const [isv, setIsv] = useState("");
  const [recibido, setRecibido] = useState(0);
  const [numeroC, setNumeroC] = useState("");

  //variables datos de Factura

  const [cai, setCai] = useState("");
  const [rango, setRango] = useState("");
  const [emision, setEmision] = useState("");
  const [nFactura, setNFactura] = useState("");
  const [rtn2, setRtn2] = useState("");

  const [disabled, setDisabled] = useState("");
  const [pago, setpago] = useState("Efectivo");
  const [banco, setbanco] = useState("");
  const [transferencia, settransferencia] = useState("");
  const [mTransferencia, setmTransferencia] = useState("none");
  const [check1, setcheck1] = useState(true);
  const [check2, setcheck2] = useState(false);
  const [check3, setcheck3] = useState(false);
  const [cortesia, setCortesia] = useState(false);
  const [nombreCortesia, setNombreCortesia] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [autorizoCortesia, setAutorizoCortesia] = useState("");

  function calcularDiferencia(hora1, hora2) {
    // Convertir las horas a formato 24 horas
    var t1 = hora1.split(":");
    var t2 = hora2.split(":");

    // Crear objetos de fecha
    var fecha1 = new Date(1970, 0, 1, t1[0], t1[1]);
    var fecha2 = new Date(1970, 0, 1, t2[0], t2[1]);

    // Calcular la diferencia en milisegundos
    var diff = fecha2.getTime() - fecha1.getTime();

    // Convertir la diferencia a minutos y redondear al entero más cercano
    var diffMinutos = Math.round(diff / 60000);

    return diffMinutos;
  }

  const calculoTiempo = (response) => {
    let hora = fecha.getHours().toString().padStart(2, "0");
    let minutos = fecha.getMinutes().toString().padStart(2, "0");
    SetHoraFactura(hora + ":" + minutos);

    const consultaHoraTicket = response[0].hora_Entrada;
    const horaGeneradoTicket = parseInt(consultaHoraTicket.slice(0, 2));
    const minutosGeneradoTicket = parseInt(consultaHoraTicket.slice(3, 5));

    const tiempoDeUsoEstacionamiento = calcularDiferencia(
      horaGeneradoTicket + ":" + minutosGeneradoTicket,
      hora + ":" + minutos
    );

    if (tiempoDeUsoEstacionamiento <= 15) {
      setTotal(parseFloat(0).toFixed(2));
    } else {
      if (response[0].identificador === "PHV") {
        if (parseInt(tiempoDeUsoEstacionamiento) <= parseInt(60)) {
          setTotal(parseFloat(25).toFixed(2));
        } else {
          const costoPorMinuto = parseFloat(
            parseFloat(25).toFixed(2) / 60
          ).toFixed(10);
          setTotal(
            Math.ceil(costoPorMinuto * tiempoDeUsoEstacionamiento).toFixed(2)
          );
        }
      } else if (response[0].identificador === "PHM") {
        if (parseInt(tiempoDeUsoEstacionamiento) <= parseInt(60)) {
          setTotal(parseFloat(10).toFixed(2));
        } else {
          const costoPorMinuto = parseFloat(
            parseFloat(10).toFixed(2) / 60
          ).toFixed(10);
          setTotal(
            Math.ceil(costoPorMinuto * tiempoDeUsoEstacionamiento).toFixed(2)
          );
        }
      }
    }
  };

  const guardarFactura = async () => {
    setDisabled("disabled");

    const fechaF =
      fecha.getFullYear() +
      "/" +
      parseInt(fecha.getMonth() + parseInt(1)) +
      "/" +
      fecha.getDate();

    console.log(cortesia);
    if (cortesia) {
      const id_Usuario = props.nombreI;

      if (nombreCortesia === "" || empresa === "" || autorizoCortesia === "") {
        setDisabled("");
        return alert("Los campos de cortesia son obligatorios");
      } else {
        const res = await fetch(`${url}/Cortesia`, {
          method: "put",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ticket,
            identificador,
            id_Usuario,
            fechaF,
            HoraFactura,
            total,
            nombreCortesia,
            empresa,
            autorizoCortesia,
          }),
        });

        const data = await res.json();
        if (data[0]) {
          setDisabled("");
          setCortesia(false);
          var checkbox = document.getElementById("cCortesia");
          checkbox.checked = false;

          alert("se guardó la cortesia del ticket N°" + ticket);
          return "opcion5";
        }
      }
    }

    if (recibido === "") {
      alert("No ha Ingresado Número en Recibido");
      const r = "opcion1";
      setDisabled("");
      return r;
    } else if (parseInt(recibido) < parseInt(total)) {
      alert("Error. Cantidad recibida es Menor al Total");
      const r = "opcion2";
      setDisabled("");
      return r;
    } else if (tipoc === "Otro" && rtn === "") {
      alert("No ha Ingresado RTN");
      const r = "opcion3";
      setDisabled("");
      return r;
    } else if (tipoc === "Otro" && n === "") {
      alert("No ha Ingresado Nombre");
      setDisabled("");
      const r = "opcion4";
      return r;
    } else {
      const id_Usuario = props.nombreI;
      const res = await fetch(`${url}/ConsultarDatosF`, {
        method: "put",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ticket,
          identificador,
          id_Usuario,
          fechaF,
          HoraFactura,
          total,
          recibido,
          tipoc,
          rtn,
          n,
          pago,
          banco,
          transferencia,
        }),
      });

      const data = await res.json();

      if (data[0] === "No Cobrar") {
        alert("No imprimir Factura");
      } else if (data[0] === "ErrorF") {
        alert("No se Creó Factura");
      } else {
        console.log(data);
        setCai(data[0].cai);
        setRango(data[0].RangoInicial);
        setEmision(data[0].fechaEmision);
        setNFactura(data[0].id_Factura);
        setRtn2(data[0].RTN);
      }

      const r = "opcion5";
      return r;
    }
  };

  const consultarTicket = async () => {
    if (numero === "") {
      alert("No ha ingresado Número de Ticket");
    } else {
      const res = await fetch(`${url}/consultarTicket`, {
        method: "put",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          valorB,
          numero,
          QR,
        }),
      });

      const data = await res.json();

      console.log("la data es" + data);
      console.log("la data es" + data[0]);

      if (data[0] === "NFacturado") {
        alert("Error(1) en Consulta, consulte de nuevo");
        return data;
      } else if (data[0] === "Existe en no Facturado") {
        alert("Aplica cortesía de 15 minutos");
        setNumero("");
        setValorB("PHV");
        return data;
      } else if (data[0] === "Existe en cortesia") {
        alert("Ticket con Cortesia");
        setValorB("PHV");
        setNumero("");
        return data;
      } else if (data[0] === "Facturado") {
        alert("Ticket Facturado");
        setValorB("PHV");
        setNumero("");
        return data;
      } else if (data.length === 0) {
        alert("No Existe Ticket");
        const r2 = ["No Existe Ticket"];
        setNumero("");
        setValorB("PHV");
        return r2;
      } else {
        setPrimerContenedor("none");
        setSegundoContenedor("");
        setTicket(data[0].id_Ticket);
        setIdentificador(data[0].identificador);
        setHora(data[0].hora_Entrada);
        setNombreS(data[0].nombre_Servicio);
        if (
          data[0].identificador === "PHM" ||
          data[0].identificador === "PDM"
        ) {
          setNumeroC(data[0].ncasco);
          setContenedorCasco("");
        }
        return data;
      }
    }
  };

  const calculoP = (restaHora, restaminutos, costoP, impuestoP) => {
    if (restaHora === 0 && restaminutos > 15) {
      setTotal(parseFloat(costoP) + parseFloat(impuestoP));
    } else if (restaHora > 0 && restaminutos > 0) {
      setTotal(
        (parseFloat(costoP) + parseFloat(impuestoP)) *
          (parseFloat(restaHora) + parseInt(1))
      );
    } else if (restaHora > 0 && restaminutos === 0) {
      setTotal(
        (parseFloat(costoP) + parseFloat(impuestoP)) * parseFloat(restaHora)
      );
    } else if (restaHora > 0 && restaminutos < 0) {
      setTotal(
        (parseFloat(costoP) + parseFloat(impuestoP)) *
          (parseFloat(restaHora) - parseInt(1))
      );
    } else if (restaHora > 0 && restaminutos > 0) {
      restaHora = parseInt(restaHora) + parseInt(1);
      setTotal(
        (parseFloat(costoP) + parseFloat(impuestoP)) * parseFloat(restaHora)
      );
    }
  };

  const agregarCierre = async () => {
    const id_Usuario = props.nombreI;
    const valorContado = prompt("Ingresar cantidad de efectivo de cierre:");

    if (valorContado === null) {
      return alert("Se ha cancelado agregar cierre");
    }
    const valorFinal = prompt(
      "¿Está seguro de guardar el cierre con la cantidad de: " +
        valorContado +
        " ?"
    );

    if (valorFinal === null) {
      return alert("Se ha cancelado agregar cierre");
    }
    let fecha = new Date();

    let dia = String(fecha.getDate()).padStart(2, "0");
    let mes = String(fecha.getMonth() + 1).padStart(2, "0"); // Los meses en JavaScript empiezan en 0
    let ano = fecha.getFullYear();

    let horas = String(fecha.getHours()).padStart(2, "0");
    let minutos = String(fecha.getMinutes()).padStart(2, "0");
    let segundos = String(fecha.getSeconds()).padStart(2, "0");

    let fechaMySQL = `${ano}-${mes}-${dia} ${horas}:${minutos}:${segundos}`;

    const res = await fetch(`${url}/cierre`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id_Usuario,
        valorFinal,
        fecha: fechaMySQL,
      }),
    });

    const data = await res.json();
    console.log(data);
    if (data[0]) {
      alert("Cierre agregado Correctamente");
    } else {
      alert("Error al crear ticket");
    }
  };

  return (
    <React.Fragment>
      <div
        className='contenedorFacturacion'
        style={{ display: `${primerContenedor}` }}
      >
        <div>
          <button
            type='button'
            className='btn btn-warning cambiarTicket'
            onClick={() => {
              setcuartoContenedor("");
              setPrimerContenedor("none");
            }}
          >
            Menú
          </button>
        </div>
        <label>Servicio</label>
        <section style={{ marginBottom: "1rem" }}>
          <div className='btn-group'>
            <button
              style={{ fontSize: "2rem" }}
              type='button'
              className='btn btn-primary2'
              onClick={() => {
                setValorB("PHV");
              }}
            >
              PHV
            </button>
            <button
              style={{ fontSize: "2rem" }}
              type='button'
              className='btn btn-primary2'
              onClick={() => {
                setValorB("PHM");
              }}
            >
              PHM
            </button>
            <button
              style={{ fontSize: "2rem" }}
              type='button'
              className='btn btn-primary2'
              onClick={() => {
                setQR("si");
                setValorB("QR");
              }}
            >
              QR
            </button>
          </div>
        </section>
        <section style={{ display: "flex", flexDirection: "column" }}>
          <label>Número de Ticket</label>
          <section>
            <span style={{ fontSize: "2rem" }}>{valorB}</span>
            <input
              type='text'
              className='inputF'
              value={numero}
              onChange={async (e) => {
                setNumero(e.target.value);
              }}
            />
          </section>
        </section>

        <button
          className='btn botonConsultarTicket'
          onClick={() => {
            consultarTicket().then((response) => {
              console.log(response);
              if (
                response[0] === "NFacturado" ||
                response[0] === "Existe en no Facturado" ||
                response[0] === "Existe en cortesia" ||
                response[0] === "Facturado" ||
                response[0] === "No Existe Ticket"
              ) {
              } else {
                calculoTiempo(response);
              }
              return;
            });
          }}
        >
          Consultar Ticket
        </button>

        <button
          className='btn botonCrearCierre'
          onClick={() => {
            agregarCierre();
          }}
        >
          Guardar Cierre
        </button>
      </div>

      <div
        style={{ display: `${segundoContenedor}` }}
        className='ContenedorGuardarPago'
      >
        <GuardarPago
          setTipoc={setTipoc}
          setcontenedorRTN={setcontenedorRTN}
          setRtn={setRtn}
          setNombre={setNombre}
          setN={setN}
          setCheck={setCheck}
        ></GuardarPago>

        <div style={{ display: `${contenedorRTN}` }}>
          <TipoCliente
            setRtn={setRtn}
            setNombre={setNombre}
            rtn={rtn}
            setN={setN}
            n={n}
            check={check}
            setCheck={setCheck}
          ></TipoCliente>
        </div>

        <div className='contenedorInfoF'>
          <label>Ticket:{identificador + ticket}</label>
          <label style={{ width: "auto" }}>Servicio:{NombreS}</label>
          <label>
            Hora Entrada:
            <span style={{ padding: "0px" }} className='alert alert-warning'>
              {" "}
              {hora}
            </span>
          </label>
          <label>
            Hora Salida:
            <span style={{ padding: "0px" }} className='alert alert-warning'>
              {HoraFactura}
            </span>
          </label>
          <label>Subtotal: {parseFloat(total / 1.15).toFixed(2)}</label>
          <label>
            ISV:{(parseFloat(total) - parseFloat(total / 1.15)).toFixed(2)}
          </label>
          <label>
            Total:
            <span style={{ padding: "0px" }} className='alert alert-success'>
              {total}
            </span>
          </label>

          <TipoPago
            setpago={setpago}
            check1={check1}
            check2={check2}
            check3={check3}
            setcheck1={setcheck1}
            setcheck2={setcheck2}
            setcheck3={setcheck3}
            banco={banco}
            setbanco={setbanco}
            transferencia={transferencia}
            settransferencia={settransferencia}
            setmTransferencia={setmTransferencia}
            mTransferencia={mTransferencia}
          />

          <div className='input-group mb-3'>
            <span style={{ fontSize: "2.4rem" }}>Recibido: </span>
            <span
              style={{ fontSize: "1.8rem", backgroundColor: "#e5e5e5" }}
              className='text-span'
            >
              LPS
            </span>
            <input
              style={{ fontSize: "1.8rem" }}
              type='number'
              min='0'
              placeholder='0'
              className='form-control'
              value={recibido}
              onChange={(e) => {
                setRecibido(e.target.value);
              }}
            />
            <span
              style={{ fontSize: "1.8rem", backgroundColor: "#e5e5e5" }}
              className='text-span'
            >
              .00
            </span>
          </div>
          <label>
            Cambio:
            <span style={{ padding: "0px" }} className='alert alert-danger'>
              {parseInt(recibido) - parseInt(total)}
            </span>
          </label>
          <label
            style={{
              marginTop: "10px",
              display:
                props.rol === "Admin" || props.rol === "Facturación Cortesia"
                  ? null
                  : "none",
            }}
          >
            <input
              type='checkbox'
              id='cCortesia'
              onClick={() => {
                setCortesia(!cortesia);
              }}
            />
            APLICAR CORTESIA
          </label>

          <div
            style={{
              display: `${cortesia ? "" : "none"}`,
              marginBottom: "10px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label>Cortesia asignada a:</label>
              <input
                type='text'
                style={{ fontSize: "2.5rem" }}
                value={nombreCortesia}
                onChange={(e) => setNombreCortesia(e.target.value)}
              />
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <label>Empresa/Institución:</label>
              <input
                type='text'
                style={{ fontSize: "2.5rem" }}
                value={empresa}
                onChange={(e) => setEmpresa(e.target.value)}
              />
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <label>Autorizada Por:</label>
              <input
                type='text'
                style={{ fontSize: "2.5rem" }}
                value={autorizoCortesia}
                onChange={(e) => setAutorizoCortesia(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div style={{ width: "35rem" }} className='d-grid'>
          <button
            className='btn btn-primary enviarF'
            disabled={disabled}
            onClick={() => {
              guardarFactura().then((response) => {
                console.log(response);
                if (
                  response === "opcion1" ||
                  response === "opcion2" ||
                  response === "opcion3" ||
                  response === "opcion4"
                ) {
                } else if (response === "opcion5") {
                  setPrimerContenedor("none");
                  setSegundoContenedor("none");
                  setTercerContenedor("");
                  setTimeout(() => {
                    window.print();
                  }, 500);
                }
              });
            }}
          >
            Guardar Factura
          </button>
        </div>
      </div>

      <div style={{ display: `${tercerContenedor}` }}>
        <Factura
          tipoc={tipoc}
          cai={cai}
          emision={emision}
          nFactura={nFactura}
          rtn2={rtn2}
          rtn={rtn}
          n={n}
          NombreS={NombreS}
          hora={hora}
          HoraFactura={HoraFactura}
          total={total}
          recibido={recibido}
          pago={pago}
          banco={banco}
          transferencia={transferencia}
          mTransferencia={mTransferencia}
          setPrimerContenedor={setPrimerContenedor}
          setSegundoContenedor={setSegundoContenedor}
          setTercerContenedor={setTercerContenedor}
          setcontenedorRTN={setcontenedorRTN}
          setNumero={setNumero}
          setValorB={setValorB}
          setQR={setQR}
          setRtn={setRtn}
          setN={setN}
          SetHoraFactura={SetHoraFactura}
          setTicket={setTicket}
          setIdentificador={setIdentificador}
          setHora={setHora}
          setNombreS={setNombreS}
          setTotal={setTotal}
          setRecibido={setRecibido}
          setCheck={setCheck}
          numeroC={numeroC}
          setNumeroC={setNumeroC}
          setContenedorCasco={setContenedorCasco}
          contenedorCasco={contenedorCasco}
          setTipoc={setTipoc}
          setDisabled={setDisabled}
          setpago={setpago}
          setbanco={setbanco}
          settransferencia={settransferencia}
          setmTransferencia={setmTransferencia}
          setNFactura={setNFactura}
          setcheck1={setcheck1}
          setcheck2={setcheck2}
          setcheck3={setcheck3}
        ></Factura>
      </div>

      <div style={{ display: `${cuartoContenedor}` }}>
        <Menu nombreI={props.nombreI} rol={props.rol}></Menu>
      </div>
    </React.Fragment>
  );
}

export default Facturacion;
