import React, { useState, useEffect, forwardRef, useRef } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Loader from "./Loader";
import Filtros from "./Filtros";

const url = "https://parkingapi.ccg.hn:4000";

function Tabla({ ...props }) {
  const fecha = new Date();
  const [mostrarL, setmostrarL] = useState("");
  const [mostrarT, setmostrarT] = useState("none");
  const [mostrarF, setmostrarF] = useState("none");
  const [mostrarModal, setMostrarModal] = useState("none");
  const [registroMensual, setRegistroMensual] = useState([]);
  const [registroMensualPersona, setRegistroMensualPersona] = useState([]);

  const [costo, setCosto] = useState([]);
  const [cantidadMeses, setcantidadMeses] = useState([]);

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const [datos, setdatos] = useState([]);

  const obtenerDatos1 = async () => {
    const fecha2 =
      fecha.getFullYear() +
      "/" +
      parseInt(fecha.getMonth()) +
      parseInt(1) +
      "/" +
      fecha.getDay();
    const tiporeporte = "NoExcel";
    const res = await fetch(`${url}/reporte2`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fecha2,
        tiporeporte,
      }),
    });
    const data = await res.json();
    setdatos(data);
    setmostrarL("none");
    setmostrarT("");
  };

  const obtenerDatos2 = async () => {
    const fecha2 =
      fecha.getFullYear() +
      "/" +
      (parseInt(fecha.getMonth()) + parseInt(1)) +
      "/" +
      fecha.getDate();

    const TipoR = "Diario";
    const res = await fetch(`${url}/consultarReporte1`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fecha2,
        TipoR,
      }),
    });
    const data = await res.json();

    const res2 = await fetch(`${url}/ConsultarRTNReporte1`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fecha2,
        TipoR,
      }),
    });
    const data2 = await res2.json();

    const res3 = await fetch(`${url}/consultarTransferencia`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fecha2,
        TipoR,
      }),
    });

    const data3 = await res3.json();

    const res4 = await fetch(`${url}/datosm2`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fecha2,
        TipoR,
      }),
    });

    const data4 = await res4.json();

    let array = [];
    let rtnconnsultado;
    let nombreConsultado;
    let bancoConsultado;
    let transferenciaConsultado;
    let TipoFuncionarioC;
    let TipoEstacionamientoC;
    let TipoPagoC;
    let NombreC;
    let TipoVehiculoC;
    let MarcaC;
    let ModeloC;
    let PlacaC;
    let TelefonoC;
    let InstituciónC;

    data.map((result) => {
      const find = data2.find(
        (element) =>
          element["id_Ticket"] === result["Código Ticket"] &&
          element["nombre_Servicio"] === result["Nombre de Servicio"]
      );

      const find2 = data3.find(
        (element) => element["id_Factura"] === result["Número de Factura"]
      );

      const find3 = data4.find(
        (element) =>
          element["Número de Factura"] === result["Número de Factura"]
      );

      rtnconnsultado = "";
      nombreConsultado = "";
      bancoConsultado = "";
      transferenciaConsultado = "";
      TipoFuncionarioC = "";
      TipoEstacionamientoC = "";
      TipoPagoC = "";
      NombreC = "";
      TipoVehiculoC = "";
      MarcaC = "";
      ModeloC = "";
      PlacaC = "";
      TelefonoC = "";
      InstituciónC = "";

      if (find != undefined) {
        rtnconnsultado = find["RTN"];
        nombreConsultado = find["nombre"];
      }

      if (find2 != undefined) {
        bancoConsultado = find2["nombreBanco"];
        transferenciaConsultado = find2["numero_Tranferencia"];
      }

      if (find3 != undefined) {
        TipoFuncionarioC = find3["Tipo de Funcionario"];
        TipoEstacionamientoC = find3["Tipo de Estacionamiento"];
        TipoPagoC = find3["Tipo de Pago"];
        NombreC = find3["Nombre"];
        TipoVehiculoC = find3["Tipo de Vehículo"];
        MarcaC = find3["Marca"];
        ModeloC = find3["Modelo"];
        PlacaC = find3["Placa"];
        TelefonoC = find3["Teléfono"];
        InstituciónC = find3["Institución"];
      }

      array.push({
        "Código Ticket": result["Código Ticket"],
        "Nombre de Servicio": result["Nombre de Servicio"],
        "Número de Factura": result["Número de Factura"],
        "Tipo de Cliente": result["Tipo de Cliente"],
        Fecha: result["Fecha"].slice(0, 10),
        "Hora de Entrada": result["Hora de Entrada"],
        "Hora de Salida": result["Hora de Salida"],
        SubTotal: result["SubTotal"],
        ISV: result["ISV"],
        total: result["Total"],
        "Forma de Pago": result["Forma de Pago"],
        Banco: bancoConsultado,
        "N° Tranferencia": transferenciaConsultado,
        Usuario: result["Nombre Usuario"],
        Estado: result["Estado"],
        RTN: rtnconnsultado,
        NombreRTN: nombreConsultado,
        "Tipo de Funcionario": TipoFuncionarioC,
        "Tipo de Estacionamiento": TipoEstacionamientoC,
        "Tipo de Pago": TipoPagoC,
        Nombre: NombreC,
        "Tipo de Vehículo": TipoVehiculoC,
        Marca: MarcaC,
        Modelo: ModeloC,
        Placa: PlacaC,
        Teléfono: TelefonoC,
        Institución: InstituciónC,
      });
    });
    setdatos(
      array.sort((a, b) => a["Número de Factura"] - b["Número de Factura"])
    );
    setmostrarL("none");
    setmostrarT("");
    setmostrarF("");
  };

  const obtenerDatos3 = async () => {
    const fecha3 = fecha.getMonth();

    const res = await fetch(`${url}/datosm`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fecha3,
      }),
    });

    const data = await res.json();
    setdatos(data);
    setmostrarL("none");
    setmostrarT("");
    setmostrarF("");
  };

  const obtenerDatos4 = async () => {
    const fecha4 =
      fecha.getFullYear() +
      "/" +
      (parseInt(fecha.getMonth()) + parseInt(1)) +
      "/" +
      fecha.getDate();
    const res = await fetch(`${url}/datosET`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fecha4,
      }),
    });
    const data = await res.json();

    setdatos(data);
    setmostrarL("none");
    setmostrarT("");
    setmostrarF("");
  };

  const obtenerDatos5 = async () => {
    const numeroMes = fecha.getMonth();
    const res = await fetch(`${url}/reporteFacturaN`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        numeroMes,
      }),
    });
    const data = await res.json();
    setdatos(data);
    setmostrarL("none");
    setmostrarT("");
    setmostrarF("");
  };

  const obtenerDatos6 = async () => {
    const fecha2 = fecha.getMonth();
    const TipoR = "Mensual";

    const res = await fetch(`${url}/consultarReporte1`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fecha2,
        TipoR,
      }),
    });
    const data = await res.json();

    const res2 = await fetch(`${url}/ConsultarRTNReporte1`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fecha2,
        TipoR,
      }),
    });
    const data2 = await res2.json();

    const res3 = await fetch(`${url}/consultarTransferencia`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fecha2,
        TipoR,
      }),
    });

    const data3 = await res3.json();

    const res4 = await fetch(`${url}/datosm2`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fecha2,
        TipoR,
      }),
    });

    const data4 = await res4.json();

    let array = [];
    let rtnconnsultado;
    let nombreConsultado;
    let bancoConsultado;
    let transferenciaConsultado;
    let TipoFuncionarioC;
    let TipoEstacionamientoC;
    let TipoPagoC;
    let NombreC;
    let TipoVehiculoC;
    let MarcaC;
    let ModeloC;
    let PlacaC;
    let TelefonoC;
    let InstituciónC;

    data.map((result) => {
      const find = data2.find(
        (element) =>
          element["id_Ticket"] === result["Código Ticket"] &&
          element["nombre_Servicio"] === result["Nombre de Servicio"]
      );

      const find2 = data3.find(
        (element) => element["id_Factura"] === result["Número de Factura"]
      );

      const find3 = data4.find(
        (element) =>
          element["Número de Factura"] === result["Número de Factura"]
      );

      rtnconnsultado = "";
      nombreConsultado = "";
      bancoConsultado = "";
      transferenciaConsultado = "";
      TipoFuncionarioC = "";
      TipoEstacionamientoC = "";
      TipoPagoC = "";
      NombreC = "";
      TipoVehiculoC = "";
      MarcaC = "";
      ModeloC = "";
      PlacaC = "";
      TelefonoC = "";
      InstituciónC = "";

      if (find != undefined) {
        rtnconnsultado = find["RTN"];
        nombreConsultado = find["nombre"];
      }

      if (find2 != undefined) {
        bancoConsultado = find2["nombreBanco"];
        transferenciaConsultado = find2["numero_Tranferencia"];
      }

      if (find3 != undefined) {
        TipoFuncionarioC = find3["Tipo de Funcionario"];
        TipoEstacionamientoC = find3["Tipo de Estacionamiento"];
        TipoPagoC = find3["Tipo de Pago"];
        NombreC = find3["Nombre"];
        TipoVehiculoC = find3["Tipo de Vehículo"];
        MarcaC = find3["Marca"];
        ModeloC = find3["Modelo"];
        PlacaC = find3["Placa"];
        TelefonoC = find3["Teléfono"];
        InstituciónC = find3["Institución"];
      }

      array.push({
        "Código Ticket": result["Código Ticket"],
        "Nombre de Servicio": result["Nombre de Servicio"],
        "Número de Factura": result["Número de Factura"],
        "Tipo de Cliente": result["Tipo de Cliente"],
        Fecha: result["Fecha"].slice(0, 10),
        "Hora de Entrada": result["Hora de Entrada"],
        "Hora de Salida": result["Hora de Salida"],
        SubTotal: result["SubTotal"],
        ISV: result["ISV"],
        total: result["Total"],
        "Forma de Pago": result["Forma de Pago"],
        Banco: bancoConsultado,
        "N° Tranferencia": transferenciaConsultado,
        Usuario: result["Nombre Usuario"],
        Estado: result["Estado"],
        RTN: rtnconnsultado,
        NombreRTN: nombreConsultado,
        "Tipo de Funcionario": TipoFuncionarioC,
        "Tipo de Estacionamiento": TipoEstacionamientoC,
        "Tipo de Pago": TipoPagoC,
        Nombre: NombreC,
        "Tipo de Vehículo": TipoVehiculoC,
        Marca: MarcaC,
        Modelo: ModeloC,
        Placa: PlacaC,
        Teléfono: TelefonoC,
        Institución: InstituciónC,
      });
    });
    setdatos(
      array.sort((a, b) => a["Número de Factura"] - b["Número de Factura"])
    );
    setmostrarL("none");
    setmostrarT("");
    setmostrarF("");
  };

  const obtenerDatos7 = async () => {
    const numeroMes = fecha.getMonth();
    const res = await fetch(`${url}/ReporteF`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        numeroMes,
      }),
    });
    const data = await res.json();

    setdatos(data);
    setmostrarL("none");
    setmostrarT("");
    setmostrarF("");
  };

  const reporteTicketF = async () => {
    const texto = "Dia";
    const fecha2 =
      fecha.getFullYear() +
      "/" +
      parseInt(fecha.getMonth() + 1) +
      "/" +
      fecha.getDate();
    const res = await fetch(`${url}/reporteTicketF`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        texto,
        fecha2,
      }),
    });
    const data = await res.json();

    console.log(data);

    var array = [
      {
        "Nombre Servicio": "Parqueo x Hora Vehículo",
        "Cantidad Ticket": data[0].Contador,
        "Facturas Creadas": data[1].Contador,
        "Ticket Gratis": data[2].Contador,
      },
      {
        "Nombre Servicio": "Parqueo x Hora Motocilceta",
        "Cantidad Ticket": data[3].Contador,
        "Facturas Creadas": data[4].Contador,
        "Ticket Gratis": data[5].Contador,
      },
      {
        "Nombre Servicio": "Parqueo x Día Vehículo",
        "Cantidad Ticket": data[6].Contador,
        "Facturas Creadas": data[7].Contador,
        "Ticket Gratis": "No Aplica",
      },
      {
        "Nombre Servicio": "Parqueo x Día Motocicleta",
        "Cantidad Ticket": data[8].Contador,
        "Facturas Creadas": data[9].Contador,
        "Ticket Gratis": "No Aplica",
      },
      {
        "Nombre Servicio": "Parqueo Mensual",
        "Cantidad Ticket": data[10].Contador,
        "Facturas Creadas": data[10].Contador,
        "Ticket Gratis": "No Aplica",
      },
      {
        "Nombre Servicio": "Extravío Ticket V",
        "Cantidad Ticket": data[11].Contador,
        "Facturas Creadas": data[11].Contador,
        "Ticket Gratis": "No Aplica",
      },
      {
        "Nombre Servicio": "Extravío Ticket M",
        "Cantidad Ticket": data[12].Contador,
        "Facturas Creadas": data[12].Contador,
        "Ticket Gratis": "No Aplica",
      },
    ];
    setdatos(array);
    console.log(array);
    setmostrarL("none");
    setmostrarT("");
    setmostrarF("");
  };

  let columnas;

  const llenadoDatos = () => {
    if (props.tituloReporte === "Reporte Facturacion por Usuario") {
      obtenerDatos1();
    } else if (props.tituloReporte === "Reporte Facturacion por Día") {
      obtenerDatos2();
    } else if (props.tituloReporte === "Reporte Pago Mensualidad") {
      obtenerDatos3();
    } else if (props.tituloReporte === "Reporte Pago Ticket Extraviado") {
      obtenerDatos4();
    } else if (props.tituloReporte === "Reporte de Facturas Nulas") {
      obtenerDatos5();
    } else if (props.tituloReporte === "Reporte Facturación por Mes") {
      obtenerDatos6();
    } else if (props.tituloReporte === "Reporte General facturación") {
      obtenerDatos7();
    } else if (props.tituloReporte === "Reporte Creación Ticket's/Facturas") {
      reporteTicketF();
    } else if (props.tituloReporte === "Reporte Ticket Cortesia") {
      obtenerDatos9();
    } else if (props.tituloReporte === "Registro Parqueo Mensual") {
      obtenerDatos10();
    }
  };

  const obtenerDatos9 = async () => {
    const fecha4 =
      fecha.getFullYear() +
      "/" +
      (parseInt(fecha.getMonth()) + parseInt(1)) +
      "/" +
      fecha.getDate();
    const res = await fetch(`${url}/datosCortesia`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fecha4,
      }),
    });
    const data = await res.json();

    setdatos(data);
    setmostrarL("none");
    setmostrarT("");
    setmostrarF("");
  };

  const obtenerDatos10 = async () => {
    const res = await fetch(`${url}/registrosParqueoMensual`);
    const data = await res.json();
    console.log(data);
    setdatos(data);
    setmostrarL("none");
    setmostrarT("");
    setmostrarF("");
  };
  const obtenerRegistroParqueoMensual = async (value) => {
    const res = await fetch(`${url}/registroParqueoMensual/${value}`);
    const data = await res.json();
    setRegistroMensualPersona(data);
  };

  function diffInMonths(date1, date2) {
    let months;
    months = (date2.getFullYear() - date1.getFullYear()) * 12;
    months -= date1.getMonth();
    months += date2.getMonth();
    return months <= 0 ? 0 : months;
  }

  const calcularResumen = () => {
    console.log(registroMensualPersona);
    let fechas = registroMensualPersona.map((obj) => new Date(obj.Fecha));
    console.log(fechas);
    let minFecha = new Date(Math.min.apply(null, fechas));
    let maxFecha = new Date(Math.max.apply(null, fechas));
    let meses = diffInMonths(minFecha, maxFecha);
    let fechaActual = new Date();
    let mesesfechaActual = diffInMonths(maxFecha, fechaActual);

    console.log("fecha Maxima" + maxFecha);
    console.log("fecha minima" + minFecha);
    console.log("fecha actual" + fechaActual);
    console.log("meses entre fechas" + meses);
    console.log("meses entre ultima y actual" + mesesfechaActual);

    let mesesEntreFecha = 0;
    if (meses !== registroMensualPersona.length) {
      mesesEntreFecha = meses - registroMensualPersona.length;
    }
    setCosto(registroMensualPersona[0].total);
    let sumaMeses = parseInt(mesesEntreFecha) + parseInt(mesesfechaActual);
    console.log("cantidad de meses" + sumaMeses);
    setcantidadMeses(sumaMeses);
  };

  useEffect(() => {
    llenadoDatos();
  }, [props.tituloReporte]);

  useEffect(() => {
    if (registroMensualPersona.length > 0) {
      calcularResumen();
    }
  }, [registroMensualPersona]);

  if (props.tituloReporte === "Reporte Facturacion por Usuario") {
    columnas = [
      { title: "Usuario", field: "nombre" },
      { title: "Cantidad de Facturas", field: "cantidad" },
      { title: "Total", field: "total" },
      { title: "Total en Efectivo", field: "Total en Efectivo" },
      { title: "Total Transferencia", field: "Total Transferencia" },
      { title: "Total Tarjeta", field: "Total Tarjeta" },
      { title: "Cantidad Cierre", field: "Cantidad Cierre" },
      { title: "Fecha", field: "Fecha" },
    ];
  } else if (
    props.tituloReporte === "Reporte Facturacion por Día" ||
    props.tituloReporte === "Reporte Facturación por Mes"
  ) {
    columnas = [
      {
        title: "Código Ticket",
        field: "Código Ticket",
        cellStyle: { with: "auto" },
      },
      { title: "Nombre de Servicio", field: "Nombre de Servicio" },
      { title: "Número de Factura", field: "Número de Factura" },
      { title: "Tipo de Cliente", field: "Tipo de Cliente" },
      { title: "Fecha", field: "Fecha" },
      { title: "Hora de Entrada", field: "Hora de Entrada" },
      { title: "Hora de Salida", field: "Hora de Salida" },
      { title: "SubTotal", field: "SubTotal" },
      { title: "ISV", field: "ISV" },
      { title: "Total", field: "total" },
      { title: "Usuario", field: "Usuario" },
      { title: "Estado", field: "Estado" },
      { title: "Forma de Pago", field: "Forma de Pago" },
      { title: "Banco", field: "Banco" },
      { title: "N° Tranferencia", field: "N° Tranferencia" },
      { title: "RTN", field: "RTN" },
      { title: "NombreRTN", field: "NombreRTN" },
      { title: "Tipo de Funcionario", field: "Tipo de Funcionario" },
      { title: "Tipo de Estacionamiento", field: "Tipo de Estacionamiento" },
      { title: "Tipo de Pago", field: "Tipo de Pago" },
      { title: "Nombre", field: "Nombre" },
      { title: "Tipo de Vehículo", field: "Tipo de Vehículo" },
      { title: "Marca", field: "Marca" },
      { title: "Modelo", field: "Modelo" },
      { title: "Placa", field: "Placa" },
      { title: "Teléfono", field: "Teléfono" },
      { title: "Institución", field: "Institución" },
    ];
  } else if (props.tituloReporte === "Reporte Pago Mensualidad") {
    columnas = [
      { title: "Número de Factura", field: "Número de Factura" },
      { title: "Fecha", field: "Fecha" },
      { title: "Usuario", field: "Usuario" },
      { title: "Tipo de Funcionario", field: "Tipo de Funcionario" },
      { title: "Tipo de Estacionamiento", field: "Tipo de Estacionamiento" },
      { title: "Tipo de Pago", field: "Tipo de Pago" },
      { title: "Nombre", field: "Nombre" },
      { title: "Tipo de Vehículo", field: "Tipo de Vehículo" },
      { title: "Marca", field: "Marca" },
      { title: "Modelo", field: "Modelo" },
      { title: "Placa", field: "Placa" },
      { title: "Teléfono", field: "Teléfono" },
      { title: "total", field: "total" },
      { title: "Institución", field: "Institución" },
    ];
  } else if (props.tituloReporte === "Reporte Pago Ticket Extraviado") {
    columnas = [
      { title: "Número de Factura", field: "Número Factura" },
      { title: "Fecha", field: "Fecha" },
      { title: "Usuario", field: "Usuario" },
      { title: "Tipo de Servicio", field: "Tipo de Servicio" },
      { title: "Nombre", field: "Nombre" },
      { title: "Tipo Vehículo", field: "Tipo Vehículo" },
      { title: "Marca", field: "Marca" },
      { title: "Modelo", field: "Modelo" },
      { title: "Placa", field: "Placa" },
      { title: "Teléfono", field: "Teléfono" },
    ];
  } else if (props.tituloReporte === "Reporte de Facturas Nulas") {
    columnas = [
      { title: "Número de Factura", field: "id_Factura" },
      { title: "Fecha", field: "Fecha" },
      { title: "Estado", field: "Estado" },
    ];
  } else if (props.tituloReporte === "Reporte General facturación") {
    columnas = [
      { title: "Fecha", field: "Fecha" },
      { title: "Número de Factura", field: "id_Factura" },
      { title: "nombre_Servicio", field: "nombre_Servicio" },
      { title: "SubTotal", field: "subtotal" },
      { title: "ISV", field: "ISV" },
      { title: "Total", field: "total" },
    ];
  } else if (props.tituloReporte === "Reporte Creación Ticket's/Facturas") {
    columnas = [
      {
        title: "Nombre Servicio",
        field: "Nombre Servicio",
        cellStyle: { backgroundColor: "#01579b", color: "#FFF" },
      },
      { title: "Cantidad Ticket", field: "Cantidad Ticket" },
      { title: "Facturas Creadas", field: "Facturas Creadas" },
      { title: "Ticket Gratis", field: "Ticket Gratis" },
    ];
  } else if (props.tituloReporte === "Reporte Ticket Cortesia") {
    columnas = [
      { title: "Número de Ticket", field: "Número de Ticket" },
      { title: "Nombre del Servicio", field: "Nombre del Servicio" },
      { title: "Usuario", field: "Usuario" },
      { title: "Fecha de Ticket", field: "Fecha de Ticket" },
      { title: "Fecha de Cortesia", field: "Fecha de Cortesia" },
      { title: "Hora de Salida", field: "hora_Salida" },
      { title: "subtotal", field: "subtotal" },
      { title: "ISV", field: "ISV" },
      { title: "total", field: "total" },
      { title: "Cortesia Asignada A", field: "Cortesia Asignada a" },
      { title: "Empresa/Institución", field: "Empresa/Institución" },
      { title: "Autorizó", field: "Autorizó" },
    ];
  } else if (props.tituloReporte === "Registro Parqueo Mensual") {
    columnas = [
      { title: "Nombre", field: "nombre" },
      { title: "Tipo Vehículo", field: "Tipo_Vehiculo" },
      { title: "Marca", field: "marca" },
      { title: "Modelo", field: "modelo" },
      { title: "Placa", field: "Placa" },
      { title: "Nombre Institución", field: "nombre_Institucion" },
    ];
  }

  return (
    <React.Fragment>
      <div
        style={{
          height: "100vh",
          display: `${mostrarT}`,
          tableLayout: "fixed",
          whiteSpace: "nowrap",
          background: "white",
        }}
      >
        <div style={{ height: "80vh" }}>
          <MaterialTable
            id='table-to-xls'
            style={{ zIndex: "1" }}
            title={<h1>{props.tituloReporte}</h1>}
            icons={tableIcons}
            columns={columnas}
            data={datos}
            localization={{
              pagination: {
                labelDisplayedRows: "{from} - {to} de {count}",
                labelRowsPerPage: "resultados por página",
                firstAriaLabel: "Primera página",
                firstTooltip: "Primera página",
                previousAriaLabel: "página anterior",
                previousTooltip: "página anterior",
                nextAriaLabel: "Siguiente página",
                nextTooltip: "Siguiente página",
                lastAriaLabel: "Última página",
                lastTooltip: "Última página",
                labelRowsSelect: "Filas",
              },
              toolbar: {
                nRowsSelected: "{0} row(s) selected",
              },
              header: {
                actions: "Acción",
              },
              body: {
                emptyDataSourceMessage: "No se encontraron registros",
                filterRow: {
                  filterTooltip: "Filter",
                },
              },
            }}
            actions={[
              {
                icon: () => (
                  <button className='botonActualizar'>Actualizar Datos</button>
                ),
                tooltip: "Actualizar",
                onClick: () => {
                  setdatos([]);
                  setmostrarT("none");
                  setmostrarL("");
                  llenadoDatos();
                },
                isFreeAction: true,
              },
              {
                icon: () => <button className='botonRegresar'>Regresar</button>,
                tooltip: "Regresar a pantalla anterior",
                onClick: () => {
                  props.setmostrarTabla("none");
                  setmostrarT("none");
                  setmostrarL("");
                  setdatos();
                  props.settituloReporte("");
                  props.setmostrarUltimaFactura("");
                  props.setmostrarBotonMenu("");
                },
                isFreeAction: true,
              },
              {
                icon: () =>
                  props.tituloReporte === "Registro Parqueo Mensual" ? (
                    <button className='btn'>Ver Detalle</button>
                  ) : null,
                tooltip: "Ver Detalles de Facturación",
                onClick: (event, rowData) => {
                  obtenerRegistroParqueoMensual(rowData["Placa"]);
                  setMostrarModal("");
                },
              },
            ]}
            options={{
              filtering: true,
              pageSize: 10,
              headerStyle: {
                backgroundColor: "#01579b",
                color: "#FFF",
                fontSize: "1.8rem",
              },
              rowStyle: {
                fontSize: "1.4rem",
              },
              searchFieldStyle: { fontSize: "1.8rem" },
              maxBodyHeight: "60vh",
              minBodyHeight: "60vh",
            }}
          />
        </div>
        <div
          style={{
            height: "20vh",
            background: "white",
            display: `${mostrarF}`,
            position: "static",
          }}
        >
          <Filtros
            setdatos={setdatos}
            tituloReporte={props.tituloReporte}
          ></Filtros>
        </div>
      </div>

      <div
        style={{
          position: "relativa",
          top: "-500px",
          display: `${mostrarL}`,
        }}
      >
        <Loader></Loader>
      </div>

      <div style={{ display: `${mostrarModal}`, zIndex: "2" }}>
        <div id='myModal' class='modal'>
          <div className='modal-content'>
            <div classname='modal-header'>
              <span className='close' onClick={() => setMostrarModal("none")}>
                &times;
              </span>
              <h2>Importar Asignacion de Estacionamientos</h2>
            </div>
            <div class='modal-body'>
              <div className='contenedor'>
                <div className='contenedorGrupo'>
                  <table>
                    <thead>
                      <tr>
                        <td>Nombre</td>
                        <td>Número de Placa</td>
                        <td>Fecha de Factura</td>
                        <td>Total</td>
                      </tr>
                    </thead>

                    <tbody>
                      {registroMensualPersona.map((result) => (
                        <tr>
                          <td>{result.nombre}</td>
                          <td>{result.Placa}</td>
                          <td>{result.Fecha}</td>
                          <td>{result.total}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <p style={{ fontSize: "24px" }}>
                Se debe la cantidad de{" "}
                <span style={{ color: "red" }}>{cantidadMeses}</span> meses a
                una tarifa de {costo} debiendo un total de{" "}
                <span style={{ color: "red" }}>{cantidadMeses * costo}</span>LPS{" "}
              </p>
            </div>

            <div class='modal-footer'>
              <button
                className=' btn danger form-control'
                style={{ fontSize: "14px", marginLeft: "20px" }}
                onClick={() => setMostrarModal("none")}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Tabla;
