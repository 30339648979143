import React, { useState, useEffect, useRef } from "react";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import Swal from "sweetalert2";

import { QrReader } from "react-qr-reader";

function Reader({ ...props }) {
  const [devices, setDevices] = useState([]);

  const videoPlayerRef = useRef(null);

  const [tipoFuncionario, setTipoFuncionario] = useState("");

  const [tipoEstacionamiento, setEstacionamiento] = useState("");

  const [tipoVehículo, setTipoVehiculo] = useState("");

  const [nombreCompleto, setNomrbeCompleto] = useState("");

  const [númeroIdentidad, setNumeroIdentidad] = useState("");

  const [id_Asignacion, setIdAsignacion] = useState("");
  const [idEstacionamiento, setIdEstacionamiento] = useState("");
  const [modalidad, setModalidad] = useState("");
  const [responsableDePago, setResponsableDePago] = useState("");

  const [institucion, setInstitucion] = useState("");

  const [numeroEstacionamiento, setNumeroEstacionamiento] = useState("");

  const [marca, setMarca] = useState("");

  const [modelo, setModelo] = useState("");

  const [color, setColor] = useState("");

  const [placa, setPlaca] = useState("");

  const [marca2, setMarca2] = useState("");

  const [modelo2, setModelo2] = useState("");

  const [color2, setColor2] = useState("");

  const [placa2, setPlaca2] = useState("");

  const [imagen, setImagen] = useState("");

  const [isScanning, setIsScanning] = useState(true);

  const [eventos, setEventos] = useState([]);

  const [backCameraId, setBackCameraId] = useState(null);

  const [mostrarConsultaPersona, setMostrarConsultaPersona] = useState("none");

  const [mostrarVistaCamara, setMostrarVistaCamara] = useState("flex");

  const [eventoGuardado, setEventoGuardado] = useState(false);

  const [
    informacionTarjetaEstacionamiento,

    setInformacionTarjetaEstacionamiento,
  ] = useState("");

  let _stream = null;

  const getDevices = async function () {
    const devices = await navigator.mediaDevices.enumerateDevices();

    let backCameraId = null;

    devices.forEach((device) => {
      if (
        device.kind === "videoinput" &&
        device.label.toLowerCase().includes("back")
      ) {
        backCameraId = device.deviceId;
      }
    });

    if (!backCameraId && devices.length > 0) {
      backCameraId = devices[1].deviceId; // Si no hay cámara trasera, selecciona la primera cámara disponible

      console.log(devices[1].deviceId);
    }

    setBackCameraId(backCameraId);

    setDevices(devices);
  };

  const requestStream = function () {
    if (_stream) {
      _stream.getTracks().forEach((track) => {
        track.stop();
      });
    }

    const videoSource = backCameraId;

    const constraints = {
      video: {
        deviceId: videoSource ? { exact: videoSource } : undefined,
      },
    };

    return navigator.mediaDevices.getUserMedia(constraints);
  };

  const consultarInformacionTarjeta = async (result) => {
    setEventoGuardado(true);

    setMostrarConsultaPersona("flex");

    setMostrarVistaCamara("none");

    const res = await fetch(
      `https://parkingapi.ccg.hn:4000/asignacionesEstacionamiento/numeroTarjeta/${result}`
    );

    const data = await res.json();

    console.log(data);
    if (data.length > 0) {
      if (!data[0].id_Asignacion_cajon) {
        return Swal.fire({
          title: "TARJETA DE ESTACIONAMIENTO DESACTIVADA",
          width: 500,
          icon: "warning",
        });
      }

      if (data[0].cajonEstacionamiento.estado && props.evento === "Entrada") {
        return Swal.fire({
          title: "lUGAR DE ESTACIONAMIENTO OCUPADO",
          width: 500,
          icon: "info",
        });
      }

      setIdAsignacion(data[0].id_Asignacion_cajon);
      setTipoFuncionario(data[0].tipo_Funcionario);
      setEstacionamiento(data[0].tipo_Estacionamiento);
      setTipoVehiculo(data[0].tipo);
      setNomrbeCompleto(data[0].nombre_Completo);
      setInstitucion(data[0].institucion.nombre_Institucion);
      setModalidad(data[0].modalidad);
      setResponsableDePago(data[0].responsable_Pago);
      setNumeroEstacionamiento(
        `${data[0].cajonEstacionamiento.sotano.nombre_Sotano} - ${data[0].cajonEstacionamiento.estacionamiento}`
      );
      setMarca(data[0].marca);
      setModelo(data[0].modelo);
      setColor(data[0].color);
      setPlaca(data[0].placa);
      setMarca2(data[0].marca2);
      setModelo2(data[0].modelo2);
      setColor2(data[0].color2);
      setPlaca2(data[0].placa2);
      setIdEstacionamiento(data[0].cajonEstacionamiento.sotano.estado);

      setIsScanning(false);

      setInformacionTarjetaEstacionamiento(
        data[0].informacionTarjetaEstacionamiento
      );
      return data[0].id_Asignacion_cajon;
    } else {
      return Swal.fire({
        title: "TARJETA DE ESTACIONAMIENTO NO REGISTRADA",
        width: 500,
        icon: "warning",
      });
    }
  };

  const consultarFlotaVehiculo = async (result) => {
    setEventoGuardado(true);

    setMostrarConsultaPersona("flex");

    setMostrarVistaCamara("none");

    const res = await fetch(
      `https://parkingapi.ccg.hn:4000/consultarFlotaVehiculos?numeroTarjeta=${result}`
    );

    const data = await res.json();
    console.log();
    if (data.length > 0) {
      setInstitucion(data[0].nombre_Institucion);

      setNumeroEstacionamiento(data[0].id_Asignacion_cajon);

      setMarca(data[0].marca);

      setModelo(data[0].modelo);

      setColor(data[0].color);

      setPlaca(data[0].placa);

      setImagen(data[0].imagen);

      setIsScanning(false);

      setInformacionTarjetaEstacionamiento(data[0].id_Flota_Vehiculo);

      return data[0].id_Flota_Vehiculo;
    } else {
      return alert("Número de tarjeta no registrado");
    }
  };

  const consultarEventos = async (result) => {
    const res2 = await fetch(
      `https://parkingapi.ccg.hn:4000/eventoTarjetaEstacionamiento?numeroTarjeta=${result}`
    );

    const data2 = await res2.json();

    setEventos(data2);

    return data2;
  };

  const guardarEvento = async (idAsignacion, consultaE) => {
    if (
      consultaE.length > 0 &&
      consultaE[consultaE.length - 1].tipo_Registro === props.evento
    ) {
      return Swal.fire({
        title: "ERROR",
        width: 500,
        text: `¡El ultimo registro fue del mismo tipo!`,
        icon: "error",
      });
    }

    if (consultaE.length === 0 && props.evento === "Salida") {
      return Swal.fire({
        title: "NO SE PUEDE AGREGAR SALIDA SI NO HAY ENTRADA REGISTRADA",
        width: 500,
        icon: "error",
      });
    }

    const res2 = await fetch(
      `https://parkingapi.ccg.hn:4000/agregarEventoTarjetaEstacionamiento`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id_Asignacion_cajon: idAsignacion,
          ubicacion: props.ubicacion,
          tipoEvento: props.evento,
          id_Usuario: props.idUsuario,
        }),
      }
    );

    await res2.json();

    if (props.evento === "Salida") {
      await fetch(
        `https://parkingapi.ccg.hn:4000/cajonesEstacionamiento/${idEstacionamiento}`,
        {
          method: "put",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            estado: false,
          }),
        }
      );
    }
    await fetch(
      `https://parkingapi.ccg.hn:4000/cajonesEstacionamiento/${idEstacionamiento}`,
      {
        method: "put",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          estado: true,
        }),
      }
    );

    Swal.fire({
      title: "Registro de Eventos",
      width: 500,
      text: `¡Se ha agregado un evento de ${props.evento} en  la Ubicación ${props.ubicacion}!`,
      icon: "success",
    });
  };

  const limpiarFormulario = () => {
    setTipoFuncionario("");
    setEstacionamiento("");

    setTipoVehiculo("");

    setNomrbeCompleto("");

    setNumeroIdentidad("");

    setInstitucion("");

    setNumeroEstacionamiento("");

    setMarca("");

    setModelo("");

    setColor("");

    setPlaca("");

    setMarca2("");

    setModelo2("");

    setColor2("");

    setPlaca2("");

    setImagen("");

    setIsScanning(true);

    setInformacionTarjetaEstacionamiento("");

    setEventos([]);
  };

  useEffect(() => {
    getDevices();
  }, []);

  useEffect(() => {
    let videoPlayer = videoPlayerRef.current;

    if (videoPlayer) {
      requestStream().then(function (stream) {
        _stream = stream;

        videoPlayer.srcObject = stream;
      });
    }
  }, [videoPlayerRef, requestStream]);

  let isProcessing = false;

  return (
    <React.Fragment>
      {/* 

   <select 

    id='video-source' 

    style={{ 

     fontSize: "1.6rem", 

     padding: "2px", 

     width: "350px", 

     borderRadius: "5px", 

     display: `${mostrarSelect}`, 

    }} 

    onChange={() => { 

     requestStream().then(function (stream) { 

      _stream = stream; 

      videoPlayer.srcObject = stream; 

     }); 

    }} 

   > 

    <option value=''>Selecione una opción</option> 

    {devices.map((valor) => 

     valor.kind === "videoinput" ? ( 

      <option value={valor.deviceId}>{valor.label}</option> 

     ) : ( 

      console.log() 

     ) 

    )} 

   </select> 

   */}
      <div
        style={{
          display: `${mostrarVistaCamara}`,

          flexDirection: "column",

          justifyContent: "center",

          background: "#354259",
        }}
      >
        <video
          autoPlay='true'
          ref={videoPlayerRef}
          style={{ width: "350px", height: "350px" }}
        >
          <QrReader
            onResult={async (result, error) => {
              if (!!result && !isProcessing) {
                isProcessing = true;

                if (!eventoGuardado) {
                  const idAsignacion = await consultarInformacionTarjeta(
                    result.text
                  );

                  const consultarEvento = await consultarEventos(idAsignacion);

                  await guardarEvento(idAsignacion, consultarEvento);
                }

                isProcessing = false;
              }

              if (!!error) {
                console.info(error);
              }
            }}
            constraints={{ facingMode: "environment" }}
            style={{ width: "100%" }}
          />
        </video>
      </div>
      <div
        style={{
          display: `${mostrarConsultaPersona}`,

          flexDirection: "column",

          justifyContent: "center",

          width: "auto",
        }}
      >
        <figure>
          <img style={{ height: "150px", width: "150px" }} src={imagen} />
          <figcaption style={{ color: "white", fontSize: "22px" }}>
            {nombreCompleto}
          </figcaption>
          <hr />
        </figure>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "auto",
            marginTop: "30px",
          }}
        >
          <label style={{ color: "white", width: "auto" }}>
            <strong>Modalidad: </strong>
            <span>{modalidad}</span>
          </label>
          <label style={{ color: "white", width: "auto" }}>
            <strong>Responsable De Pago: </strong>
            <span>{responsableDePago}</span>
          </label>
          <label style={{ color: "white", width: "auto" }}>
            <strong>Tipo de Vehículo: </strong>
            {tipoVehículo ? (
              <DirectionsCarIcon fontSize='large' />
            ) : (
              <TwoWheelerIcon fontSize='4rem' />
            )}
          </label>
          <label style={{ color: "white", width: "auto" }}>
            <strong>Institución: </strong> <span>{institucion}</span>
          </label>
          <label style={{ color: "white", width: "auto" }}>
            <strong>Número de Estacionamiento: </strong>
            <span>{numeroEstacionamiento}</span>
          </label>
          {eventos.map((result) => {
            if (result.tipo_Registro === "Entrada") {
              return (
                <p
                  style={{
                    fontSize: "22px",

                    color: "white",

                    backgroundColor: "#D1e7DD",

                    color: "#0A3622",
                  }}
                >
                  {result.hora +
                    " " +
                    result.tipo_Registro +
                    " " +
                    result.ubicacion}
                </p>
              );
            } else {
              return (
                <p
                  style={{
                    fontSize: "22px",

                    color: "white",

                    backgroundColor: "#F8D7DA",

                    color: "#58151C",
                  }}
                >
                  {result.hora +
                    " " +
                    result.tipo_Registro +
                    " " +
                    result.ubicacion}
                </p>
              );
            }
          })}
          <table style={{ marginTop: "20px" }}>
            <thead>
              <tr>
                <td>Marca</td> <td>Modelo</td>
                <td>Color</td> <td>Placa</td>
              </tr>
            </thead>
            <tbody style={{ background: "white" }}>
              <tr>
                <td>{marca}</td>
                <td>{modelo}</td>
                <td>{color}</td>
                <td>{placa}</td>
              </tr>
              <tr>
                <td>{marca2}</td>
                <td>{modelo2}</td>
                <td>{color2}</td>
                <td>{placa2}</td>
              </tr>
            </tbody>
          </table>
          <div>
            <button
              className='botonConsultarTicket'
              onClick={() => {
                setMostrarConsultaPersona("none");

                setMostrarVistaCamara("flex");

                limpiarFormulario();

                setEventoGuardado(false);
              }}
            >
              Nueva consulta
            </button>
          </div>
        </div>
      </div>

      {/* 

 <div 

    style={{ 

     backgroundColor: `${color1}`, 

     display: "flex", 

     flexDirection: "column", 

     alignItems: "center", 

    }} 

   > 

    <label 

     style={{ 

      width: "auto", 

      backgroundColor: `${color1}`, 

      color: `${colorletra1}`, 

     }} 

    > 

     Fecha de Pago:{fechaPago} 

    </label> 

    <label 

     style={{ 

      width: "auto", 

      backgroundColor: `${color1}`, 

      color: `${colorletra1}`, 

     }} 

    > 

     Hora de Pago:{horaPago} 

    </label> 

    <label 

     style={{ 

      width: "auto", 

      backgroundColor: `${color1}`, 

      color: `${colorletra1}`, 

     }} 

    > 

     Nombre Servicio:{nombreS} 

    </label> 

    <label 

     style={{ 

      width: "auto", 

      backgroundColor: `${color1}`, 

      color: `${colorletra1}`, 

     }} 

    > 

     {validacionHora} 

    </label> 

    <div> 

    <button style={{marginTop:"1rem"}} onClick={()=>{setvalidacionHora("");setnombreS("");sethoraPago("");setfechaPago("");setcolor1("#ffffff");setcolorletra1("black");}}>Limpiar Texto</button> 

   </div> 

   </div> 

  */}
    </React.Fragment>
  );
}

export default Reader;
