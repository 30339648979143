import React, { useState, useEffect } from "react";
import "../css/filtro.css";
const url = "https://parkingapi.ccg.hn:4000";

function Filtros({ ...props }) {
  const [mostrarDia, setmostrarDia] = useState("");
  const [mostrarMes, setmostrarMes] = useState("none");
  const [entrefechas, setentrefechas] = useState("none");
  const [mostrarRB, setmostrarRB] = useState("none");
  const [select, setSelect] = useState("Dia");

  const [fecha1, setFecha1] = useState("");
  const [mes, setMes] = useState("");
  const [fecha2, setFecha2] = useState("");
  const [fecha3, setFecha3] = useState("");

  const obtenerDatos2 = async () => {
    const fecha2 = fecha1;
    const TipoR = "Diario";

    props.setdatos([]);

    const res = await fetch(`${url}/consultarReporte1`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fecha2,
        TipoR,
      }),
    });
    const data = await res.json();

    const res2 = await fetch(`${url}/ConsultarRTNReporte1`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fecha2,
        TipoR,
      }),
    });
    const data2 = await res2.json();

    const res3 = await fetch(`${url}/consultarTransferencia`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fecha2,
        TipoR,
      }),
    });

    const data3 = await res3.json();

    const res4 = await fetch(`${url}/datosm2`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fecha2,
        TipoR,
      }),
    });

    const data4 = await res4.json();

    let array = [];
    let rtnconnsultado;
    let nombreConsultado;
    let bancoConsultado;
    let transferenciaConsultado;
    let TipoFuncionarioC;
    let TipoEstacionamientoC;
    let TipoPagoC;
    let NombreC;
    let TipoVehiculoC;
    let MarcaC;
    let ModeloC;
    let PlacaC;
    let TelefonoC;
    let InstituciónC;

    data.map((result) => {
      const find = data2.find(
        (element) =>
          element["id_Ticket"] === result["Código Ticket"] &&
          element["nombre_Servicio"] === result["Nombre de Servicio"]
      );

      const find2 = data3.find(
        (element) => element["id_Factura"] === result["Número de Factura"]
      );

      const find3 = data4.find(
        (element) =>
          element["Número de Factura"] === result["Número de Factura"]
      );

      rtnconnsultado = "";
      nombreConsultado = "";
      bancoConsultado = "";
      transferenciaConsultado = "";
      TipoFuncionarioC = "";
      TipoEstacionamientoC = "";
      TipoPagoC = "";
      NombreC = "";
      TipoVehiculoC = "";
      MarcaC = "";
      ModeloC = "";
      PlacaC = "";
      TelefonoC = "";
      InstituciónC = "";

      if (find != undefined) {
        rtnconnsultado = find["RTN"];
        nombreConsultado = find["nombre"];
      }

      if (find2 != undefined) {
        bancoConsultado = find2["nombreBanco"];
        transferenciaConsultado = find2["numero_Tranferencia"];
      }

      if (find3 != undefined) {
        TipoFuncionarioC = find3["Tipo de Funcionario"];
        TipoEstacionamientoC = find3["Tipo de Estacionamiento"];
        TipoPagoC = find3["Tipo de Pago"];
        NombreC = find3["Nombre"];
        TipoVehiculoC = find3["Tipo de Vehículo"];
        MarcaC = find3["Marca"];
        ModeloC = find3["Modelo"];
        PlacaC = find3["Placa"];
        TelefonoC = find3["Teléfono"];
        InstituciónC = find3["Institución"];
      }

      array.push({
        "Código Ticket": result["Código Ticket"],
        "Nombre de Servicio": result["Nombre de Servicio"],
        "Número de Factura": result["Número de Factura"],
        "Tipo de Cliente": result["Tipo de Cliente"],
        Fecha: result["Fecha"].slice(0, 10),
        "Hora de Entrada": result["Hora de Entrada"],
        "Hora de Salida": result["Hora de Salida"],
        SubTotal: result["SubTotal"],
        ISV: result["ISV"],
        total: result["Total"],
        "Forma de Pago": result["name_TipoPago"],
        Banco: bancoConsultado,
        "N° Tranferencia": transferenciaConsultado,
        Usuario: result["nombre"],
        Estado: result["Estado"],
        RTN: rtnconnsultado,
        NombreRTN: nombreConsultado,
        "Tipo de Funcionario": TipoFuncionarioC,
        "Tipo de Estacionamiento": TipoEstacionamientoC,
        "Tipo de Pago": TipoPagoC,
        Nombre: NombreC,
        "Tipo de Vehículo": TipoVehiculoC,
        Marca: MarcaC,
        Modelo: ModeloC,
        Placa: PlacaC,
        Teléfono: TelefonoC,
        Institución: InstituciónC,
      });
    });
    props.setdatos(
      array.sort((a, b) => a["Número de Factura"] - b["Número de Factura"])
    );
  };

  const obtenerDatos3 = async () => {
    const fecha3 = mes;

    const res = await fetch(`${url}/datosm`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fecha3,
      }),
    });

    const data = await res.json();
    props.setdatos(data);
  };

  const obtenerDatos4 = async () => {
    const fecha4 = fecha1;
    const res = await fetch(`${url}/datosET`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fecha4,
      }),
    });
    const data = await res.json();

    props.setdatos(data);
  };

  const obtenerDatos5 = async () => {
    const numeroMes = mes;
    const res = await fetch(`${url}/reporteFacturaN`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        numeroMes,
      }),
    });
    const data = await res.json();
    props.setdatos(data);
  };

  const obtenerDatos6 = async () => {
    const fecha2 = mes;
    const TipoR = "Mensual";
    props.setdatos([]);
    const res = await fetch(`${url}/consultarReporte1`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fecha2,
        TipoR,
      }),
    });
    const data = await res.json();

    const res2 = await fetch(`${url}/ConsultarRTNReporte1`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fecha2,
        TipoR,
      }),
    });
    const data2 = await res2.json();

    const res3 = await fetch(`${url}/consultarTransferencia`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fecha2,
        TipoR,
      }),
    });

    const data3 = await res3.json();

    const res4 = await fetch(`${url}/datosm2`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fecha2,
        TipoR,
      }),
    });

    const data4 = await res4.json();

    let array = [];
    let rtnconnsultado;
    let nombreConsultado;
    let bancoConsultado;
    let transferenciaConsultado;
    let TipoFuncionarioC;
    let TipoEstacionamientoC;
    let TipoPagoC;
    let NombreC;
    let TipoVehiculoC;
    let MarcaC;
    let ModeloC;
    let PlacaC;
    let TelefonoC;
    let InstituciónC;

    data.map((result) => {
      const find = data2.find(
        (element) =>
          element["id_Ticket"] === result["Código Ticket"] &&
          element["nombre_Servicio"] === result["Nombre de Servicio"]
      );

      const find2 = data3.find(
        (element) => element["id_Factura"] === result["Número de Factura"]
      );

      const find3 = data4.find(
        (element) =>
          element["Número de Factura"] === result["Número de Factura"]
      );

      rtnconnsultado = "";
      nombreConsultado = "";
      bancoConsultado = "";
      transferenciaConsultado = "";
      TipoFuncionarioC = "";
      TipoEstacionamientoC = "";
      TipoPagoC = "";
      NombreC = "";
      TipoVehiculoC = "";
      MarcaC = "";
      ModeloC = "";
      PlacaC = "";
      TelefonoC = "";
      InstituciónC = "";

      if (find != undefined) {
        rtnconnsultado = find["RTN"];
        nombreConsultado = find["nombre"];
      }

      if (find2 != undefined) {
        bancoConsultado = find2["nombreBanco"];
        transferenciaConsultado = find2["numero_Tranferencia"];
      }

      if (find3 != undefined) {
        TipoFuncionarioC = find3["Tipo de Funcionario"];
        TipoEstacionamientoC = find3["Tipo de Estacionamiento"];
        TipoPagoC = find3["Tipo de Pago"];
        NombreC = find3["Nombre"];
        TipoVehiculoC = find3["Tipo de Vehículo"];
        MarcaC = find3["Marca"];
        ModeloC = find3["Modelo"];
        PlacaC = find3["Placa"];
        TelefonoC = find3["Teléfono"];
        InstituciónC = find3["Institución"];
      }

      array.push({
        "Código Ticket": result["Código Ticket"],
        "Nombre de Servicio": result["Nombre de Servicio"],
        "Número de Factura": result["Número de Factura"],
        "Tipo de Cliente": result["Tipo de Cliente"],
        Fecha: result["Fecha"].slice(0, 10),
        "Hora de Entrada": result["Hora de Entrada"],
        "Hora de Salida": result["Hora de Salida"],
        SubTotal: result["SubTotal"],
        ISV: result["ISV"],
        total: result["Total"],
        "Forma de Pago": result["Forma de Pago"],
        Banco: bancoConsultado,
        "N° Tranferencia": transferenciaConsultado,
        Usuario: result["Nombre Usuario"],
        Estado: result["Estado"],
        RTN: rtnconnsultado,
        NombreRTN: nombreConsultado,
        "Tipo de Funcionario": TipoFuncionarioC,
        "Tipo de Estacionamiento": TipoEstacionamientoC,
        "Tipo de Pago": TipoPagoC,
        Nombre: NombreC,
        "Tipo de Vehículo": TipoVehiculoC,
        Marca: MarcaC,
        Modelo: ModeloC,
        Placa: PlacaC,
        Teléfono: TelefonoC,
        Institución: InstituciónC,
      });
    });
    console.log(array);
    props.setdatos(
      array.sort((a, b) => a["Número de Factura"] - b["Número de Factura"])
    );
    alert("terminó");
  };

  const obtenerDatos7 = async () => {
    const texto = select;

    const res = await fetch(`${url}/ReporteF2`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        texto,
        fecha1,
        mes,
        fecha2,
        fecha3,
      }),
    });
    const data = await res.json();

    props.setdatos(data);
  };

  const reporteTicketF = async () => {
    const texto = select;

    const res = await fetch(`${url}/reporteTicketF2`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        texto,
        fecha1,
        mes,
        fecha2,
        fecha3,
      }),
    });
    const data = await res.json();

    var array = [
      {
        "Nombre Servicio": "Parqueo x Hora Vehículo",
        "Cantidad Ticket": data[0].Contador,
        "Facturas Creadas": data[1].Contador,
        "Ticket Gratis": data[2].Contador,
      },
      {
        "Nombre Servicio": "Parqueo x Hora Motocilceta",
        "Cantidad Ticket": data[3].Contador,
        "Facturas Creadas": data[4].Contador,
        "Ticket Gratis": data[5].Contador,
      },
      {
        "Nombre Servicio": "Parqueo x Día Vehículo",
        "Cantidad Ticket": data[6].Contador,
        "Facturas Creadas": data[7].Contador,
        "Ticket Gratis": "No Aplica",
      },
      {
        "Nombre Servicio": "Parqueo x Día Motocicleta",
        "Cantidad Ticket": data[8].Contador,
        "Facturas Creadas": data[9].Contador,
        "Ticket Gratis": "No Aplica",
      },
      {
        "Nombre Servicio": "Parqueo Mensual",
        "Cantidad Ticket": data[10].Contador,
        "Facturas Creadas": data[10].Contador,
        "Ticket Gratis": "No Aplica",
      },
      {
        "Nombre Servicio": "Extravío Ticket V",
        "Cantidad Ticket": data[11].Contador,
        "Facturas Creadas": data[11].Contador,
        "Ticket Gratis": "No Aplica",
      },
      {
        "Nombre Servicio": "Extravío Ticket M",
        "Cantidad Ticket": data[12].Contador,
        "Facturas Creadas": data[12].Contador,
        "Ticket Gratis": "No Aplica",
      },
    ];
    props.setdatos(array);
  };

  const actualizarTabla = () => {
    if (props.tituloReporte === "Reporte Facturacion por Día") {
      fecha1 === "" ? alert('El campo "Día" es obligatorio') : obtenerDatos2();
    } else if (props.tituloReporte === "Reporte Pago Ticket Extraviado") {
      fecha1 === "" ? alert('El campo "Día" es obligatorio') : obtenerDatos4();
    } else if (props.tituloReporte === "Reporte Pago Mensualidad") {
      mes === "" ? alert('El campo "Mes" es obligatorio') : obtenerDatos3();
    } else if (props.tituloReporte === "Reporte de Facturas Nulas") {
      mes === "" ? alert('El campo "Mes" es obligatorio') : obtenerDatos5();
      obtenerDatos5();
    } else if (props.tituloReporte === "Reporte Facturación por Mes") {
      mes === "" ? alert('El campo "Mes" es obligatorio') : obtenerDatos6();
    } else if (props.tituloReporte === "Reporte Creación Ticket's/Facturas") {
      if (select === "Dia") {
        fecha1 === ""
          ? alert('El campo "Día" es obligatorio')
          : reporteTicketF();
      } else if (select === "Mes") {
        mes === "" ? alert('El campo "Mes" es obligatorio') : reporteTicketF();
      } else if (select === "entreFechas") {
        if (fecha2 === "" || fecha3 === "") {
          alert("Ambos campos son obligatorios");
        } else {
          reporteTicketF();
        }
      }
    } else if (props.tituloReporte === "Reporte General facturación") {
      obtenerDatos7();
    }
  };

  const verFiltro = () => {
    if (
      props.tituloReporte === "Reporte Facturacion por Día" ||
      props.tituloReporte === "Reporte Pago Ticket Extraviado"
    ) {
      setmostrarRB("none");
      setmostrarDia("");
      setmostrarMes("none");
      setentrefechas("none");
    } else if (
      props.tituloReporte === "Reporte Pago Mensualidad" ||
      props.tituloReporte === "Reporte de Facturas Nulas" ||
      props.tituloReporte === "Reporte Facturación por Mes"
    ) {
      setmostrarRB("none");
      setmostrarDia("none");
      setmostrarMes("");
      setentrefechas("none");
    } else if (props.tituloReporte === "") {
      setSelect("Dia");
      const f1 = document.getElementById("selectorFecha1");
      const mes = document.getElementById("selectorMes");
      const f2 = document.getElementById("selectorFecha2");
      const f3 = document.getElementById("selectorFecha3");

      f1.value = "";
      mes.value = "";
      f2.value = "";
      f3.value = "";
    } else {
      setmostrarRB("");
    }
  };

  useEffect(() => {
    verFiltro();
  }, [props.tituloReporte]);

  return (
    <React.Fragment>
      <div className='ContenedorFiltro'>
        <div className='contenedorRButtons' style={{ display: `${mostrarRB}` }}>
          <input
            type='radio'
            id='dia'
            name='tipoReporte'
            value='DIA'
            defaultChecked='true'
            onChange={() => {
              setmostrarDia("");
              setmostrarMes("none");
              setentrefechas("none");
              setSelect("Dia");
            }}
          />
          <label for='dia'>Día</label>
          <input
            type='radio'
            id='mes'
            name='tipoReporte'
            value='MES'
            onChange={() => {
              setmostrarDia("none");
              setmostrarMes("");
              setentrefechas("none");
              setSelect("Mes");
            }}
          />
          <label for='mes'>Mes </label>

          <input
            type='radio'
            id='entreFechas'
            name='tipoReporte'
            value='ENTREFECHAS'
            onChange={() => {
              setmostrarDia("none");
              setmostrarMes("none");
              setentrefechas("");
              setSelect("entreFechas");
            }}
          />
          <label for='entreFechas'>Entre Fechas </label>
        </div>

        <div style={{ display: `${mostrarDia}` }}>
          <label>Día </label>
          <input
            type='date'
            id='selectorFecha1'
            onChange={(e) => {
              setFecha1(e.target.value);
            }}
            className='filtroinput'
          />
        </div>

        <div style={{ display: `${mostrarMes}` }}>
          <label>Mes</label>
          <select
            id='selectorMes'
            onChange={(e) => {
              setMes(e.target.value);
            }}
            className='selectfiltro'
          >
            <option value=''>Seleccione una Opción</option>
            <option value='5'>Junio</option>
            <option value='6'>Julio</option>
            <option value='7'>Agosto</option>
            <option value='8'>Septiembre</option>
            <option value='9'>Octubre</option>
            <option value='10'>Noviembre</option>
            <option value='11'>Diciembre</option>
          </select>
        </div>

        <div style={{ display: `${entrefechas}` }}>
          <label>de</label>
          <input
            type='date'
            id='selectorFecha2'
            onChange={(e) => {
              setFecha2(e.target.value);
            }}
            className='filtroinput'
          />
          <label>Hasta</label>
          <input
            type='date'
            id='selectorFecha3'
            onChange={(e) => {
              setFecha3(e.target.value);
            }}
            className='filtroinput'
          />
        </div>

        <div>
          <button onClick={actualizarTabla} className='botonFiltro'>
            Actualizar Consulta
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Filtros;
