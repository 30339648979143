import React, { useState } from "react";
import Qr from "./Qr";
import Servicios from "./Servicios";
import imagen from "../assets/LOGO COMPLETO CCG NEGRO.png";
import imagen2 from "../assets/LOGO-COMPLETO-CCG-rectangulo.svg";
import "../css/menu.css";
import Menu from "./Menu";
const url = "https://parkingapi.ccg.hn:4000";

function Ticket({ ...props }) {
  const Fecha = new Date();

  const [seleccion, setSeleccion] = useState("");
  const [mostrarB, setMostrarB] = useState("");
  const [mostrarQ, setMostrarQ] = useState("none");
  const [contenedorTicket, setContenedorTicket] = useState("");
  const [contenedorMenu, setContenedorMenu] = useState("none");
  const [fechaT, setFechaT] = useState(
    Fecha.getFullYear() +
      "/" +
      (Fecha.getMonth() + parseInt(1)) +
      "/" +
      Fecha.getDate()
  );
  const [fecha2, setFecha2] = useState("");
  const [textoP, setTextoP] = useState("Por Hora");
  const [valor, setValor] = useState("Por Hora");
  const [nCascos, setNCascos] = useState("none");
  const [numeroCascos, setNumeroCascos] = useState("");
  const nombreU = props.nombreI;
  const [nTikcet, setNTicket] = useState("");

  const generar = async () => {
    let h = "";
    let m = "";

    if (Fecha.getHours() < 10) {
      h = "0" + Fecha.getHours();
    } else {
      h = Fecha.getHours();
    }

    if (Fecha.getMinutes() < 10) {
      m = "0" + Fecha.getMinutes();
    } else {
      m = Fecha.getMinutes();
    }

    const HORA = h + ":" + m;
    setFecha2(HORA);
    if (seleccion === "") {
      alert("No se ha selecionado ningún servicio");
    } else if (
      numeroCascos == "" &&
      seleccion === "Parqueo x Hora Motocicleta"
    ) {
      alert("No ha ingresado Número de casco");
    } else {
      const res = await fetch(`${url}/crearTicket`, {
        method: "put",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fechaT,
          seleccion,
          nombreU,
          HORA,
          numeroCascos,
        }),
      });

      const data = await res.json();
      console.log(data[0]);
      if (data.length > 0) {
        setNTicket(data[0].identificador + data[0].id_Ticket);
        setMostrarB("none");
        setMostrarQ("");

        if (seleccion === "Parqueo x Hora Vehículo") {
          setTextoP("Por Hora Vehículo");
          setValor("LPS.25.00");
        } else if (seleccion === "Parqueo x Hora Motocicleta") {
          setTextoP("Por Hora Motocicleta");
          setValor("LPS.10.00");
        }

        setTimeout(() => {
          window.print();
        }, 500);
      } else if (data[0] === "Error") {
        alert("Error al Crear Ticket");
      }
    }
  };

  const recargar = () => {
    setSeleccion("");
    setMostrarB("");
    setMostrarQ("none");
    setFecha2("");
    setTextoP("Por Hora");
    setValor("Por Hora");
    setNTicket("");
    setNCascos("none");
    setNumeroCascos("");
  };

  return (
    <React.Fragment>
      <div
        className='contenedorTicket'
        style={{ display: `${contenedorTicket}` }}
      >
        <section className='contenedorTitulo contenedoresTicket'>
          <h1>Centro Civíco</h1>
          <h1>Gubernamental</h1>
        </section>
        <section className='contenedoresTicket'>
          <img src={imagen} alt='Logo' className='imagen' onClick={recargar} />
        </section>

        <section className='contenedoresTicket'>
          <h2 style={{ marginTop: "1.5rem" }}>Ticket {nTikcet}</h2>
        </section>

        <section style={{ marginTop: "1rem" }} className='contenedoresTicket'>
          <h2>
            {Fecha.getDate() +
              "/" +
              (Fecha.getMonth() + parseInt(1)) +
              "/" +
              Fecha.getFullYear()}
          </h2>
        </section>

        <section className='contenedorServicio contenedoresTicket'>
          <label>Servicio:</label>
          <div
            className='btn-group-vertical sBotones'
            style={{ display: `${mostrarB}` }}
          >
            <Servicios
              setSeleccion={setSeleccion}
              setNCascos={setNCascos}
              nTikcet={nTikcet}
            />
          </div>

          <div style={{ textAlign: "start" }}>
            <label
              className='contenedoresTicket'
              style={{ display: `${mostrarQ}`, fontSize: "2rem" }}
            >
              {seleccion}
            </label>
          </div>

          <div style={{ display: `${mostrarQ}`, textAlign: "start" }}>
            <label className='contenedoresTicket'>Hora Entrada:</label>
          </div>

          <div
            style={{
              display: `${mostrarQ}`,
              fontSize: "2rem",
              textAlign: "start",
            }}
          >
            <label className='contenedoresTicket'>{fecha2}</label>
          </div>

          <div style={{ display: `${nCascos}`, textAlign: "start" }}>
            <label className='contenedoresTicket'>Número de Cascos</label>
          </div>
          <div
            className='contenedoresTicket'
            style={{ display: `${nCascos}`, marginBottom: "1rem" }}
          >
            <input
              type='number'
              className='inputCasco'
              value={numeroCascos}
              style={{ marginBottom: "1rem" }}
              onChange={(e) => {
                setNumeroCascos(e.target.value);
              }}
            />
          </div>
          <div style={{ display: `${mostrarQ}`, height: "200" }}>
            <Qr value={nTikcet} />
          </div>
        </section>

        <section>
          <button
            type='button'
            className='btn btn-primary generar'
            style={{ display: `${mostrarB}` }}
            onClick={generar}
          >
            Generar Ticket
          </button>

          <div>
            <button
              type='button'
              className='btn btn-warning cambiarFactura'
              style={{ display: `${mostrarB}` }}
              onClick={() => {
                setContenedorMenu("");
                setContenedorTicket("none");
              }}
            >
              Menú
            </button>
          </div>
        </section>

        <div style={{ display: `${mostrarQ}`, margin: "1rem" }}>
          <p className='p'>
            <b>No olvide Pagar su boleto en las estaciones de pago</b>
          </p>
        </div>

        <div style={{ display: `${mostrarQ}` }}>
          <p className='p'>
            <b>
              El vehiculo es estacionado por cuenta y riesgo del usuario. No nos
              hacemos responsables por daños o pérdidas causadas al vehiculo o
              por daños causados a terceros. El extravio del comprobante dará
              lugar al pago de 12 horas de estacionamiento y acreditar la
              propiedad del vehiculo.
            </b>
          </p>
        </div>
        <div style={{ display: `${mostrarQ}` }}>
          <p className='p2'>
            <b>
              Tarifa {valor} {textoP}
            </b>
          </p>
        </div>
      </div>

      <div style={{ display: `${contenedorMenu}` }}>
        <Menu nombreI={props.nombreI} rol={props.rol}></Menu>
      </div>
    </React.Fragment>
  );
}

export default Ticket;
