import React from "react";

export default function Boton({ ...props }) {
  return (
    <React.Fragment>
      <button
        className='btn btn-primary botones'
        onClick={() => {
          props.setMostar(props.modulo);
        }}
      >
        {props.name}
      </button>
    </React.Fragment>
  );
}
