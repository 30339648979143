import React, { useState, useEffect, forwardRef, useRef } from "react";
import MaterialTable from "material-table";
import "../css/FormularioCajon.css";
import FormularioCajon from "./FormularioCajon";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Menu from "./Menu";
import * as XLSX from "xlsx";
import FormularioUpdateCajon from "./FormularioupdateCajon";
import ModalImportar from "./ModalImportar";
const url = "https://parkingapi.ccg.hn:4000";

function Cajon({ ...props }) {
  const tableRef = useRef(null);
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  const [mostarformulario, setmostrarformulario] = useState("none");
  const [mostarformularioUpdate, setmostrarformularioUpdate] = useState("none");
  const [mostarImportar, setMostarImportar] = useState("none");
  const [mostrarModuloMenú, setmostrarModuloMenú] = useState("none");
  const [mostrarModuloCajon, setmostrarModuloCajon] = useState("");

  const [recargar, setrecargar] = useState(false);
  const [cajones, setCajones] = useState([]);
  const [consultaCajones, setConsultaCajones] = useState([]);
  const [cajonUpdate, setCajonUpdate] = useState([]);

  useEffect(() => {
    asignacionEstacionamiento();
  }, [recargar]);

  const asignacionEstacionamiento = async () => {
    const res = await fetch(`${url}/asignacionesEstacionamiento`);
    const data = await res.json();
    setConsultaCajones(data);

    const newData = data.map((result) => ({
      ...result,
      tipo_Vehiculo: result.tipo ? "Vehículo" : "Motocicleta",
      estado: result.estado ? "Disponible" : "Ocupado",
      colorE: result.estado ? "green" : "red",
      nombre_Institucion: result.institucion.nombre_Institucion,
      sotano: result.cajonEstacionamiento.sotano.nombre_Sotano,
      estacionamiento: result.cajonEstacionamiento.estacionamiento,
    }));
    setCajones(newData);
  };

  const deleteAsignacion = async (value, tipoAcceso, numeroTarjeta) => {
    if (tipoAcceso === "Boleta") {
      numeroTarjeta = 0;
    }
    const res = await fetch(
      `https://parkingapi.ccg.hn:4000/asignacionesEstacionamiento/${value}/${tipoAcceso}/${numeroTarjeta}`,
      {
        method: "DELETE",
      }
    );

    if (res.status === 202) {
      await res.json();
      alert("Registro Eliminado Correctamente:");
    } else {
      throw new Error(`Error en la solicitud. Estado: ${res.status}`);
    }
  };

  const handleSelectionChange = () => {
    if (tableRef.current) {
      console.log(tableRef.current.dataManager.data);
      const filteredData = tableRef.current.dataManager.filteredData;
      const newData = filteredData.map((item) => {
        const {
          id_institucion,
          id_Institucion,
          id_cajon_Estacionamiento,
          tipo,
          cajonEstacionamiento,
          tableData,
          color,
          institucion,
          ...rest // Otras propiedades que no se deben eliminar
        } = item;

        return rest;
      });

      const worksheet = XLSX.utils.json_to_sheet(newData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workbook, `Reporte Cajones Estacionamiento.xlsx`);
    } else {
      console.error(
        "La instancia de la tabla no se encontró. Asegúrate de que la tabla está configurada correctamente."
      );
    }
  };

  const columns = [
    { title: "No.", field: "id_Asignacion_cajon" },
    { title: "Dependencia", field: "nombre_Institucion" },
    { title: "Nombre Completo", field: "nombre_Completo" },
    { title: "Modalidad", field: "modalidad" },
    { title: "Responsable de Pago", field: "responsable_Pago" },
    { title: "Tipo de Vehículo", field: "tipo_Vehiculo" },
    { title: "Sotano", field: "sotano" },
    { title: "No. Estacionamiento", field: "estacionamiento" },
    { title: "Marca", field: "marca" },
    { title: "Modelo", field: "modelo" },
    { title: "Color", field: "color" },
    { title: "No. Placa", field: "placa" },
    { title: "Marca 2", field: "marca 2" },
    { title: "Modelo 2", field: "modelo 2" },
    { title: "Color 2", field: "color 2" },
    { title: "No. Placa 2", field: "placa 2" },
    { title: "Tipo de Accceso", field: "tipo_Acceso" },
    { title: "Número de Tarjeta", field: "numero_Tarjeta" },
    { title: "observacion", field: "observacion" },
  ];

  return (
    <React.Fragment>
      <div style={{ display: `${mostrarModuloCajon}` }}>
        <MaterialTable
          style={{ zIndex: "1" }}
          id='table-to-xls'
          title={<h1>Administración Cajones de Estacionamiento</h1>}
          icons={tableIcons}
          columns={columns}
          data={cajones}
          tableRef={tableRef}
          localization={{
            pagination: {
              labelDisplayedRows: "{from} - {to} de {count}",
              labelRowsPerPage: "resultados por página",
              firstAriaLabel: "Primera página",
              firstTooltip: "Primera página",
              previousAriaLabel: "página anterior",
              previousTooltip: "página anterior",
              nextAriaLabel: "Siguiente página",
              nextTooltip: "Siguiente página",
              lastAriaLabel: "Última página",
              lastTooltip: "Última página",
              labelRowsSelect: "Filas",
            },
            toolbar: {
              nRowsSelected: "{0} row(s) selected",
            },
            header: {
              actions: "Acción",
            },
            body: {
              emptyDataSourceMessage: "No se encontraron registros",
              filterRow: {
                filterTooltip: "Filter",
              },
            },
          }}
          actions={[
            {
              icon: () => (
                <button className='btn agregarBotonCaj'>Agregar</button>
              ),
              tooltip: "Actualizar",
              onClick: () => {
                setmostrarformulario("");
              },
              isFreeAction: true,
            },
            {
              icon: () => (
                <button className='importarBotonCaj'>Importar</button>
              ),
              tooltip: "Actualizar",
              onClick: () => {
                setmostrarformulario("none");
                setMostarImportar("");
              },
              isFreeAction: true,
            },

            {
              icon: () => <button className='exportBotonCaj'>Exportar</button>,
              tooltip: "Actualizar",
              onClick: () => {
                handleSelectionChange();
              },
              isFreeAction: true,
            },
            {
              icon: () => <button className='botonRegresar'>Regresar</button>,
              tooltip: "Regresar a pantalla anterior",
              onClick: () => {
                setMostarImportar("none");
                setmostrarModuloCajon("none");
                setmostrarModuloMenú("");
              },
              isFreeAction: true,
            },
            {
              icon: () => <button className='exportBotonCaj'>Editar</button>,
              onClick: (event, rowData) => {
                setmostrarformularioUpdate("");
                const registroActualizar = consultaCajones.filter(
                  (result) =>
                    result.id_Asignacion_cajon ===
                    rowData["id_Asignacion_cajon"]
                );
                setCajonUpdate(registroActualizar);
              },
            },
            {
              icon: () => <button className='botonRegresar'>Eliminar</button>,
              onClick: async (event, rowData) => {
                await deleteAsignacion(
                  rowData["id_Asignacion_cajon"],
                  rowData["tipo_Acceso"],
                  rowData["numero_Tarjeta"]
                );
                setrecargar(!recargar);
              },
            },
          ]}
          options={{
            filtering: true,
            pageSize: 10,
            headerStyle: {
              backgroundColor: "#01579b",
              color: "#FFF",
              fontSize: "1.8rem",
              whiteSpace: "nowrap",
            },
            rowStyle: {
              fontSize: "1.4rem",
              whiteSpace: "nowrap",
            },
            searchFieldStyle: { fontSize: "1.8rem" },
            maxBodyHeight: "60vh",
            minBodyHeight: "60vh",
          }}
        />
      </div>
      <div
        style={{ display: `${mostarformulario}`, zIndex: "2" }}
        className='contenedorprueba'
      >
        <FormularioCajon
          setrecargar={setrecargar}
          setmostrarformulario={setmostrarformulario}
          recargar={recargar}
        ></FormularioCajon>
      </div>

      <div
        style={{ display: `${mostarformularioUpdate}`, zIndex: "2" }}
        className='contenedorprueba'
      >
        <FormularioUpdateCajon
          cajonUpdate={cajonUpdate}
          setCajonUpdate={cajonUpdate}
          setmostrarformularioUpdate={setmostrarformularioUpdate}
          setrecargar={setrecargar}
          recargar={recargar}
        ></FormularioUpdateCajon>
      </div>

      <div
        style={{ display: `${mostarImportar}`, zIndex: "2" }}
        className='contenedorprueba'
      >
        <ModalImportar
          setrecargar={setrecargar}
          setMostarImportar={setMostarImportar}
          recargar={recargar}
        ></ModalImportar>
      </div>

      <div style={{ display: `${mostrarModuloMenú}` }}>
        <Menu nombreI={props.nombreI} rol={props.rol}></Menu>
      </div>
    </React.Fragment>
  );
}

export default Cajon;
