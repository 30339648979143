import React, { useState,useEffect } from "react";

function Servicios({ ...props }) {
  const serviciosArray = [
    "Parqueo x Hora Vehículo",
    "Parqueo x Hora Motocicleta",
    "Parqueo x Día Vehículo",
    "Parqueo x Día Motocicleta",
  ];

  const [color, setColor] = useState("#ffffff");
  const [colorL, setColorL] = useState("#6c757d");

  const [color2, setColor2] = useState("#ffffff");
  const [colorL2, setColorL2] = useState("#6c757d");

  const [color3, setColor3] = useState("#ffffff");
  const [colorL3, setColorL3] = useState("#6c757d");

  const [color4, setColor4] = useState("#ffffff");
  const [colorL4, setColorL4] = useState("#6c757d");


useEffect(() => {
  setColor("#ffffff");
  setColorL("#6c757d");

  setColor2("#ffffff");
  setColorL2("#6c757d");

  setColor3("#ffffff");
  setColorL3("#6c757d");

  setColor4("#ffffff");
  setColorL4("#6c757d");
}, [props.nTikcet])

  
  return (
    <React.Fragment>
      <button
        style={{ backgroundColor: `${color}`, color: `${colorL}` }}
        type='button'
        className='btn btn-outline-secondary m-1 botones'
        onClick={() => {
          setColor("#6c757d");
          setColorL("#ffffff");

          setColor2("#ffffff");
          setColorL2("#6c757d");

          setColor3("#ffffff");
          setColorL3("#6c757d");

          setColor4("#ffffff");
          setColorL4("#6c757d");
          props.setSeleccion("Parqueo x Hora Vehículo");
          props.setNCascos("none")
        }}
      >
        Parqueo x Hora Vehículo
      </button>

      <button
        style={{ backgroundColor: `${color2}`, color: `${colorL2}` }}
        type='button'
        className='btn btn-outline-secondary m-1 botones'
        onClick={() => {
          setColor2("#6c757d");
          setColorL2("#ffffff");

          setColor("#ffffff");
          setColorL("#6c757d");

          setColor3("#ffffff");
          setColorL3("#6c757d");

          setColor4("#ffffff");
          setColorL4("#6c757d");
          props.setSeleccion("Parqueo x Hora Motocicleta");
          props.setNCascos("")
        }}
      >
        Parqueo x Hora Motocicleta
      </button>

      
    </React.Fragment>
  );
}

export default Servicios;
