import React from "react";
import "../css/menu.css";
import SeleccionTipoC from "./SeleccionTipoC";

function GuardarPago({...props}) {

  return (
    <React.Fragment>
      <label className='labelDatosFacturacalculo '>Tipo de Cliente:</label>
      <div className='d-grid gap-2 btn-group-vertical ConternedorbotonesTipoCliente'>
        <SeleccionTipoC
          name='Consumidor Final'
          setTipoc={props.setTipoc}
          setcontenedorRTN={props.setcontenedorRTN}
          setRtn={props.setRtn}
          setNombre={props.setNombre}
          setN={props.setN}
          setCheck={props.setCheck}
        ></SeleccionTipoC>

        <SeleccionTipoC
          name='Otro'
          setTipoc={props.setTipoc}
          setcontenedorRTN={props.setcontenedorRTN}
          setRtn={props.setRtn}
          setNombre={props.setNombre}
          setN={props.setN}
          setCheck={props.setCheck}
        ></SeleccionTipoC>

      </div>
    </React.Fragment>
  );
}

export default GuardarPago;
