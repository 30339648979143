import React, { useState } from "react";
import "../css/otro.css";
import Factura from "./Factura";
import FormularioTicketEx from "./FormularioTicketEx";
import Mensualidad from "./Mensualidad";
import TicketExtraviado from "./TicketExtraviado";
import Menu from "./Menu";
import CrearTicket from "./CrearTicket";

function FacturacionOtro({ ...props }) {
  const [activateB1, setActivateB1] = useState("checked");
  const [activateB2, setActivateB2] = useState("");
  const [activateB3, setActivateB3] = useState("");
  const [activateB4, setActivateB4] = useState("");
  const [Color, setColor] = useState("white");
  const [mostrarLista, setMostrarLista] = useState("");
  const [primerContenedor, setPrimerContenedor] = useState("");
  const [segundoContenedor, setSegundoContenedor] = useState("none");
  const [tercerContenedor, setTercerContenedor] = useState("");
  const [cuartoContenedor, setCuartoContenedor] = useState("none");
  const [quintoContenedor, setQuintoContenedor] = useState("none");

  const [color1, setcolor1] = useState("#0d6efd");
  const [color1L, setcolor1l] = useState("white");

  const [color2, setcolor2] = useState("white");
  const [color2L, setcolor2l] = useState("black");

  const [color3, setcolor3] = useState("white");
  const [color3L, setcolor3l] = useState("black");

  const [color4, setcolor4] = useState("white");
  const [color4L, setcolor4l] = useState("black");

  return (
    <React.Fragment>
      <div
        style={{display: `${primerContenedor}` }}
        className='contenedorP'
      >
        <section className='seccion-Botones' style={{display: `${mostrarLista}`}}>
          <button
            className='seccion-Botones-Boton'
            style={{ backgroundColor: `${color1}`, color: `${color1L}` }}
            onClick={() => {
              setcolor1("#0d6efd");
              setcolor1l("white");
              setcolor2("white");
              setcolor2l("black");
              setcolor3("white");
              setcolor3l("black");
              setcolor4("white");
              setcolor4l("black");
              setSegundoContenedor("none");
              setTercerContenedor("");
              setCuartoContenedor("none");
              setCuartoContenedor("none");
              setQuintoContenedor("none");
            }}
          >
            Crear y Facturar
          </button>
          <button
            className='seccion-Botones-Boton'
            style={{ backgroundColor: `${color2}`, color: `${color2L}` }}
            onClick={() => {
              setcolor1("white");
              setcolor1l("black");

              setcolor2("#0d6efd");
              setcolor2l("white");

              setcolor3("white");
              setcolor3l("black");

              setcolor4("white");
              setcolor4l("black");

              setSegundoContenedor("none");
              setTercerContenedor("none");
              setCuartoContenedor("none");
              setQuintoContenedor("");
            }}
          >
            Facturar Ticket Extraviado
          </button>
          <button
            className='seccion-Botones-Boton'
            style={{ backgroundColor: `${color3}`, color: `${color3L}` }}
            onClick={() => {
              setcolor1("white");
              setcolor1l("black");

              setcolor3("#0d6efd");
              setcolor3l("white");

              setcolor2("white");
              setcolor2l("black");

              setcolor4("white");
              setcolor4l("black");

              setSegundoContenedor("none");
              setTercerContenedor("none");
              setCuartoContenedor("");
              setQuintoContenedor("none");
            }}
          >
            Facturar Estacionamiento Mensual
          </button>
          <button className='seccion-Botones-Boton' style={{ backgroundColor: `${color4}`, color: `${color4L}` }}
              onClick={() => {
                setcolor1("white");
                setcolor1l("black");

                setcolor4("#0d6efd");
                setcolor4l("white");

                setcolor3("white");
                setcolor3l("black");

                setcolor2("white");
                setcolor2l("black");

                setPrimerContenedor("none");
                setSegundoContenedor("");
                setTercerContenedor("none");
                setCuartoContenedor("none");
                setQuintoContenedor("none");
              }}>Menú</button>
        </section>

        <div style={{ display: `${tercerContenedor}` }}>
          <CrearTicket
            nombreI={props.nombreI}
            setColor={setColor}
            setMostrarLista={setMostrarLista}
        
          ></CrearTicket>
        </div>

        <div style={{ display: `${quintoContenedor}` }}>
          <TicketExtraviado
            nombreI={props.nombreI}
            setColor={setColor}
            setMostrarLista={setMostrarLista}
          ></TicketExtraviado>
        </div>

        <div style={{ display: `${cuartoContenedor}` }}>
          <Mensualidad
            setColor={setColor}
            setMostrarLista={setMostrarLista}
            nombreI={props.nombreI}
          ></Mensualidad>
        </div>
      </div>

      <div style={{ display: `${segundoContenedor}` }}>
        <Menu nombreI={props.nombreI} rol={props.rol}></Menu>
      </div>
    </React.Fragment>
  );
}

export default FacturacionOtro;
