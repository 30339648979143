import React from 'react'
import QRCode from "react-qr-code";

function qr({...props}) {
  return (
    <React.Fragment>
         
    <div style={{ height: 210, margin: "0 auto", maxWidth: 210, width: "100%" }}>
      <QRCode
        size={500}
        style={{ height: "auto", maxWidth: "100%", width: "100%"}}
        value={props.value}
        viewBox={`0 0 500 500`}
      />
  </div>
        
    </React.Fragment>
  )
}

export default qr