import React, { useState } from "react";
import "../css/TipoPago.css";

function TipoPago({ ...props }) {
  const [numeroT, setnumeroT] = useState("");
  const [texto, settexto] = useState("");



  const changePay = (valor) => {
    if (valor === "Transferencia") {
      props.setmTransferencia("");
      props.setpago(valor);
      props.setcheck3(false)
      props.setcheck2(true)
      props.setcheck1(false)
      settexto("N° Tranferencia")
      
    } else if(valor === "Efectivo") {
      props.settransferencia("");
      props.setpago(valor);
      props.setmTransferencia("none");
      props.setcheck1(true)
      props.setcheck2(false)
      props.setcheck3(false)
      settexto("N° Tranferencia")
    }else{
      props.setpago(valor);
      props.setmTransferencia("");
      props.setcheck1(false)
      props.setcheck2(false)
      props.setcheck3(true)
      settexto("Comprobante Pago")

    }
  };

  return (
    <React.Fragment>
      <div>
        <section>
          <label>Tipo Pago: </label>
          <section>
            <label for='efectivo'>Efectivo</label>
            <input
              type='radio'
              name='tipo_Pago'
              id='efectivo'
              checked={props.check1}
              value='Efectivo'
              className='input-TipoPago'
              onClick={(e) => {
                changePay(e.target.value);
              }}
            />
             <label for='tarjeta'>Tarjeta</label>
            <input
              type='radio'
              name='tipo_Pago'
              id='tarjeta'
              checked={props.check3}
              value='Tarjeta'
              className='input-TipoPago'
              onClick={(e) => {
                changePay(e.target.value);
              }}
            />
          </section>
        </section>

        <section style={{ display: `${props.mTransferencia}` }}  className='select-TipoPago'>
          <label>Banco</label>
          <select
            className='input-TipoPago'
            value={props.banco}
            onChange={(e) => {
              props.setbanco(e.target.value);
            }}
          >
             <option value=''>Seleccione Opción</option>
            <option value='Ficohsa'>Ficohsa</option>
            <option value='Atlántida'>Atlántida</option>
            <option value='BAC'>BAC</option>
            <option value='Occidente'>Occidente</option>
            <option value='Banrural'>Banrural</option>
            <option value='Lafise'>Lafise</option>
            <option value='Banpaís'>Banpaís</option>
            <option value='Banco de los Trabajadores'>Banco de los Trabajadores</option>
            <option value='N/A'>N/A</option>
         
            
          </select>
        </section>

        <div
          className='select-TipoPago'
          style={{ display: `${props.mTransferencia}` }}
        >
          <label>{texto}</label>
          <input
            type='text'
            value={props.transferencia}
            className='input-TipoPago'
            onChange={(e) => {
              setnumeroT(e.target.value);
              props.settransferencia(e.target.value);
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default TipoPago;
