import React from "react";

function Loader() {
  return (
    <React.Fragment>
      <div id='contenedor'>
        <div class='loader' id='loader'>
          Loading...
        </div>
      </div>
    </React.Fragment>
  );
}

export default Loader;
