import React, { useState, useEffect } from "react";
import "../css/otro.css";
import FacturaM from "./FacturaM";
import TipoPago from "./TipoPago";

const url = "https://parkingapi.ccg.hn:4000";

function Mensualidad({ ...props }) {
  const fecha = new Date();
  const [opcion1, setopcion1] = useState("");
  const [opcion2, setopcion2] = useState("");
  const [opcion3, setopcion3] = useState("");
  const [opcion4, setopcion4] = useState("");
  const [opcion5, setopcion5] = useState("");
  const [opcion6, setopcion6] = useState("");
  const [opcion7, setopcion7] = useState("");
  const [opcion8, setopcion8] = useState("");
  const [mostrarDias, setMostrarDias] = useState("none");
  const [numeroDia, setNumeroDia] = useState(0);
  const [color1, setColor1] = useState("");
  const [color2, setColor2] = useState("");
  const [color3, setColor3] = useState("");
  const [color4, setColor4] = useState("");
  const [institucion, setInstitucion] = useState([]);
  const [mostrarRTN, setMostrarRTN] = useState("none");
  const [mostrarContenedorF, setMostrarContenedorF] = useState("");
  const [id_Institucion, setId_Institucion] = useState(0);
  const [mostrarContenedorF2, setMostrarContenedorF2] = useState("none");

  const [disabled, setDisabled] = useState("");
  const [disabledEnviar, setDisabledEnviar] = useState("");
  const [c, setC] = useState("");
  const [checkActivartarjeta, setCheckActivartarjeta] = useState(false);

  const opciones = [
    setopcion1,
    setopcion2,
    setopcion3,
    setopcion4,
    setopcion5,
    setopcion6,
    setopcion7,
    setopcion8,
  ];

  const enviarInfo = async () => {
    const id_Usuario = props.nombreI;

    const Fecha =
      fecha.getFullYear() +
      "/" +
      (fecha.getMonth() + parseInt(1)) +
      "/" +
      fecha.getDate();
    let h = "";
    let m = "";

    if (fecha.getHours() < 10) {
      h = "0" + fecha.getHours();
    } else {
      h = fecha.getHours();
    }

    if (fecha.getMinutes < 10) {
      m = "0" + fecha.getMinutes();
    } else {
      m = fecha.getMinutes();
    }

    const HORA = h + ":" + m;

    const res = await fetch(`${url}/facturaMensual`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id_Usuario,
        funcionario,
        estacionamiento,
        pago2,
        tipoC,
        nombre,
        tipoV,
        marca,
        modelo,
        placa,
        telefono,
        recibido,
        total,
        RTN,
        nombreRTN,
        numeroDia,
        Fecha,
        HORA,
        id_Institucion,
        pago,
        banco,
        transferencia,
      }),
    });

    const data = await res.json();

    if (mostrarActivacion === "") {
      invento(data[0].id_Factura);
    }

    setId_factura(data[0].id_Factura);
    return data;
  };

  const invento = async (id_Factura) => {
    const res = await fetch(
      `https://parkingapi.ccg.hn:4000/prueba/factura/${tarjeta}/${id_Factura}`
    );

    await res.json();
    setTarjeta("");
    setCheckActivartarjeta(false);
  };

  const colores = [setColor1, setColor2, setColor3, setColor4];

  //variables Fomrulario
  const [funcionario, setFuncionario] = useState("");
  const [estacionamiento, setEstacionamiento] = useState("");
  const [pago2, setPago2] = useState("");
  const [tipoC, setTipoC] = useState("");

  const [RTN, setRTN] = useState("");
  const [nombreRTN, setNombreRTN] = useState("");

  const [nombre, setNombre] = useState("");
  const [tipoV, setTipoV] = useState("");
  const [marca, setMarca] = useState("");
  const [modelo, setModelo] = useState("");
  const [placa, setPlaca] = useState("");
  const [telefono, setTelefono] = useState("");
  const [recibido, setRecibido] = useState("");
  const [total, setTotal] = useState(0);
  const [subtotal, setsubtotal] = useState(0);
  const [impuesto, setimpuesto] = useState(0);

  const [id_factura, setId_factura] = useState(0);
  const [nombreInstitución, setnombreInstitución] = useState("");

  const [identidad, setIdentidad] = useState("");
  const [tarjeta, setTarjeta] = useState("");
  const [mostrarActivacion, setmostrarActivacion] = useState("none");

  const values = [
    funcionario,
    estacionamiento,
    pago2,
    tipoC,
    nombre,
    tipoV,
    marca,
    modelo,
    placa,
    telefono,
    recibido,
    total,
    RTN,
    nombreRTN,
    numeroDia,
    id_factura,
    nombreInstitución,
  ];

  const values2 = [
    setFuncionario,
    setEstacionamiento,
    setPago2,
    setTipoC,
    setNombre,
    setTipoV,
    setMarca,
    setModelo,
    setPlaca,
    setTelefono,
    setRecibido,
    setTotal,
    setRTN,
    setNombreRTN,
    setNumeroDia,
    setId_factura,
    setnombreInstitución,
  ];

  const [banco, setbanco] = useState("");
  const [transferencia, settransferencia] = useState("");
  const [mTransferencia, setmTransferencia] = useState("none");
  const [pago, setpago] = useState("Efectivo");
  const [check1, setcheck1] = useState(true);
  const [check2, setcheck2] = useState(false);
  const [check3, setcheck3] = useState(false);

  const instituciones = async () => {
    const res = await fetch(`${url}/instituciones`);
    const data = await res.json();

    if (data.length === 0) {
      alert("No hay Instituciones");
    } else {
      setInstitucion(data);
    }
  };

  useEffect(() => {
    instituciones();
  }, []);

  const caculoPago = (valor) => {
    if (
      valor === "Vehículo" &&
      funcionario === "Autorizado" &&
      pago2 === "Completo"
    ) {
      const costo = 1275;
      setTotal(costo.toFixed(2));
      const impuesto = parseFloat(costo.toFixed(2) / 1.15).toFixed(2);
      setsubtotal(parseFloat(costo.toFixed(2) - impuesto).toFixed(2));
      setimpuesto(impuesto);
    } else if (
      valor === "Vehículo" &&
      funcionario === "No Autorizado" &&
      pago2 === "Completo"
    ) {
      const costo = 1500;
      setTotal(costo.toFixed(2));
      const impuesto = parseFloat(costo.toFixed(2) / 1.15).toFixed(2);
      setsubtotal(parseFloat(costo.toFixed(2) - impuesto).toFixed(2));
      setimpuesto(impuesto);
    } else if (
      valor === "Vehículo" &&
      funcionario === "Autorizado" &&
      pago2 === "Parcial"
    ) {
      const costo = 1275;
      const d = costo.toFixed(2);
      const division = parseFloat(d / parseFloat(30));
      const multiplicacion = division * numeroDia;
      setTotal(multiplicacion.toFixed(2));
      const impuesto = parseFloat(multiplicacion.toFixed(2) / 1.15).toFixed(2);
      setsubtotal(parseFloat(multiplicacion.toFixed(2) - impuesto).toFixed(2));
      setimpuesto(impuesto);
    } else if (
      valor === "Vehículo" &&
      funcionario === "No Autorizado" &&
      pago2 === "Parcial"
    ) {
      const costo = 1500;
      const d = costo.toFixed(2);
      const division = parseFloat(d / parseFloat(30));
      const multiplicacion = division * numeroDia;
      setTotal(multiplicacion.toFixed(2));
      const impuesto = parseFloat(multiplicacion.toFixed(2) / 1.15).toFixed(2);
      setsubtotal(parseFloat(multiplicacion.toFixed(2) - impuesto).toFixed(2));
      setimpuesto(impuesto);
    } else if (
      valor === "Motocicleta" &&
      funcionario === "No Autorizado" &&
      pago2 === "Completo"
    ) {
      const costo = 600;
      setTotal(costo.toFixed(2));
      const impuesto = parseFloat(costo.toFixed(2) / 1.15).toFixed(2);
      setsubtotal(parseFloat(costo.toFixed(2) - impuesto).toFixed(2));
      setimpuesto(impuesto);
    } else if (
      valor === "Motocicleta" &&
      funcionario === "Autorizado" &&
      pago2 === "Completo"
    ) {
      const costo = 600;
      setTotal(costo.toFixed(2));
      const impuesto = parseFloat(costo.toFixed(2) / 1.15).toFixed(2);
      setsubtotal(parseFloat(costo.toFixed(2) - impuesto).toFixed(2));
      setimpuesto(impuesto);
    } else if (
      valor === "Motocicleta" &&
      funcionario === "No Autorizado" &&
      pago2 === "Parcial"
    ) {
      const costo = 600;
      const d = costo.toFixed(2);
      const division = parseFloat(d / parseFloat(30));
      const multiplicacion = division * numeroDia;
      setTotal(multiplicacion.toFixed(2));
      setTotal(multiplicacion.toFixed(2));
      const impuesto = parseFloat(multiplicacion.toFixed(2) / 1.15).toFixed(2);
      setsubtotal(parseFloat(multiplicacion.toFixed(2) - impuesto).toFixed(2));
      setimpuesto(impuesto);
    } else if (
      valor === "Motocicleta" &&
      funcionario === "Autorizado" &&
      pago2 === "Parcial"
    ) {
      const costo = 600;
      const d = costo.toFixed(2);
      const division = parseFloat(d / parseFloat(30));
      const multiplicacion = division * numeroDia;
      setTotal(multiplicacion.toFixed(2));
      setTotal(multiplicacion.toFixed(2));
      const impuesto = parseFloat(multiplicacion.toFixed(2) / 1.15).toFixed(2);
      setsubtotal(parseFloat(multiplicacion.toFixed(2) - impuesto).toFixed(2));
      setimpuesto(impuesto);
    }
  };

  return (
    <React.Fragment>
      <div>
        <div style={{ display: `${mostrarContenedorF}`, height: "91vh" }}>
          <div className='contenedorMensualidadPrincipal'>
            <div
              className='contenedor1'
              style={{ backgroundColor: `${color1}` }}
            >
              <label>Tipo Funcionario:</label>
              <div style={{ width: "40vw" }}>
                <input
                  type='radio'
                  checked={opcion1}
                  onClick={() => {
                    setopcion1("checked");
                    setopcion2("");
                    setColor1("#d1e7dd");
                    setFuncionario("Autorizado");
                    setDisabled("disabled");
                    setopcion4("");
                    setopcion3("checked");
                    setEstacionamiento("Permanente");
                    setTotal(0);
                    setTipoV("");
                    setColor2("#d1e7dd");
                  }}
                />
                <label style={{ width: "" }}>Funcionario Autorizado</label>
              </div>

              <div>
                <input
                  type='radio'
                  checked={opcion2}
                  onClick={() => {
                    setopcion2("checked");
                    setopcion1("");
                    setColor1("#d1e7dd");
                    setFuncionario("No Autorizado");
                    setDisabled("");
                    setTotal(0);
                    setTipoV("");

                    setopcion3("");
                    setopcion4("");
                  }}
                />
                <label>Funcionario No Autorizado</label>
              </div>
            </div>

            <div
              className='contenedor2 '
              style={{ backgroundColor: `${color2}` }}
            >
              <label style={{ width: "auto" }}>Tipo Estacionamiento:</label>
              <div>
                <input
                  type='radio'
                  checked={opcion3}
                  onClick={() => {
                    setopcion3("checked");
                    setopcion4("");
                    setColor2("#d1e7dd");
                    setEstacionamiento("Permanente");
                  }}
                />
                <label>Permanente</label>
              </div>

              <div>
                <input
                  type='radio'
                  checked={opcion4}
                  disabled={disabled}
                  onClick={() => {
                    setopcion3("");
                    setopcion4("checked");
                    setColor2("#d1e7dd");
                    setEstacionamiento("Temporal");
                  }}
                />
                <label>Temporal</label>
              </div>
            </div>
            <div
              className='contenedor3'
              style={{ backgroundColor: `${color3}` }}
            >
              <label>Tipo Pago:</label>
              <div>
                <input
                  type='radio'
                  checked={opcion5}
                  onClick={() => {
                    setopcion5("checked");
                    setopcion6("");
                    setColor3("#d1e7dd");
                    setPago2("Completo");
                  }}
                />
                <label>Completo</label>
              </div>

              <div>
                <input
                  type='radio'
                  checked={opcion6}
                  onClick={() => {
                    setopcion6("checked");
                    setopcion5("");
                    setColor3("#d1e7dd");
                    setMostrarDias("");
                    setPago2("Parcial");
                  }}
                />
                <label>Parcial</label>
              </div>
              <div
                style={{ display: `${mostrarDias}` }}
                className='contenedor-crearticket-grupo2'
              >
                <label className=''>Cantidad de Días</label>
                <input
                  type='number'
                  className='input-crearticket2'
                  value={numeroDia}
                  onChange={(e) => {
                    setNumeroDia(e.target.value);
                    setTipoV("");
                    setTotal(0);
                  }}
                />
              </div>
            </div>
            <div
              className='contenedor4'
              style={{ backgroundColor: `${color4}` }}
            >
              <label>Tipo Cliente:</label>
              <div>
                <input
                  type='radio'
                  checked={opcion7}
                  onClick={() => {
                    setopcion7("checked");
                    setopcion8("");
                    setColor4("#d1e7dd");
                    setTipoC("Consumidor Final");
                    setMostrarRTN("none");
                    setRTN("");
                    setNombreRTN("");
                  }}
                />
                <label>Consumidor Final</label>
              </div>

              <div>
                <input
                  type='radio'
                  checked={opcion8}
                  onClick={() => {
                    setopcion8("checked");
                    setopcion7("");
                    setColor4("#d1e7dd");
                    setMostrarRTN("");
                    setTipoC("Otro");
                    setRTN("");
                    setNombreRTN("");
                  }}
                />
                <label>Otro</label>
              </div>
            </div>
          </div>

          <div
            className='row col-md-8 offset-md-2 justify-content-md-center mt-3'
            style={{ display: `${mostrarRTN}` }}
          >
            <div id='contendoresSpan' className='contenedor-crearticket-grupo'>
              <div className='contenedor-crearticket-grupo1'>
                <div className='contenedor-crearticket-grupo2'>
                  <label>RTN</label>
                  <input
                    type='text'
                    className='input-crearticket2'
                    style={{ width: "30rem", fontSize: "1.8rem" }}
                    value={RTN}
                    onChange={(e) => {
                      setRTN(e.target.value);
                    }}
                  />
                </div>
                <div className='contenedor-crearticket-grupo2'>
                  <label>Nombre</label>
                  <input
                    type='text'
                    className='input-crearticket2'
                    style={{ width: "30rem", fontSize: "1.8rem" }}
                    value={nombreRTN}
                    onChange={(e) => {
                      setNombreRTN(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <div id='contendoresSpan' className='input-group col'></div>
          </div>

          <div className='contenedor-crearticket-grupo'>
            <div id='contendoresSpan' className='contenedor-crearticket-grupo2'>
              <label>Nombre</label>
              <input
                type='text'
                className='input-crearticket'
                style={{ fontSize: "1.8rem" }}
                onChange={(e) => {
                  setNombre(e.target.value);
                }}
                value={nombre}
              />
            </div>

            <div className='contenedor-crearticket-grupo'>
              <div className='contenedor-crearticket-grupo1'>
                <div className='contenedor-crearticket-grupo2'>
                  <label>Tipo Vehiculo</label>
                  <select
                    className='input-crearticket2'
                    style={{ fontSize: "1.8rem" }}
                    value={tipoV}
                    onChange={(e) => {
                      setTipoV(e.target.value);
                      caculoPago(e.target.value);
                    }}
                  >
                    <option value=''>Selecione una Opción</option>
                    <option value='Vehículo'>Vehículo</option>
                    <option value='Motocicleta'>Motocicleta</option>
                  </select>
                </div>

                <div className='contenedor-crearticket-grupo2'>
                  <label>Institución</label>
                  <select
                    className='input-crearticket2'
                    style={{ fontSize: "1.8rem" }}
                    onChange={(e) => {
                      setId_Institucion(e.target.value);

                      let index = e.target.selectedIndex;
                      setnombreInstitución(e.target.options[index].text);
                    }}
                  >
                    <option value=''>Seleccione Una Opción</option>
                    {institucion.map((item) => (
                      <option
                        value={item.id_Institucion}
                        key={item.id_Institucion}
                      >
                        {item.nombre_Institucion}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className='contenedor-crearticket-grupo'>
            <div className='contenedor-crearticket-grupo1'>
              <div
                id='contendoresSpan'
                className='contenedor-crearticket-grupo2'
              >
                <label>Marca</label>
                <input
                  type='text'
                  className='input-crearticket2'
                  style={{ fontSize: "1.8rem" }}
                  value={marca}
                  onChange={(e) => {
                    setMarca(e.target.value);
                  }}
                />
              </div>

              <div
                id='contendoresSpan'
                className='contenedor-crearticket-grupo2'
              >
                <label>Modelo</label>
                <input
                  type='text'
                  className='input-crearticket2'
                  style={{ fontSize: "1.8rem" }}
                  value={modelo}
                  onChange={(e) => {
                    setModelo(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>

          <div className='contenedor-crearticket-grupo'>
            <div className='contenedor-crearticket-grupo1'>
              <div className='contenedor-crearticket-grupo2'>
                <label>Placa</label>
                <input
                  type='text'
                  className='input-crearticket2'
                  style={{ fontSize: "1.8rem", textTransform: "uppercase" }}
                  value={placa}
                  onChange={(e) => {
                    setPlaca(e.target.value.toUpperCase());
                  }}
                />
              </div>

              <div className='contenedor-crearticket-grupo2'>
                <label>Telefono</label>
                <input
                  type='text'
                  className='input-crearticket2'
                  style={{ fontSize: "1.8rem" }}
                  placeholder='0000-0000'
                  value={telefono}
                  onChange={(e) => {
                    setTelefono(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>

          <div className='contenedor-crearticket-grupo'>
            <div className='contenedor-crearticket-grupo1'>
              <div
                id='contendoresSpan'
                className='contenedor-crearticket-grupo2'
              >
                <label>Total</label>
                <input
                  type='text'
                  className='input-crearticket2'
                  style={{ fontSize: "1.8rem" }}
                  disabled='disabled'
                  value={total}
                />
              </div>

              <div
                id='contendoresSpan'
                className='contenedor-crearticket-grupo2'
              >
                <label>Recibido</label>
                <input
                  type='number'
                  className='input-crearticket2'
                  style={{ fontSize: "1.8rem" }}
                  value={recibido}
                  onChange={(e) => {
                    setRecibido(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>

          <div className='contenedor-crearticket-grupo'>
            <div className='contenedor-crearticket-grupo1'>
              <div
                id='contendoresSpan'
                className='contenedor-crearticket-grupo2'
              >
                <label>SubTotal</label>
                <input
                  type='text'
                  className='input-crearticket2'
                  style={{ fontSize: "1.8rem" }}
                  value={impuesto}
                  disabled
                />
              </div>

              <div
                id='contendoresSpan'
                className='contenedor-crearticket-grupo2'
              >
                <label>ISV</label>
                <input
                  type='text'
                  className='input-crearticket2'
                  style={{ fontSize: "1.8rem" }}
                  value={subtotal}
                  disabled
                />
              </div>
            </div>
          </div>

          <div className='contenedor-crearticket-grupo'>
            <div className='contenedor-crearticket-grupo1'>
              <TipoPago
                setpago={setpago}
                banco={banco}
                setbanco={setbanco}
                check1={check1}
                check2={check2}
                setcheck1={setcheck1}
                setcheck2={setcheck2}
                setcheck3={setcheck3}
                settransferencia={settransferencia}
                transferencia={transferencia}
                setmTransferencia={setmTransferencia}
                mTransferencia={mTransferencia}
              />
            </div>
          </div>

          <div className='contenedor-crearticket-grupo'>
            <div>
              <input
                type='checkbox'
                id='activacion'
                name='activacion'
                checked={checkActivartarjeta}
                onClick={() => {
                  if (!checkActivartarjeta) {
                    setmostrarActivacion("");
                  } else {
                    setmostrarActivacion("none");
                  }
                  setCheckActivartarjeta(!checkActivartarjeta);
                  setTarjeta("");
                }}
              />
              <label for='activacion'>Activar Permiso Estacionamiento</label>
            </div>
          </div>

          <div
            className='contenedor-crearticket-grupo'
            style={{ display: `${mostrarActivacion}` }}
          >
            <div className='contenedor-crearticket-grupo1'>
              <div
                id='contendoresSpan'
                className='contenedor-crearticket-grupo2'
              >
                <label>Numero de Contrato</label>
                <input
                  type='text'
                  className='input-crearticket2'
                  style={{ fontSize: "1.8rem" }}
                  value={identidad}
                  onChange={(e) => setIdentidad(e.target.value)}
                />
              </div>

              <div
                id='contendoresSpan'
                className='contenedor-crearticket-grupo2'
              >
                <label>Tarjeta de Acceso</label>
                <input
                  type='text'
                  className='input-crearticket2'
                  style={{ fontSize: "1.8rem" }}
                  value={tarjeta}
                  onChange={(e) => setTarjeta(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className='row offset-md-5 col-md-6'>
            <div className='d-grid gap-2'>
              <button
                className='btn btn-verde'
                style={{ fontSize: "1.8rem", marginBottom: "5rem" }}
                disabled={disabledEnviar}
                type='button'
                onClick={() => {
                  if (
                    funcionario === "" ||
                    estacionamiento === "" ||
                    pago2 === "" ||
                    tipoC === "" ||
                    nombre === "" ||
                    tipoV === "" ||
                    marca === "" ||
                    modelo === "" ||
                    placa === "" ||
                    telefono === "" ||
                    recibido === "" ||
                    total === 0 ||
                    id_Institucion === ""
                  ) {
                    alert("Todos los campos son Obligatorios");
                  } else if (parseFloat(recibido) < parseFloat(total)) {
                    alert("La cantidad Recibida es menor al Total");
                  } else {
                    setDisabledEnviar("disabled");
                    enviarInfo().then((response) => {
                      if (response.length > 0) {
                        setMostrarContenedorF("none");
                        setMostrarContenedorF2("");
                        props.setColor("#FFFFFF");
                        props.setMostrarLista("none");
                        setTimeout(() => {
                          window.print();
                        }, 500);
                      } else if (
                        response[0] === "No se Pudo Consultar Factura"
                      ) {
                        alert("Se creó Factura pero no se pudo Consultar");
                      } else if (response[0] === "No se creo Factura") {
                        alert("No se creó la Factura");
                      }
                      return;
                    });
                  }
                }}
              >
                Crear Factura
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className='contenedorFacturtaM'
        style={{ display: `${mostrarContenedorF2}` }}
      >
        <FacturaM
          values={values}
          values2={values2}
          setDisabledEnviar={setDisabledEnviar}
          setMostrarContenedorF={setMostrarContenedorF}
          setMostrarContenedorF2={setMostrarContenedorF2}
          setMostrarLista={props.setMostrarLista}
          setMostrarDias={setMostrarDias}
          setColor={props.setColor}
          colores={colores}
          opciones={opciones}
          pago2={pago2}
          banco={banco}
          transferencia={transferencia}
          mTransferencia={mTransferencia}
          pago={pago}
          setpago={setpago}
          setbanco={setbanco}
          settransferencia={settransferencia}
          setmTransferencia={setmTransferencia}
          setcheck1={setcheck1}
          setcheck2={setcheck2}
          setcheck3={setcheck3}
        ></FacturaM>
      </div>
    </React.Fragment>
  );
}

export default Mensualidad;
