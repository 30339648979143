import React, { useState } from "react";

function FormularioTicketEx() {
  const [opcion1, setopcion1] = useState("");
  const [opcion2, setopcion2] = useState("");
  const [opcion3, setopcion3] = useState("");
  const [opcion4, setopcion4] = useState("");
  const [opcion5, setopcion5] = useState("");
  const [opcion6, setopcion6] = useState("");
  const [mostrarDias, setMostrarDias] = useState("none");
  const [numeroDia, setNumeroDia] = useState(0);

  return (
    <React.Fragment>
      <div className='ContenedorTicketE'>

        <div className='ContenedorFuncionario'>
          <label>Tipo Funcionario:</label>
          <div>
            <input
              type='radio'
              checked={opcion1}
              onClick={() => {
                setopcion1("checked");
                setopcion2("");
              }}
            />
            <label>Funcionario Autorizado</label>
          </div>

          <div>
            <input
              type='radio'
              checked={opcion2}
              onClick={() => {
                setopcion2("checked");
                setopcion1("");
              }}
            />
            <label>Funcionario No Autorizado</label>
          </div>
        </div>

        <div className='ContenedorFuncionario'>
          <label>Tipo Estacionamiento:</label>
          <div>
            <input
              type='radio'
              checked={opcion3}
              onClick={() => {
                setopcion3("checked");
                setopcion4("");
              }}
            />
            <label>Permanente</label>
          </div>

          <div>
            <input
              type='radio'
              checked={opcion4}
              onClick={() => {
                setopcion3("");
                setopcion4("checked");
              }}
            />

            <label>Temporal</label>
          </div>

          <label style={{ marginTop: "0,5rem" }}>Tipo Pago</label>
          <div className='ContenedorPago'>
            <div>
              <input
                type='radio'
                checked={opcion5}
                onClick={() => {
                  setopcion5("checked");
                  setopcion6("");
                  setMostrarDias("none");
                  setNumeroDia(0);
                }}
              />
              <label>Completo</label>
            </div>

            <div>
              <input
                type='radio'
                checked={opcion6}
                onClick={() => {
                  setopcion5("");
                  setopcion6("checked");
                  setMostrarDias("");
                }}
              />
              <label>Prorrateado</label>
            </div>
            
          </div>
          <div>
            <label>Tipo Cliente:</label>
            <div>
              <input
                type='radio'
                checked={opcion6}
                onClick={() => {
                  setopcion5("");
                  setopcion6("checked");
                  setMostrarDias("");
                }}
              />
              <label>Consumidor Final</label>
            </div>

            <div>
              <input
                type='radio'
                checked={opcion6}
                onClick={() => {
                  setopcion5("");
                  setopcion6("checked");
                  setMostrarDias("");
                }}
              />
              <label>Otro</label>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default FormularioTicketEx;
