import React, { useState } from "react";
import Reader from "./Reader";

function Lectorqr({...props}) {
  
  const f1 = new Date(); 
  
  const [fecha, setfecha] = useState("");
  const [hora, sethora] = useState("");

  

  return (
    <React.Fragment>
      <div style={{display:"flex",flexDirection:"column",alignItems:"center",textAlign:"center",backgroundColor:"#354259", minHeight:"100vh"}}>
        <h3 style={{fontSize:"24px",marginBottom:"10px",marginTop:"10px",color:"white"}}>Validación De Tarjeta De Estacionamiento </h3>
        <Reader ubicacion={props.ubicacion} evento={props.evento} idUsuario={props.idUsuario}></Reader>
      </div>
 
    </React.Fragment>
  );
}

export default Lectorqr;
