import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import * as XLSX from "xlsx";

import "../css/reportes.css";

const url = "https://parkingapi.ccg.hn:4000";

function ReportesB({ ...props }) {
  const [solicitudes1, setSolicitudes1] = useState([]);

  const [solicitudes2, setSolicitudes2] = useState([]);
  const data = [{ foo: "foo" }, { bar: "bar" }];
  const fileName = "Reporte Factura";
  const exportType = "xlsx";

  const downloadExcel = (solicitudes1, nombre, fechaP) => {
    if (fechaP === undefined) {
      fechaP = "";
    }
    const worksheet = XLSX.utils.json_to_sheet(solicitudes1);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, `Reporte ${nombre + " " + fechaP}.xlsx`);
  };

  const obtenerDatos1 = async () => {
    const fecha = props.fecha2;
    const tiporeporte = "Excel";
    const res = await fetch(`${url}/reporte2`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fecha,
        tiporeporte,
      }),
    });
    const data = await res.json();
    return data;
  };

  const obtenerDatos2 = async () => {
    const fecha2 = props.fecha2;
    const TipoR = "Diario";

    const res = await fetch(`${url}/consultarReporte1`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fecha2,
        TipoR,
      }),
    });
    const data = await res.json();

    const res2 = await fetch(`${url}/ConsultarRTNReporte1`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fecha2,
        TipoR,
      }),
    });
    const data2 = await res2.json();

    const res3 = await fetch(`${url}/consultarTransferencia`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fecha2,
        TipoR,
      }),
    });

    const data3 = await res3.json();

    const res4 = await fetch(`${url}/datosm2`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fecha2,
        TipoR,
      }),
    });

    const data4 = await res4.json();

    let array = [];
    let rtnconnsultado;
    let nombreConsultado;
    let bancoConsultado;
    let transferenciaConsultado;
    let TipoFuncionarioC;
    let TipoEstacionamientoC;
    let TipoPagoC;
    let NombreC;
    let TipoVehiculoC;
    let MarcaC;
    let ModeloC;
    let PlacaC;
    let TelefonoC;
    let InstituciónC;

    data.map((result) => {
      const find = data2.find(
        (element) =>
          element["id_Ticket"] === result["Código Ticket"] &&
          element["nombre_Servicio"] === result["Nombre de Servicio"]
      );

      const find2 = data3.find(
        (element) => element["id_Factura"] === result["Número de Factura"]
      );

      const find3 = data4.find(
        (element) =>
          element["Número de Factura"] === result["Número de Factura"]
      );

      rtnconnsultado = "";
      nombreConsultado = "";
      bancoConsultado = "";
      transferenciaConsultado = "";
      TipoFuncionarioC = "";
      TipoEstacionamientoC = "";
      TipoPagoC = "";
      NombreC = "";
      TipoVehiculoC = "";
      MarcaC = "";
      ModeloC = "";
      PlacaC = "";
      TelefonoC = "";
      InstituciónC = "";

      if (find != undefined) {
        rtnconnsultado = find["RTN"];
        nombreConsultado = find["nombre"];
      }

      if (find2 != undefined) {
        bancoConsultado = find2["nombreBanco"];
        transferenciaConsultado = find2["numero_Tranferencia"];
      }

      if (find3 != undefined) {
        TipoFuncionarioC = find3["Tipo de Funcionario"];
        TipoEstacionamientoC = find3["Tipo de Estacionamiento"];
        TipoPagoC = find3["Tipo de Pago"];
        NombreC = find3["Nombre"];
        TipoVehiculoC = find3["Tipo de Vehículo"];
        MarcaC = find3["Marca"];
        ModeloC = find3["Modelo"];
        PlacaC = find3["Placa"];
        TelefonoC = find3["Teléfono"];
        InstituciónC = find3["Institución"];
      }

      array.push({
        "Código Ticket": result["Código Ticket"],
        "Nombre de Servicio": result["Nombre de Servicio"],
        "Número de Factura": result["Número de Factura"],
        "Tipo de Cliente": result["Tipo de Cliente"],
        Fecha: result["Fecha"].slice(0, 10),
        "Hora de Entrada": result["Hora de Entrada"],
        "Hora de Salida": result["Hora de Salida"],
        SubTotal: result["SubTotal"],
        ISV: result["ISV"],
        total: result["Total"],
        "Forma de Pago": result["Forma de Pago"],
        Banco: bancoConsultado,
        "N° Tranferencia": transferenciaConsultado,
        Usuario: result["Nombre Usuario"],
        Estado: result["Estado"],
        RTN: rtnconnsultado,
        NombreRTN: nombreConsultado,
        "Tipo de Funcionario": TipoFuncionarioC,
        "Tipo de Estacionamiento": TipoEstacionamientoC,
        "Tipo de Pago": TipoPagoC,
        Nombre: NombreC,
        "Tipo de Vehículo": TipoVehiculoC,
        Marca: MarcaC,
        Modelo: ModeloC,
        Placa: PlacaC,
        Teléfono: TelefonoC,
        Institución: InstituciónC,
      });
    });
    return array.sort(
      (a, b) => a["Número de Factura"] - b["Número de Factura"]
    );
  };

  const obtenerDatos3 = async () => {
    const fecha3 = props.nmes2;
    const res = await fetch(`${url}/datosm`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fecha3,
      }),
    });
    const data = await res.json();

    return data;
  };

  const obtenerDatos4 = async () => {
    const fecha4 = props.fecha2;
    const res = await fetch(`${url}/datosET`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fecha4,
      }),
    });
    const data = await res.json();

    return data;
  };

  const obtenerDatos5 = async () => {
    const numeroMes = props.nmes2;
    const res = await fetch(`${url}/reporteFacturaN`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        numeroMes,
      }),
    });
    const data = await res.json();

    return data;
  };

  const obtenerDatos6 = async () => {
    const fecha2 = props.nmes2;
    const TipoR = "Mensual";

    const res = await fetch(`${url}/consultarReporte1`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fecha2,
        TipoR,
      }),
    });
    const data = await res.json();

    const res2 = await fetch(`${url}/ConsultarRTNReporte1`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fecha2,
        TipoR,
      }),
    });
    const data2 = await res2.json();

    const res3 = await fetch(`${url}/consultarTransferencia`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fecha2,
        TipoR,
      }),
    });

    const data3 = await res3.json();

    const res4 = await fetch(`${url}/datosm2`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fecha2,
        TipoR,
      }),
    });

    const data4 = await res4.json();

    let array = [];
    let rtnconnsultado;
    let nombreConsultado;
    let bancoConsultado;
    let transferenciaConsultado;
    let TipoFuncionarioC;
    let TipoEstacionamientoC;
    let TipoPagoC;
    let NombreC;
    let TipoVehiculoC;
    let MarcaC;
    let ModeloC;
    let PlacaC;
    let TelefonoC;
    let InstituciónC;

    data.map((result) => {
      const find = data2.find(
        (element) =>
          element["id_Ticket"] === result["Código Ticket"] &&
          element["nombre_Servicio"] === result["Nombre de Servicio"]
      );

      const find2 = data3.find(
        (element) => element["id_Factura"] === result["Número de Factura"]
      );

      const find3 = data4.find(
        (element) =>
          element["Número de Factura"] === result["Número de Factura"]
      );

      rtnconnsultado = "";
      nombreConsultado = "";
      bancoConsultado = "";
      transferenciaConsultado = "";
      TipoFuncionarioC = "";
      TipoEstacionamientoC = "";
      TipoPagoC = "";
      NombreC = "";
      TipoVehiculoC = "";
      MarcaC = "";
      ModeloC = "";
      PlacaC = "";
      TelefonoC = "";
      InstituciónC = "";

      if (find != undefined) {
        rtnconnsultado = find["RTN"];
        nombreConsultado = find["nombre"];
      }

      if (find2 != undefined) {
        bancoConsultado = find2["nombreBanco"];
        transferenciaConsultado = find2["numero_Tranferencia"];
      }

      if (find3 != undefined) {
        TipoFuncionarioC = find3["Tipo de Funcionario"];
        TipoEstacionamientoC = find3["Tipo de Estacionamiento"];
        TipoPagoC = find3["Tipo de Pago"];
        NombreC = find3["Nombre"];
        TipoVehiculoC = find3["Tipo de Vehículo"];
        MarcaC = find3["Marca"];
        ModeloC = find3["Modelo"];
        PlacaC = find3["Placa"];
        TelefonoC = find3["Teléfono"];
        InstituciónC = find3["Institución"];
      }

      array.push({
        "Código Ticket": result["Código Ticket"],
        "Nombre de Servicio": result["Nombre de Servicio"],
        "Número de Factura": result["Número de Factura"],
        "Tipo de Cliente": result["Tipo de Cliente"],
        Fecha: result["Fecha"].slice(0, 10),
        "Hora de Entrada": result["Hora de Entrada"],
        "Hora de Salida": result["Hora de Salida"],
        SubTotal: result["SubTotal"],
        ISV: result["ISV"],
        total: result["Total"],
        "Forma de Pago": result["Forma de Pago"],
        Banco: bancoConsultado,
        "N° Tranferencia": transferenciaConsultado,
        Usuario: result["Nombre Usuario"],
        Estado: result["Estado"],
        RTN: rtnconnsultado,
        NombreRTN: nombreConsultado,
        "Tipo de Funcionario": TipoFuncionarioC,
        "Tipo de Estacionamiento": TipoEstacionamientoC,
        "Tipo de Pago": TipoPagoC,
        Nombre: NombreC,
        "Tipo de Vehículo": TipoVehiculoC,
        Marca: MarcaC,
        Modelo: ModeloC,
        Placa: PlacaC,
        Teléfono: TelefonoC,
        Institución: InstituciónC,
      });
    });
    return array.sort(
      (a, b) => a["Número de Factura"] - b["Número de Factura"]
    );
  };

  const obtenerDatos7 = async () => {
    let fecha2;
    props.nmes2 !== "" ? (fecha2 = props.nmes2) : (fecha2 = props.fecha2);
    const texto = props.valorSelect;

    const res = await fetch(`${url}/reporteTicketF`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        texto,
        fecha2,
      }),
    });
    const data = await res.json();

    var array = [
      {
        "Nombre Servicio": "Parqueo x Hora Vehículo",
        "Cantidad Ticket": data[0].Contador,
        "Facturas Creadas": data[1].Contador,
        "Ticket Gratis": data[2].Contador,
      },
      {
        "Nombre Servicio": "Parqueo x Hora Motocicleta",
        "Cantidad Ticket": data[3].Contador,
        "Facturas Creadas": data[4].Contador,
        "Ticket Gratis": data[5].Contador,
      },
      {
        "Nombre Servicio": "Parqueo x Día Vehículo",
        "Cantidad Ticket": data[6].Contador,
        "Facturas Creadas": data[7].Contador,
        "Ticket Gratis": "No Aplica",
      },
      {
        "Nombre Servicio": "Parqueo x Día Motocicleta",
        "Cantidad Ticket": data[8].Contador,
        "Facturas Creadas": data[9].Contador,
        "Ticket Gratis": "No Aplica",
      },
      {
        "Nombre Servicio": "Parqueo Mensual",
        "Cantidad Ticket": data[10].Contador,
        "Facturas Creadas": data[10].Contador,
        "Ticket Gratis": "No Aplica",
      },
      {
        "Nombre Servicio": "Extravío Ticket V",
        "Cantidad Ticket": data[11].Contador,
        "Facturas Creadas": data[11].Contador,
        "Ticket Gratis": "No Aplica",
      },
      {
        "Nombre Servicio": "Extravío Ticket M",
        "Cantidad Ticket": data[12].Contador,
        "Facturas Creadas": data[12].Contador,
        "Ticket Gratis": "No Aplica",
      },
    ];

    return array;
  };

  const obtenerDatos8 = async () => {
    const res = await fetch(`${url}/ReporteF`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });
    const data = await res.json();

    return data;
  };

  const obtenerDatos9 = async () => {
    const fecha4 = props.fecha2;
    const res = await fetch(`${url}/datosCortesia`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fecha4,
      }),
    });
    const data = await res.json();

    return data;
  };

  useEffect(() => {
    if (props.peticion === true) {
      if (props.tituloReporte === "Reporte Facturacion por Usuario") {
        obtenerDatos1()
          .then((data) =>
            downloadExcel(data, "Facturación por Usuario", props.fecha2)
          )
          .then(() => {
            props.setPeticion(false);
            props.setmostrarLoader("none");
            props.setmostrarF("");
          });
      } else if (props.tituloReporte === "Reporte Facturacion por Día") {
        obtenerDatos2()
          .then((data) =>
            downloadExcel(data, "Facturacion por Día", props.fecha2)
          )
          .then(() => {
            props.setPeticion(false);
            props.setmostrarLoader("none");
            props.setmostrarF("");
          });
      } else if (props.tituloReporte === "Reporte Pago Mensualidad") {
        obtenerDatos3()
          .then((data) =>
            downloadExcel(
              data,
              "Pago Mensualidad",
              parseInt(props.fecha2) + parseInt(1)
            )
          )
          .then(() => {
            props.setPeticion(false);
            props.setmostrarLoader("none");
            props.setmostrarF("");
          });
      } else if (props.tituloReporte === "Reporte Pago Ticket Extraviado") {
        obtenerDatos4()
          .then((data) =>
            downloadExcel(data, "Pago Ticket Extraviado", props.fecha2)
          )
          .then(() => {
            props.setPeticion(false);
            props.setmostrarLoader("none");
            props.setmostrarF("");
          });
      } else if (props.tituloReporte === "Reporte de Facturas Nulas") {
        obtenerDatos5()
          .then((data) => downloadExcel(data, "Facturas Nulas", props.fecha2))
          .then(() => {
            props.setPeticion(false);
            props.setmostrarLoader("none");
            props.setmostrarF("");
          });
      } else if (props.tituloReporte === "Reporte Facturación por Mes") {
        obtenerDatos6()
          .then((data) =>
            downloadExcel(data, "Facturación por Mes", props.fecha2)
          )
          .then(() => {
            props.setPeticion(false);
            props.setmostrarLoader("none");
            props.setmostrarF("");
          });
      } else if (props.tituloReporte === "Reporte Creación Ticket's/Facturas") {
        obtenerDatos7()
          .then((data) => downloadExcel(data, "Creación Ticket's/Facturas", ""))
          .then(() => {
            props.setPeticion(false);
            props.setmostrarLoader("none");
            props.setmostrarF("");
          });
      } else if (props.tituloReporte === "Reporte General facturación") {
        obtenerDatos8()
          .then((data) =>
            downloadExcel(data, "Reporte General facturación", "")
          )
          .then(() => {
            props.setPeticion(false);
            props.setmostrarLoader("none");
            props.setmostrarF("");
          });
      } else if (props.tituloReporte === "Reporte Ticket Cortesia") {
        obtenerDatos9()
          .then((data) => downloadExcel(data, "Reporte Ticket Cortesia", ""))
          .then(() => {
            props.setPeticion(false);
            props.setmostrarLoader("none");
            props.setmostrarF("");
          });
      } else if (props.tituloReporte === "") {
      }
    } else if (props.peticion === false) {
    }
  }, [props.peticion]);

  /*useEffect(() => {
    if (props.peticion2 === true) {
      obtenerDatos2().then((data) =>
        downloadExcel(data, "Facturación por Día", props.fechaR2 + parseInt(1))
      );
      props.setPeticion2(false);
    } else if (props.peticion2 === false) {
    }
  }, [props.peticion2]);

  useEffect(() => {
    if (props.peticion3 === true) {
      obtenerDatos3().then((data) =>
        downloadExcel(data, "Pago Ticket Extraviado", props.fecha4)
      );
      props.setPeticion3(false);
    } else if (props.peticion3 === false) {
    }
  }, [props.peticion3]);

  useEffect(() => {
    if (props.peticion4 === true) {
      obtenerDatos4().then((data) => {
        if (data.length === 0) {
          alert("No se Encontraron Facturas Anuladas en el Mes");
        } else {
          downloadExcel(
            data,
            "Facturas Nulas Mes N°",
            props.nmes + parseInt(1)
          );
        }
      });
      props.setPeticion4(false);
    } else if (props.peticion4 === false) {
    }
  }, [props.peticion4]);

  useEffect(() => {
    if (props.peticion5 === true) {
      obtenerDatos5().then((data) => {
        if (data.length === 0) {
          alert("No se Encontraron Facturas en ese Mes");
        } else {
          downloadExcel(
            data,
            "Reporte Facturación Mes N°",
            props.nmes3 + parseInt(1)
          );
        }
      });
      props.setPeticion5(false);
    } else if (props.peticion5 === false) {
    }
  }, [props.peticion5]);*/

  return <React.Fragment></React.Fragment>;
}

export default ReportesB;
