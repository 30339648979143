import React, { useState } from "react";
import "../css/menu.css";
import Boton from "./Boton";
import Ticket from "./Ticket";
import Facturacion from "./Facturacion";
import Reportes from "./Reporte2";
import FacturacionOtro from "./FacturacionOtro";
import ConsultarFactura from "./ConsultarFactura";
import Lectorqr from "./Lectorqr";
import Cajon from "./Cajon";

function Menu({ ...props }) {
  const [mostrar, setMostar] = useState("");

  if (mostrar === "") {
    if (props.rol === "Facturación") {
      return (
        <React.Fragment>
          <div className='principal'>
            <div className='row secundario'>
              <Boton
                name='Crear Ticket'
                modulo='Ticket'
                setMostar={setMostar}
              />
              <Boton
                name='Facturación Ticket'
                modulo='facturacion'
                setMostar={setMostar}
              />
              <Boton
                name='Servicios Varios'
                modulo='facturacionOtro'
                setMostar={setMostar}
              />
              <Boton
                name='Validar Factura'
                modulo='validarFactura'
                setMostar={setMostar}
              

              />
            </div>
          </div>
        </React.Fragment>
      );
    } else if (props.rol === "Ticket") {
      return (
        <React.Fragment>
          <div className='principal'>
            <div className='row secundario'>
              <Boton
                name='Crear Ticket'
                modulo='Ticket'
                setMostar={setMostar}
              />
            </div>
          </div>
        </React.Fragment>
      );
    } else if (props.rol === "Admin") {
      return (
        <React.Fragment>
          <div className='principal'>
            <div className='row secundario'>
              <Boton name='Reportes' modulo='Reportes' setMostar={setMostar} />
              <Boton
                name='Crear Ticket'
                modulo='Ticket'
                setMostar={setMostar}
              />
              <Boton
                name='Facturación Ticket'
                modulo='facturacion'
                setMostar={setMostar}
              />
              <Boton
                name='Servicios Varios'
                modulo='facturacionOtro'
                setMostar={setMostar}
              />
              <Boton
                name='Consultar Factura'
                modulo='editarRtn'
                setMostar={setMostar}
              />
              <Boton
                name='Validar Factura'
                modulo='validarFactura'
                setMostar={setMostar}
               
              />
              <Boton
                name='Cajón Estacionamiento'
                modulo='cajon'
                setMostar={setMostar}
              />
            </div>
          </div>
        </React.Fragment>
      );
    } else if (props.rol === "Reporteria") {
      return (
        <React.Fragment>
          <div className='principal'>
            <div className='row secundario'>
              <Boton name='Reportes' modulo='Reportes' setMostar={setMostar} />
            </div>
          </div>
        </React.Fragment>
      );
    }
  } else if (mostrar === "Ticket") {
    return <Ticket nombreI={props.nombreI} rol={props.rol}></Ticket>;
  } else if (mostrar === "facturacion") {
    return <Facturacion nombreI={props.nombreI} rol={props.rol}></Facturacion>;
  } else if (mostrar === "Reportes") {
    return <Reportes nombreI={props.nombreI} rol={props.rol}></Reportes>;
  } else if (mostrar === "facturacionOtro") {
    return (
      <FacturacionOtro
        nombreI={props.nombreI}
        rol={props.rol}
      ></FacturacionOtro>
    );
  } else if (mostrar === "editarRtn") {
    return (
      <ConsultarFactura
        nombreI={props.nombreI}
        rol={props.rol}
      ></ConsultarFactura>
    );
  } else if (mostrar === "validarFactura") {
    return <Lectorqr nombreI={props.nombreI} rol={props.rol} ubicacion={props.ubicacion} evento={props.evento} idUsuario={props.nombreI}></Lectorqr>;
  } else if (mostrar === "cajon") {
    return <Cajon nombreI={props.nombreI} rol={props.rol}></Cajon>;
  }
}

export default Menu;
