import React, { useState, useEffect } from "react";

function FormularioupdateCajon({ ...props }) {
  console.log(props.cajonUpdate);

  const [idAsignacion, setIdAsignacion] = useState("");
  const [instituciones, setInstituciones] = useState([]);
  const [sotanos, setSotanos] = useState([]);
  const [estacionamientos, setEstacionamientos] = useState([]);

  const [idInstitucion, setIdInstitucion] = useState(0);
  const [nombreCompleto, setNombreCompleto] = useState("");
  const [identidad, setIdentidad] = useState("");
  const [cargo, setCargo] = useState("");
  const [celular, setCelular] = useState("");
  const [modalidad, setModalidad] = useState("");
  const [responsablePago, setResponsablePago] = useState("");
  const [tipoVehiculo, setTipoVehiculo] = useState(true);
  const [idCajon, setIdCajon] = useState("");
  const [marca, setMarca] = useState("");
  const [modelo, setModelo] = useState("");
  const [color, setColor] = useState("");
  const [placa, setPlaca] = useState("");
  const [marca2, setMarca2] = useState("");
  const [modelo2, setModelo2] = useState("");
  const [color2, setColor2] = useState("");
  const [placa2, setPlaca2] = useState("");
  const [tipoAcceso, setTipoAcceso] = useState("");
  const [numeroTarjeta, setNumeroTarjeta] = useState("");
  const [observacion, setObservacion] = useState("");

  const [cajones, setCajones] = useState([]);

  const [sotano, setSotano] = useState(1);

  const cancelar = () => {
    setIdAsignacion("");
    setIdInstitucion(0);
    setNombreCompleto("");
    setIdentidad("");
    setCargo("");
    setCelular("");
    setModalidad("");
    setResponsablePago("");
    setTipoVehiculo("");
    setIdCajon("");
    setMarca("");
    setModelo("");
    setColor("");
    setPlaca("");
    setMarca2("");
    setModelo2("");
    setColor2("");
    setPlaca2("");
    setTipoAcceso("");
    setNumeroTarjeta("");
    setObservacion("");
    props.setmostrarformularioUpdate("none");
  };

  const actualizarAsignacion = async () => {
    const res = await fetch(
      `https://parkingapi.ccg.hn:4000/asignacionesEstacionamiento/actualizar/${idAsignacion}`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id_Institucion: idInstitucion,
          id_cajon_Estacionamiento: idCajon,
          nombre_Completo: nombreCompleto,
          identidad: identidad,
          cargo: cargo,
          celular: celular,
          modalidad: modalidad,
          responsable_Pago: responsablePago,
          tipo: tipoVehiculo,
          marca: marca,
          modelo: modelo,
          color: color,
          placa: placa,
          marca2: marca2,
          modelo2: modelo2,
          color2: color2,
          placa2: placa2,
          tipo_Acceso: tipoAcceso,
          numero_Tarjeta: numeroTarjeta,
          observacion: observacion,
        }),
      }
    );

    if (res.status === 202) {
      await res.json();
      alert("Registro Actualizado Correctamente:");

      props.setrecargar(!props.recargar);
      cancelar();
      // Realizar acciones adicionales si es necesario
    } else {
      // Manejar errores en caso de que el estado no sea 201
      throw new Error(`Error en la solicitud. Estado: ${res.status}`);
    }
  };

  const obtenerInstituciones = async () => {
    const res = await fetch("https://parkingapi.ccg.hn:4000/instituciones");
    const data = await res.json();
    setInstituciones(data);
  };

  const obtenerSotanos = async () => {
    const res = await fetch("https://parkingapi.ccg.hn:4000/sotanos");
    const data = await res.json();
    setSotanos(data);
  };

  const obtenerEstacionamiento = async () => {
    const res = await fetch(
      `https://parkingapi.ccg.hn:4000/cajonesEstacionamiento/sotano/${sotano}/${tipoVehiculo}`
    );
    const data = await res.json();
    setEstacionamientos(data);
  };

  useEffect(() => {
    if (props.cajonUpdate.length > 0) {
      obtenerInstituciones();
      obtenerSotanos();
      obtenerEstacionamiento();

      setIdAsignacion(props.cajonUpdate[0].id_Asignacion_cajon);

      setIdInstitucion(props.cajonUpdate[0].id_institucion);
      setNombreCompleto(props.cajonUpdate[0].nombre_Completo);
      setIdentidad(props.cajonUpdate[0].identidad);
      setCargo(props.cajonUpdate[0].cargo);
      setCelular(props.cajonUpdate[0].celular);
      setModalidad(props.cajonUpdate[0].modalidad);
      setResponsablePago(props.cajonUpdate[0].responsable_Pago);
      props.cajonUpdate[0].tipo
        ? setTipoVehiculo(true)
        : setTipoVehiculo(false);
      setSotano(props.cajonUpdate[0].cajonEstacionamiento.id_Sotano);
      setIdCajon(props.cajonUpdate[0].id_Cajon_Estacionamiento);
      setMarca(props.cajonUpdate[0].marca);
      setModelo(props.cajonUpdate[0].modelo);
      setColor(props.cajonUpdate[0].color);
      setPlaca(props.cajonUpdate[0].placa);
      setMarca2(props.cajonUpdate[0].marca2);
      setModelo2(props.cajonUpdate[0].modelo2);
      setColor2(props.cajonUpdate[0].color2);
      setPlaca2(props.cajonUpdate[0].placa2);
      setTipoAcceso(props.cajonUpdate[0].tipo_Acceso);
      setNumeroTarjeta(props.cajonUpdate[0].numero_Tarjeta);
      setObservacion(props.cajonUpdate[0].observacion);
    }
  }, [props.cajonUpdate]);

  return (
    <div>
      <div id='myModal' class='modal'>
        <div className='modal-content'>
          <div classname='modal-header'>
            <span class='close' onClick={() => cancelar()}>
              &times;
            </span>
            <h2>Actualizar Cajón de Estacionamiento</h2>
          </div>
          <div class='modal-body'>
            <div className='contenedor'>
              <div className='contenedorGrupo'>
                <label className='elementLabel'>Dependencia</label>
                <select
                  className='elemetInputCaj'
                  value={idInstitucion}
                  onChange={(e) => setIdInstitucion(e.target.value)}
                >
                  <option value={0}>Seleccione una Opción</option>
                  {instituciones.map((result) => (
                    <option value={result.id_institucion}>
                      {result.nombre_Institucion}
                    </option>
                  ))}
                </select>
              </div>

              <div className='contenedorGrupo' style={{ marginLeft: "20px" }}>
                <label className='elementLabel'>Nombre Completo</label>
                <input
                  className='elemetInputCaj'
                  type='text'
                  value={nombreCompleto}
                  onChange={(e) => setNombreCompleto(e.target.value)}
                />
              </div>

              <div className='contenedorGrupo' style={{ marginLeft: "20px" }}>
                <label className='elementLabel'>Número de Identidad</label>
                <input
                  className='elemetInputCaj'
                  value={identidad}
                  onChange={(e) => setIdentidad(e.target.value)}
                  type='text'
                />
              </div>
            </div>

            <div className='contenedor'>
              <div className='contenedorGrupo'>
                <label className='elementLabel'>Cargo</label>
                <input
                  className='elemetInputCaj'
                  type='text'
                  value={cargo}
                  onChange={(e) => setCargo(e.target.value)}
                />
              </div>

              <div className='contenedorGrupo' style={{ marginLeft: "20px" }}>
                <label className='elementLabel'>Celular</label>
                <input
                  className='elemetInputCaj'
                  type='text'
                  placeholder='####-####'
                  value={celular}
                  onChange={(e) => setCelular(e.target.value)}
                />
              </div>

              <div className='contenedorGrupo' style={{ marginLeft: "20px" }}>
                <label className='elementLabel'>Modalidad</label>
                <select
                  className='elemetInputCaj'
                  value={modalidad}
                  onChange={(e) => setModalidad(e.target.value)}
                >
                  <option value=''>Seleccione una Opción</option>
                  <option value='Gratuito'>Gratuito</option>
                  <option value='Permanente'>Permanente</option>
                  <option value='Temporal'>Temporal</option>
                </select>
              </div>
            </div>

            <div className='contenedor'>
              <div className='contenedorGrupo'>
                <label className='elementLabel'>Responsable de Pago</label>
                <select
                  className='elemetInputCaj'
                  value={responsablePago}
                  onChange={(e) => setResponsablePago(e.target.value)}
                >
                  <option value=''>Seleccione una Opción</option>
                  <option value='Institución'>Institución</option>
                  <option value='Usuario'>Usuario</option>
                  <option value='N/A'>N/A</option>
                </select>
              </div>

              <div className='contenedorGrupo' style={{ marginLeft: "20px" }}>
                <label className='elementLabel'>Tipo de Vehículo</label>
                <select
                  className='elemetInputCaj'
                  value={tipoVehiculo}
                  onChange={(e) => setTipoVehiculo(e.target.value)}
                >
                  <option value=''>Seleccione una Opción</option>
                  <option value={true}>Vehículo</option>
                  <option value={false}>Motocicleta</option>
                </select>
              </div>

              <div className='contenedorGrupo' style={{ marginLeft: "20px" }}>
                <label className='elementLabel'>Sotano</label>
                <select
                  className='elemetInputCaj'
                  value={sotano}
                  onChange={(e) => setSotano(e.target.value)}
                >
                  <option>Seleccione una Opción</option>
                  {sotanos.map((result) => (
                    <option value={result.id_Sotano}>
                      {result.nombre_Sotano}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className='contenedor'>
              <div className='contenedorGrupo'>
                <label className='elementLabel'>
                  Número de Estacionamiento
                </label>
                <select
                  className='elemetInputCaj'
                  value={idCajon}
                  onChange={(e) => setIdCajon(e.target.value)}
                >
                  <option value=''>Seleccione una Opción</option>
                  {estacionamientos.map((result) => (
                    <option value={result.id_Cajon_Estacionamiento}>
                      {result.estacionamiento}
                    </option>
                  ))}
                </select>
              </div>

              <div className='contenedorGrupo' style={{ marginLeft: "20px" }}>
                <label className='elementLabel'>Marca</label>
                <input
                  className='elemetInputCaj'
                  value={marca}
                  onChange={(e) => setMarca(e.target.value)}
                />
              </div>
              <div className='contenedorGrupo' style={{ marginLeft: "20px" }}>
                <label className='elementLabel'>Modelo</label>
                <input
                  className='elemetInputCaj'
                  value={modelo}
                  onChange={(e) => setModelo(e.target.value)}
                />
              </div>
            </div>

            <div className='contenedor'>
              <div className='contenedorGrupo'>
                <label className='elementLabel'>Color</label>
                <input
                  className='elemetInputCaj'
                  value={color}
                  onChange={(e) => setColor(e.target.value)}
                />
              </div>

              <div className='contenedorGrupo' style={{ marginLeft: "20px" }}>
                <label className='elementLabel'>Número de Placa</label>
                <input
                  className='elemetInputCaj'
                  value={placa}
                  onChange={(e) => setPlaca(e.target.value)}
                />
              </div>

              <div className='contenedorGrupo' style={{ marginLeft: "20px" }}>
                <label className='elementLabel'>Marca Vehículo 2</label>
                <input
                  className='elemetInputCaj'
                  value={marca2}
                  onChange={(e) => setMarca2(e.target.value)}
                />
              </div>
            </div>

            <div className='contenedor'>
              <div className='contenedorGrupo'>
                <label className='elementLabel'>Modelo Vehículo 2</label>
                <input
                  className='elemetInputCaj'
                  value={modelo2}
                  onChange={(e) => setModelo2(e.target.value)}
                />
              </div>

              <div className='contenedorGrupo' style={{ marginLeft: "20px" }}>
                <label className='elementLabel'>Color Vehículo 2</label>
                <input
                  className='elemetInputCaj'
                  value={color2}
                  onChange={(e) => setColor2(e.target.value)}
                />
              </div>
              <div className='contenedorGrupo' style={{ marginLeft: "20px" }}>
                <label className='elementLabel'>
                  Número de Placa Vehículo 2
                </label>
                <input
                  className='elemetInputCaj'
                  value={placa2}
                  onChange={(e) => setPlaca2(e.target.value)}
                />
              </div>
            </div>

            <div className='contenedor'>
              <div className='contenedorGrupo'>
                <label className='elementLabel'>Acceso</label>
                <select
                  className='elemetInputCaj'
                  value={tipoAcceso}
                  onChange={(e) => setTipoAcceso(e.target.value)}
                >
                  <option value=''>Seleccione una Opción</option>
                  <option value='Tarjeta Inteligente'>
                    Tarjeta Inteligente
                  </option>
                  <option value='Boleta'>Boleta</option>
                </select>
              </div>

              <div className='contenedorGrupo' style={{ marginLeft: "20px" }}>
                <label className='elementLabel'>
                  Número de Tarjeta Inteligente
                </label>
                <input
                  className='elemetInputCaj'
                  value={numeroTarjeta}
                  onChange={(e) => setNumeroTarjeta(e.target.value)}
                />
              </div>

              <div className='contenedorGrupo' style={{ marginLeft: "20px" }}>
                <label className='elementLabel'>Observaciones</label>
                <input
                  className='elemetInputCaj'
                  value={observacion}
                  onChange={(e) => setObservacion(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div class='modal-footer'>
            <button
              className='btn btn-primary form-control'
              style={{ fontSize: "14px" }}
              onClick={() => actualizarAsignacion()}
            >
              Guardar
            </button>
            <button
              className=' btn danger form-control'
              style={{ fontSize: "14px", marginLeft: "20px" }}
              onClick={() => {
                cancelar();
              }}
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormularioupdateCajon;
