import React,{useState} from "react";
import Login from "./components/Login";
import Menu from "./components/Menu";


function App() {
  const [nombreI,setNombreI]=useState("");
  const [rol,setRol]=useState("");
  const [ubicacion, setUbicacion] = useState("Puma");
  const [evento, setEvento] = useState("Entrada");

  if(nombreI===""){
    return <Login setNombreI={setNombreI} setRol={setRol} ubicacion={ubicacion} setUbicacion={setUbicacion} evento={evento} setEvento={setEvento}  />
  }
  return(
    <React.Fragment>
      <Menu nombreI={nombreI} rol={rol} ubicacion={ubicacion} evento={evento}></Menu>
    </React.Fragment>
  )
}

export default App;
