import React from "react";

function SeleccionTipoC({ ...props }) {
  
  const selectCliente = () => {
    if (props.name === "Otro") {
      props.setTipoc(props.name);
      props.setcontenedorRTN("")
      props.setRtn("")
      props.setNombre("")
      props.setN("")
      props.setCheck(false)
    } else if (props.name === "Consumidor Final") {
      props.setTipoc(props.name);
      props.setcontenedorRTN("none")
      props.setRtn("")
      props.setNombre("")
      props.setN("")
      props.setCheck(false)
    }
  };

  return (
    <React.Fragment>
      <button
        style={{ width: "35rem", fontSize: "2rem" }}
        className="btn btn-outline-primary"
        onClick={selectCliente}
      >
        {props.name}
      </button>
    </React.Fragment>
  );
}

export default SeleccionTipoC;
