import React, { useState } from "react";
import "../css/Login.css";
import logoOperadora from "../assets/logoOperadora.png";
import Lectorqr from "./Lectorqr";
const url = "https://parkingapi.ccg.hn:4000";

function Login({ ...props }) {
  const [nombre, setNombre] = useState("");
  const [contrasena, setContrasena] = useState("");

  const [mensaje, setMensaje] = useState("");
  const [mostrarM, setMostrarM] = useState("none");

  const inicio = async () => {
    const res = await fetch(`${url}/consultarCuenta`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        nombre,
        contrasena,
      }),
    });

    const data = await res.json();

    if (data[0] === "Error") {
      setMensaje("No se Realizó la Consulta");
      setMostrarM("");
    } else if (data[0] === "No existe Usuario con ese Nombre") {
      setMensaje("*No hay Cuenta Asociada a ese Nombre");
      setMostrarM("");
    } else if (data[0] === "Credenciales incorrectas") {
      setMensaje("*Nombre de Usuario o Contraseña Incorrectos");
      setMostrarM("");
    } else if (data.length > 0) {
      setMostrarM("none");
      props.setNombreI(data[0].id_Usuario);
      props.setRol(data[0].nombre_Rol);
    }
  };

  return (
    <React.Fragment>
      <div className='contenedorLogin'>
        <div class='login-container'>
          <div class='logo'>
            <img src={logoOperadora} alt='Logo de la empresa' />
          </div>
          <h1 className='h1-login'>Iniciar Sesión</h1>
          <form>
            <div className='label-login form-group'>
              <label className='label-login form-group' for='username'>
                Usuario:
              </label>
              <input
                className='inputs-login'
                type='text'
                id='username'
                name='username'
                placeholder='Ingrese su usuario'
                required
                value={nombre}
                onChange={(e) => {
                  setNombre(e.target.value);
                }}
              />
            </div>
            <div className='label-login form-group'>
              <label className='form-group' for='password'>
                Contraseña:
              </label>
              <input
                className='inputs-login'
                type='password'
                id='password'
                name='password'
                placeholder='Ingrese su contraseña'
                required
                value={contrasena}
                onChange={(e) => {
                  setContrasena(e.target.value);
                }}
              />
            </div>

            <div class='form-group'>
              <label className='label-login form-group' for='ubicacion'>
                Ubicación:
              </label>
              <select
                id='ubicacion'
                name='ubicacion'
                className='inputs-login select-login'
                value={props.ubicacion}
                onChange={(e) => {
                  props.setUbicacion(e.target.value);
                }}
              >
                <option value='Puma'>Puma</option>
                <option value='Águila'>Águila</option>
                <option value='Leopardo'>Leopardo</option>
              </select>
            </div>

            <div class='form-group'>
              <label className='label-login form-group' for='evento'>
                Evento:
              </label>
              <select
                value={props.evento}
                id='evento'
                name='evento'
                className='inputs-login select-login'
                onChange={(e) => {
                  props.setEvento(e.target.value);
                }}
              >
                <option value='Entrada'>Entrada</option>
                <option value='Salida'>Salida</option>
              </select>
            </div>

            <div
              className='alert alert-danger mensaje'
              role='alert'
              style={{ display: `${mostrarM}` }}
            >
              {mensaje}
            </div>
            <button className='button-login' type='button' onClick={inicio}>
              Ingresar
            </button>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Login;
