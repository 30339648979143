import React, { useState, useEffect } from "react";
import FacturaOtros from "./FacturaOtros";
import imagen from "../assets/LOGO COMPLETO CCG NEGRO.png";
import TipoPago from "./TipoPago";
const url = "https://parkingapi.ccg.hn:4000";

function TicketExtraviado({ ...props }) {
  const fecha = new Date();
  const [principal, setPrincipal] = useState("");
  const [mostrarRTN, setMostrarRTN] = useState("none");
  const [mostrarF, setMostrarF] = useState("none");
  const [mostrarMas, setMostrarMas] = useState("");
  const [total, setTotal] = useState(0);
  const [h, setH] = useState("");
  const [tipoV, setTipoV] = useState("");
  const [tipoC, setTipoC] = useState("Consumidor Final");
  const [nombre, setNombre] = useState("");
  const [rtn, setRTN] = useState("");
  const [recibido, setRecibido] = useState(0);
  const [cambio, setCambio] = useState(0);

  const [cai, setCai] = useState("");
  const [rango, setRango] = useState("");
  const [emision, setEmision] = useState("");
  const [nFactura, setNFactura] = useState("");
  const [rtn2, setRtn2] = useState("");
  const [id_Institucion, setId_Institucion] = useState(0);
  const [institucion, setinstitucion] = useState([]);

  const [nombreP, setNombreP] = useState("");

  const [marca, setMarca] = useState("");
  const [modelo, setModelo] = useState("");
  const [placa, setPlaca] = useState("");
  const [telefono, setTelefono] = useState("");
  const [id_factura, setId_factura] = useState(0);

  const [pago, setpago] = useState("Efectivo");
  const [banco, setbanco] = useState("");
  const [transferencia, settransferencia] = useState("");
  const [mTransferencia, setmTransferencia] = useState("none");
  const [check1, setcheck1] = useState(true);
  const [check2, setcheck2] = useState(false);
  const [check3, setcheck3] = useState(false);

  const v = [
    setMostrarF,
    setMostrarRTN,
    setTotal,
    setH,
    setTipoV,
    setTipoC,
    setNombre,
    setRTN,
    setRecibido,
    setCambio,
    setNFactura,
    setPrincipal,
    setTipoV,
  ];

  const instituciones = async () => {
    const res = await fetch(`${url}/instituciones`);
    const data = await res.json();

    if (data.length === 0) {
      alert("No hay Instituciones");
    } else {
      setinstitucion(data);
    }
  };

  useEffect(() => {
    instituciones();
  }, []);

  const cliente = (valor) => {
    if (valor === "Consumidor Final") {
      setMostrarRTN("none");
    } else if (valor === "Otro") {
      setMostrarRTN("");
    }
  };

  const costo = (valor) => {
    if (valor === "Vehículo") {
      const total2 = 300;
      setTotal(total2.toFixed(2));
    } else if (valor === "Motocicleta") {
      const total2 = 120;
      setTotal(total2.toFixed(2));
    }
  };

  const hora = () => {
    let hora2 = "";
    let minutos = "";
    let segundos = "";

    if (fecha.getHours() < 10) {
      hora2 = "0" + fecha.getHours();
    } else {
      hora2 = fecha.getHours();
    }

    if (fecha.getMinutes() < 10) {
      minutos = "0" + fecha.getMinutes();
    } else {
      minutos = fecha.getMinutes();
    }

    if (fecha.getSeconds() < 10) {
      segundos = "0" + fecha.getSeconds();
    } else {
      segundos = fecha.getSeconds();
    }
    setH(hora2 + ":" + minutos + ":" + segundos);
  };

  const enviarDatos = async () => {
    const fecha2 =
      fecha.getFullYear() +
      "/" +
      (fecha.getMonth() + parseInt(1)) +
      "/" +
      fecha.getDate();
    const id_Usuario = props.nombreI;
    const res = await fetch(`${url}/ticketExtraviado`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id_Usuario,
        fecha2,
        h,
        tipoV,
        tipoC,
        rtn,
        nombre,
        total,
        recibido,
        cambio,
        marca,
        modelo,
        placa,
        telefono,
        id_Institucion,
        nombreP,
        pago,
        banco,
        transferencia,
      }),
    });

    const data = await res.json();
    console.log(data);
    setCai(data[0].cai);
    setRango(data[0].RangoInicial);
    setEmision(data[0].fechaEmision);
    setNFactura(data[0].id_Factura);
    setRtn2(data[0].RTN);
  };

  return (
    <React.Fragment>
      <div
        className='contenedor-crearticket'
        style={{ display: `${principal}` }}
      >
        <div className='contenedor-crearticket-grupo'>
          <div className='contenedor-crearticket-grupo2'>
            <label>Tipo Vehiculo</label>

            <select
              style={{ fontSize: "1.8rem" }}
              value={tipoV}
              className='input-crearticket'
              onChange={(e) => {
                costo(e.target.value);
                hora();
                setTipoV(e.target.value);
                setNombre("");
                setRTN("");
              }}
            >
              <option value=''>Seleccione una Opción</option>
              <option value='Vehículo'>Vehículo</option>
              <option value='Motocicleta'>Motocicleta</option>
            </select>
          </div>
        </div>

        <div className='contenedor-crearticket-grupo'>
          <div className='contenedor-crearticket-grupo1'>
            <div className='contenedor-crearticket-grupo2'>
              <label>Hora</label>
              <input
                type='text'
                className='input-crearticket2'
                style={{ fontSize: "1.8rem" }}
                disabled='disabled'
                value={h}
              />
            </div>
            <div>
              <div className='contenedor-crearticket-grupo2'>
                <label>Total</label>
                <input
                  disabled='disabled'
                  type='text'
                  className='input-crearticket2'
                  style={{ fontSize: "1.8rem" }}
                  value={total}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='contenedor-crearticket-grupo'>
          <div className='contenedor-crearticket-grupo1'>
            <div className='contenedor-crearticket-grupo2'>
              <label>Recibido</label>
              <input
                type='text'
                className='input-crearticket2'
                style={{ fontSize: "1.8rem" }}
                value={recibido}
                onChange={(e) => {
                  setRecibido(e.target.value);
                }}
              />
            </div>
            <div>
              <div className='contenedor-crearticket-grupo2'>
                <label>Cambio</label>
                <input
                  disabled='disabled'
                  type='text'
                  className='input-crearticket2'
                  style={{ fontSize: "1.8rem" }}
                  value={parseFloat(recibido - total).toFixed(2)}
                  onChange={(e) => {
                    setCambio(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='contenedor-crearticket-grupo'>
          <div className='contenedor-crearticket-grupo1'>
            <div className='contenedor-crearticket-grupo2'>
              <label>Nombre</label>
              <input
                type='text'
                className='input-crearticket2'
                style={{ fontSize: "1.8rem" }}
                value={nombreP}
                onChange={(e) => {
                  setNombreP(e.target.value);
                }}
              />
            </div>
            <div>
              <div className='contenedor-crearticket-grupo2'>
                <label>Institución</label>
                <select
                  className='input-crearticket2'
                  style={{ fontSize: "1.8rem" }}
                  onChange={(e) => {
                    setId_Institucion(e.target.value);
                  }}
                >
                  <option value=''>Seleccione Una Opción</option>
                  {institucion.map((item) => (
                    <option
                      value={item.id_Institucion}
                      key={item.id_Institucion}
                    >
                      {item.nombre_Institucion}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className='contenedor-crearticket-grupo'>
          <div className='contenedor-crearticket-grupo1'>
            <div className='contenedor-crearticket-grupo2'>
              <label>Marca</label>
              <input
                type='text'
                className='input-crearticket2'
                style={{ fontSize: "1.8rem" }}
                value={marca}
                onChange={(e) => {
                  setMarca(e.target.value);
                }}
              />
            </div>
            <div>
              <div className='contenedor-crearticket-grupo2'>
                <label>Modelo</label>
                <input
                  type='text'
                  className='input-crearticket2'
                  style={{ fontSize: "1.8rem" }}
                  value={modelo}
                  onChange={(e) => {
                    setModelo(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='contenedor-crearticket-grupo'>
          <div className='contenedor-crearticket-grupo1'>
            <div className='contenedor-crearticket-grupo2'>
              <label>Placa</label>
              <input
                type='text'
                className='input-crearticket2'
                style={{ fontSize: "1.8rem" }}
                value={placa}
                onChange={(e) => {
                  setPlaca(e.target.value);
                }}
              />
            </div>
            <div>
              <div className='contenedor-crearticket-grupo2'>
                <label>Teléfono</label>
                <input
                  type='text'
                  className='input-crearticket2'
                  placeholder='0000-0000'
                  style={{ fontSize: "1.8rem" }}
                  value={telefono}
                  onChange={(e) => {
                    setTelefono(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='contenedor-crearticket-grupo'>
          <div className='contenedor-crearticket-grupo1'>
            <TipoPago
              setpago={setpago}
              setbanco={setbanco}
              banco={banco}
              check1={check1}
              check2={check2}
              check3={check3}
              setcheck1={setcheck1}
              setcheck2={setcheck2}
              setcheck3={setcheck3}
              settransferencia={settransferencia}
              transferencia={transferencia}
              setmTransferencia={setmTransferencia}
              mTransferencia={mTransferencia}
            />
          </div>
        </div>

        <div className='contenedor-crearticket-grupo'>
          <div className='contenedor-crearticket-grupo2'>
            <span className='text-span' style={{ fontSize: "2rem" }}>
              Tipo Cliente
            </span>
            <select
              style={{ fontSize: "1.8rem" }}
              className='input-crearticket'
              onChange={(e) => {
                cliente(e.target.value);
                setTipoC(e.target.value);
              }}
              value={tipoC}
            >
              <option value=''>Seleccione una Opción</option>
              <option value='Consumidor Final'>Consumidor Final</option>
              <option value='Otro'>Otro</option>
            </select>
          </div>
        </div>

        <div
          className='contenedor-crearticket-grupo'
          style={{ display: `${mostrarRTN}` }}
        >
          <div className='contenedor-crearticket-grupo1'>
            <div className='contenedor-crearticket-grupo2'>
              <label>RTN</label>
              <input
                type='text'
                className='input-crearticket2'
                style={{ fontSize: "1.8rem" }}
                onChange={(e) => {
                  setRTN(e.target.value);
                }}
              />
            </div>
            <div>
              <div className='contenedor-crearticket-grupo2'>
                <label>Nombre</label>
                <input
                  type='text'
                  className='input-crearticket2'
                  style={{ fontSize: "1.8rem" }}
                  onChange={(e) => {
                    setNombre(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>

          <div className=' col-md-3'></div>
        </div>
        <div className='row'>
          <div className='offset-md-4 col-md-6'>
            <button
              class='btn btn-verde form-control m-3'
              type='button'
              style={{ fontSize: "1.8rem" }}
              onClick={() => {
                if (
                  tipoV === "" ||
                  h === "" ||
                  total === 0 ||
                  recibido === 0 ||
                  nombreP === "" ||
                  marca === "" ||
                  modelo === "" ||
                  institucion === "" ||
                  placa === "" ||
                  telefono === ""
                ) {
                  alert("Todos los Campos son Obligatorios");
                } else if (parseFloat(recibido) < parseFloat(total)) {
                  alert("Recibido es Menor a Total");
                } else {
                  enviarDatos().then((response) => {
                    setMostrarF("");
                    setPrincipal("none");
                    props.setColor("");
                    props.setMostrarLista("none");
                    setTimeout(() => {
                      window.print();
                    }, 500);
                    return;
                  });
                }
              }}
            >
              Crear Factura
            </button>
          </div>
        </div>
      </div>

      <div style={{ display: `${mostrarF}` }}>
        <FacturaOtros
          cai={cai}
          rango={rango}
          Emision={emision}
          nFactura={nFactura}
          rtn2={rtn2}
          tipoC={tipoC}
          tipoV={tipoV}
          rtn={rtn}
          nombre={nombre}
          total={total}
          recibido={recibido}
          h={h}
          v={v}
          pago={pago}
          banco={banco}
          transferencia={transferencia}
          mTransferencia={mTransferencia}
          setMostrarLista={props.setMostrarLista}
          setColor={props.setColor}
          mostrarMas={mostrarMas}
          nombreP={nombreP}
          marca={marca}
          modelo={modelo}
          placa={placa}
          telefono={telefono}
          setNombreP={setNombreP}
          setMarca={setMarca}
          setModelo={setModelo}
          setPlaca={setPlaca}
          setTelefono={setTelefono}
          setpago={setpago}
          setbanco={setbanco}
          settransferencia={settransferencia}
          setmTransferencia={setmTransferencia}
          setcheck1={setcheck1}
          setcheck2={setcheck2}
          setcheck3={setcheck3}
        ></FacturaOtros>
      </div>
    </React.Fragment>
  );
}

export default TicketExtraviado;
