import React from "react";

function SeccionReporte({ ...props }) {
  return (
    <React.Fragment>
      <section className='seccionReporte'>
        <div className="contenedor1-seccionReporte">
        <p style={{fontSize:"30px",textAlign:"center",marginBottom:"2rem", color:"#cfe2ff"}}>{props.titulo}</p>
        <img src={props.icono} alt="icono" className="icono" />
        <p style={{marginTop:"2rem",fontSize:"1.6rem",textAlign:"start",color:"#cfe2ff",marginTop:"10px",width:"30rem"}}>Descripción: </p>
        <p style={{fontSize:"1.6rem",textAlign:"justify",color:"#cfe2ff",width:"30rem"}}>{props.descripcion}</p>
        </div>
        <div className="contenedor2-seccionReporte">
        <button className='btnReporte2' onClick={(e)=>{props.setmostrarTabla(""); props.settituloReporte(props.titulo);props.setmostrarBotonMenu("none");props.setmostrarUltimaFactura("none")}}>Consultar</button>
     <button className="btnReporte2" onClick={()=>{props.setmostrarModal("");props.setFilter("blur(1px)");props.settituloReporte(props.titulo);props.setmostrarBotonMenu("none");props.setmostrarUltimaFactura("none")}}>Generar Reporte</button>
        </div>
      </section>
    </React.Fragment>
  );
}

export default SeccionReporte;
