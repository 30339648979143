import React, { useState } from "react";
import "../css/consultarFactura.css";
import ConsultaF from "./ConsultaF";
import Menu from "./Menu";

const url = "https://parkingapi.ccg.hn:4000";

function ConsultarFactura({ ...props }) {
  const [mostrarB, setmostrarB] = useState("");
  const [mostrarF, setmostrarF] = useState("none");
  const [id_Factura, setId_Factura] = useState("none");
  const [datos, setDatos] = useState([]);

  //contenedores de Factura,Mostrar y ocultar
  const [ocultar, setOcultar] = useState("none");
  const [mostrarNcasco, setMostrarNcasco] = useState("none");
  const [horaEntrada, setHoraEntrada] = useState("");
  const [servicioMensual, setservicioMensual] = useState("none");

  const [mostrarMenu, setmostrarMenu] = useState("none");

  const [nula, setNula] = useState("none");

  const [id_Ticket, setId_Ticket] = useState("");
  const [nombre_Servicio, setNombre_Servicio] = useState("");
  const [id_Factura2, setId_Factura2] = useState("");
  const [tipo_Cliente, setTipo_Cliente] = useState("");
  const [Fecha, setFecha] = useState("");
  const [hora_Entrada, setHora_Entrada] = useState("");
  const [hora_Salida, setHora_Salida] = useState("");
  const [subtotal, setSubtotal] = useState("");
  const [ISV, setISV] = useState("");
  const [total, setTotal] = useState("");
  const [recibido, setRecibido] = useState("");
  const [cambio, setCambio] = useState("");
  const [ncasco, setncasco] = useState("");
  const [RTN, setRTN] = useState("");
  const [nombre, setNombre] = useState("");
  const [estado, setEstado] = useState("");

  const [cantoidadDias, setcantoidadDias] = useState("");
  const [TipoV, setTipoV] = useState("");
  const [tipoP, settipoP] = useState("");
  const [nombrep, setnombrep] = useState("");
  const [marca, setmarca] = useState("");
  const [modelo, setmodelo] = useState("");
  const [placa, setplaca] = useState("");
  const [telefono, settelefono] = useState("");

  const values = [
    cantoidadDias,
    TipoV,
    tipoP,
    nombrep,
    marca,
    modelo,
    placa,
    telefono,
  ];
  const enviarDatos = async () => {
    const res = await fetch(`${url}/verFactura`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id_Factura,
      }),
    });

    const data = await res.json();
    console.log(data);
    if (data.length === 0) {
      alert("No se Encontró Factura");
    } else {
      setId_Ticket(data[0].id_Ticket);
      const t = data[0].id_Ticket;
      setNombre_Servicio(data[0].nombre_Servicio);

      if (
        data[0].nombre_Servicio === "Parqueo x Hora Motocicleta" ||
        data[0].nombre_Servicio === "Parqueo x Día Motocicleta"
      ) {
        setMostrarNcasco("");
      } else {
        setMostrarNcasco("none");
      }

      setTipo_Cliente(data[0].tipo_Cliente);

      if (data[0].tipo_Cliente === "Otro") {
        setOcultar("");
      } else if (data[0].tipo_Cliente === "Consumidor Final") {
        setOcultar("none");
      }

      if (data[0].nombre_Servicio === "Parqueo Ticket Vehículo") {
        setHoraEntrada("none");
        setNombre_Servicio("Extravío Ticket Vehículo");
      }
      if (data[0].nombre_Servicio === "Parqueo Ticket Motocicleta") {
        setHoraEntrada("none");
        setNombre_Servicio("Extravío Ticket Motocicleta");
      } else if (data[0].nombre_Servicio === "Parqueo Mensual") {
        const rest2 = await fetch(`${url}/ParqueoMensual`, {
          method: "put",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            t,
          }),
        });

        const data2 = await rest2.json();

        if (data2.length > 0) {
          setservicioMensual("");
          setTipoV(data2[0].Tipo_Vehiculo);
          setnombrep(data2[0].nombre);
          setmarca(data2[0].marca);
          setmodelo(data2[0].modelo);
          setplaca(data2[0].Placa);
          settelefono(data2[0].Telefono);
          setNombre_Servicio("Estacionamiento Mensual");

          if (parseInt(data2[0].id_Pago) === 1) {
            settipoP("Completo");
          } else {
            settipoP("Parcial");
          }
        } else {
          alert("No se Encontraron datos");
        }
      } else {
        setHoraEntrada();
      }
      setFecha(data[0].Fecha);
      setHora_Entrada(data[0].hora_Entrada);
      setHora_Salida(data[0].hora_Salida);
      setSubtotal(data[0].subtotal);
      setISV(data[0].ISV);
      setTotal(data[0].total);
      setRecibido(data[0].recibido);
      setCambio(data[0].cambio);
      setRTN(data[0].RTN);
      setNombre(data[0].nombre);
      setId_Factura2(data[0].id_Factura);

      if (data[0].Estado === "NULA") {
        setNula("");
      } else {
        setNula("none");
      }
    }

    return data;
  };

  return (
    <React.Fragment>
      <div
        className='contendorConsultarFactura'
        style={{ display: `${mostrarB}` }}
      >
        <div>
          <span className='span'>Número de Factura</span>
          <input
            className='input'
            type='number'
            value={id_Factura}
            onChange={(e) => {
              setId_Factura(e.target.value);
            }}
          />
        </div>

        <div>
          <button
            className='boton'
            onClick={() => {
              enviarDatos().then((response) => {
                if (response.length > 0) {
                  setmostrarB("none");
                  setmostrarF("");
                }
              });
            }}
          >
            Consultar
          </button>
        </div>

        <button
          className='btn btn-warning cambiarFactura'
          style={{ marginTop: "10px" }}
          onClick={() => {
            setmostrarB("none");
            setmostrarMenu("");
          }}
        >
          Menu
        </button>
      </div>

      <div style={{ display: `${mostrarF}` }}>
        <ConsultaF
          setId_Factura={setId_Factura}
          setmostrarF={setmostrarF}
          setmostrarB={setmostrarB}
          mostrarNcasco={mostrarNcasco}
          datos={datos}
          id_Ticket={id_Ticket}
          nombre_Servicio={nombre_Servicio}
          id_Factura2={id_Factura2}
          tipo_Cliente={tipo_Cliente}
          Fecha={Fecha}
          hora_Entrada={hora_Entrada}
          hora_Salida={hora_Salida}
          subtotal={subtotal}
          ISV={ISV}
          total={total}
          recibido={recibido}
          cambio={cambio}
          ocultar={ocultar}
          ncasco={ncasco}
          RTN={RTN}
          nombre={nombre}
          values={values}
          servicioMensual={servicioMensual}
          setservicioMensual={setservicioMensual}
          nula={nula}
          setNula={setNula}
        ></ConsultaF>
      </div>

      <div style={{ display: `${mostrarMenu}` }}>
        <Menu nombreI={props.nombreI} rol={props.rol}></Menu>
      </div>
    </React.Fragment>
  );
}

export default ConsultarFactura;
