import React, { useState, useEffect } from "react";
import Loader from "./Loader";
import ReportesB from "./ReportesB";

function Modal({ ...props }) {
  const [fechaR, setfechaR] = useState("");
  const [fechaR2, setfechaR2] = useState("");
  const [mostrarF, setmostrarF] = useState("");
  const [mostrarLoader, setmostrarLoader] = useState("none");
  const [mostrarFiltro, setmostrarFiltro] = useState("none");
  const [peticion, setPeticion] = useState(false);
  const [nmes2, setNmes2] = useState("");
  const [valorSelect, setvalorSelect] = useState("Dia");
  const [mostrarSelect, setMostrarSelect] = useState("none");
  const [mostrarInput, setMostrarInput] = useState("none");

  useEffect(() => {
    if (
      props.tituloReporte === "Reporte Facturacion por Usuario" ||
      props.tituloReporte === "Reporte Facturacion por Día" ||
      props.tituloReporte === "Reporte Pago Ticket Extraviado" ||
      props.tituloReporte === "Reporte Ticket Cortesia"  ||
      props.tituloReporte === "Registro Parqueo Mensual"
       
    ) {
      setMostrarInput("");
      setMostrarSelect("none");
    } else if (
      props.tituloReporte === "Reporte Pago Mensualidad" ||
      props.tituloReporte === "Reporte de Facturas Nulas" ||
      props.tituloReporte === "Reporte Facturación por Mes"
    ) {
      setMostrarSelect("");
      setMostrarInput("none");
    }else if(props.tituloReporte === "Reporte Creación Ticket's/Facturas"){
      setMostrarInput("");
      setMostrarSelect("none");
      setmostrarFiltro("")
    } else {
      setMostrarSelect("none");
      setMostrarInput("none");
    }
  }, [props.tituloReporte]);

  return (
    <React.Fragment>
      <div>
        <div
          className='contenedor-Pricipal-Modal'
          style={{ display: `${mostrarF}` }}
        >
          <p style={{ marginTop: "4rem" }}>
            Exportar {props.tituloReporte} Formato Excel
          </p>
          <div className='contenedor-Formulario-Modal'>
            <div className='contenedor-Formulario-Modal-Filtro' style={{ display: `${mostrarFiltro}` }}>
              <div>
                <label>Filtar Por: </label>
              </div>
              <div>
                <input
                  type='radio'
                  name='filtro'
                  value='Mes'
                  onClick={(e) => {
                    setvalorSelect(e.target.value);
                    setMostrarInput("none");
                    setMostrarSelect("")
                    setfechaR("")
                  }}
                />
                <label for='Mes'>Mes</label>
              </div>
              <div>
                <input
                  type='radio'
                  id='Dia'
                  name='filtro'
                  value='Dia'
                  checked='checked'
                  onClick={(e) => {
                    setvalorSelect(e.target.value);
                    setMostrarInput("");
                    setMostrarSelect("none")
                    setNmes2("")

                  }}
                />
                <label for='Dia'>Día</label>
              </div>
            </div>
            <div style={{ display: `${mostrarSelect}` }}>
              <p style={{}}>Mes</p>
              <select
                type='date'
                className='input-Modal'
                id="selectorMes"
                style={{ fontSize: "1.8rem", width: "30rem" }}
                onChange={(e) => {
                  setNmes2(e.target.value);
                }}
              >
                <option value="">Seleccione una Opción</option>
                <option value='1'>Enero</option>
                <option value='2'>Febrero</option>
                <option value='3'>Marzo</option>
                <option value='4'>abril</option>
                <option value='5'>Mayo</option>
                <option value='6'>Junio</option>
                <option value='7'>Julio</option>
                <option value='8'>Agosto</option>
                <option value='9'>Septiembre</option>
                <option value='10'>Octubre</option>
                <option value='11'>Noviembre</option>
                <option value='12'>Diciembre</option>
              </select>
            </div>

            <div style={{ display: `${mostrarInput}` }}>
              <p style={{}}>Día</p>
              <input
                className='input-Modal'
                id='selectorFecha'
                type='date'
                onChange={(e) => {
                  setfechaR(e.target.value);

                }}
              />
            </div>

            <div className='contenedor-Botones-Modal'>
              <button
                className='botonExportar'
                onClick={(e) => {

                  if(mostrarSelect==="" && nmes2==="" && mostrarInput==="none"){
                    alert("No se ha seleccionado mes.")
                  }else if(mostrarInput==="" && fechaR===""  && mostrarSelect==="none" ){
                    alert("No se ha seleccionado día.")
                  }else{
                    setmostrarLoader("");
                    setmostrarF("none");
                    setPeticion(true); 
                  }
                }}
              >
                Crear Reporte
              </button>
              <button
                className='botonRegresar'
                onClick={() => {
                  props.setmostrarModal("none");
                  props.setFilter("");
                  setfechaR("");
                  setmostrarFiltro("none")
                  props.setmostrarBotonMenu("")
                  props.setmostrarUltimaFactura("")
                  const selectFecha=document.getElementById("selectorFecha");
                  const selectorM=document.getElementById("selectorMes");
                  selectFecha.value=""
                  selectorM.value=""

                }}
              >
                Regresar
              </button>
            </div>
          </div>
        </div>

        <div
          style={{
            display: `${mostrarLoader}`,
            position: "relative",
            top: "100px",
          }}
        >
          <Loader></Loader>
        </div>
      </div>
      <ReportesB
        peticion={peticion}
        setPeticion={setPeticion}
        fecha2={fechaR}
        setmostrarLoader={setmostrarLoader}
        setmostrarF={setmostrarF}
        tituloReporte={props.tituloReporte}
        nmes2={nmes2}
        valorSelect={valorSelect}
      ></ReportesB>
    </React.Fragment>
  );
}

export default Modal;
